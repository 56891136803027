import React from "react";
import { StatCard } from "./StatCard";
import { useStyletron } from "baseui";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { ReactComponent as PeopleFilled } from "@fluentui/svg-icons/icons/people_24_filled.svg";
import { ReactComponent as ChatFilled } from "@fluentui/svg-icons/icons/chat_24_filled.svg";
import { ReactComponent as AlertFilled } from "@fluentui/svg-icons/icons/alert_24_filled.svg";
import { ReactComponent as DataBarVertical } from "@fluentui/svg-icons/icons/data_bar_vertical_24_filled.svg";

export default function DashboardStats() {
  const [, theme] = useStyletron();
  return (
    <>
      <FlexGrid
        flexGridColumnCount={[1, 2, 2, 4]}
        flexGridColumnGap="scale600"
        flexGridRowGap="scale600"
        marginBottom="scale600"
      >
        <FlexGridItem>
          <StatCard
            label="New Subscribers"
            value="27,418"
            icon={<PeopleFilled fill={theme.colors.accent} />}
          />
        </FlexGridItem>
        <FlexGridItem>
          <StatCard
            label="Streams"
            value={16}
            icon={<ChatFilled fill={theme.colors.accent} />}
          />
        </FlexGridItem>
        <FlexGridItem>
          <StatCard
            label="Engagement Rate"
            value="3:1"
            icon={<AlertFilled fill={theme.colors.accent} />}
          />
        </FlexGridItem>
        <FlexGridItem>
          <StatCard
            label="Earnings"
            value="$3,049"
            icon={<DataBarVertical fill={theme.colors.accent} />}
          />
        </FlexGridItem>
      </FlexGrid>
    </>
  );
}
