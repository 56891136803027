import React from "react";
import { useStyletron } from "baseui";

interface Props {
  to: string;
  children?: React.ReactNode;
  icon?: JSX.Element;
  subMenu: React.ReactNode;
  onClick?: () => void;
}

export const NavSubList: React.FunctionComponent<Props> = (props: Props) => {
  const [css, theme] = useStyletron();

  const button = (
    <div
      className={css({
        ...theme.typography.LabelXSmall,
        color: theme.colors.contentSecondary,
        paddingTop: `${theme.sizing.scale500}`,
        paddingRight: `${theme.sizing.scale400}`,
        paddingBottom: `${theme.sizing.scale500}`,
        textTransform: "uppercase",
      })}
    >
      {props.children}
    </div>
  );

  return (
    <div>
      <>
        {button}
        {props.subMenu}
      </>
    </div>
  );
};
