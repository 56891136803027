import React from "react";
import { PaymentCard } from "baseui/payment-card";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { useStyletron } from "baseui";

interface Props {
  disabled: boolean;
}

function getFormOverrides(width: string) {
  return {
    ControlContainer: {
      style: {
        width,
        marginRight: "5px",
      },
    },
  };
}

export const PaymentForm: React.FC<Props> = (props) => {
  const [css] = useStyletron();

  return (
    <div className={css({ display: "flex", flexFlow: "row wrap" })}>
      <FormControl overrides={getFormOverrides("250px")} caption="Card number">
        <PaymentCard
          value={props.disabled ? "5555555555554444" : "************4444"}
          placeholder="Card number"
          disabled={!props.disabled}
        />
      </FormControl>
      <FormControl
        overrides={getFormOverrides("90px")}
        caption="Expiration date"
      >
        <Input value="01/25" placeholder="MM/YY" disabled={!props.disabled} />
      </FormControl>
      <FormControl overrides={getFormOverrides("70px")} caption="CVC">
        <Input value={"123"} placeholder="CVC" disabled={!props.disabled} />
      </FormControl>
    </div>
  );
};
