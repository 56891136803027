import React from "react";
import { ReactComponent as Add } from "@fluentui/svg-icons/icons/add_20_filled.svg";
import { ReactComponent as BuildingFilled } from "@fluentui/svg-icons/icons/building_24_filled.svg";
import { ReactComponent as InkingToolFilled } from "@fluentui/svg-icons/icons/inking_tool_24_filled.svg";
import { ReactComponent as InkStrokeFilled } from "@fluentui/svg-icons/icons/ink_stroke_24_filled.svg";
import { ReactComponent as ShieldFilled } from "@fluentui/svg-icons/icons/shield_24_filled.svg";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { FormControl } from "baseui/form-control";
import { StatefulInput } from "baseui/input";
import { Cell, Grid } from "baseui/layout-grid";
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from "baseui/modal";
import { Select, Value } from "baseui/select";
import { GroupCard } from "../components/GroupCard";
import { Header } from "../components/Header";
import { MainHeading } from "../components/MainHeading";
import { Divider } from "../components/Divider";
import { Row } from "../components/Row";
import StyledButton from "../components/StyledButton";
import { Project, PROJECTS_DATA } from "../utils/mockData";

export default function Projects() {
  const [css, theme] = useStyletron();
  const [data, setData] = React.useState<Project[]>(PROJECTS_DATA);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [nameValue, setNameValue] = React.useState<string>("");
  const [descriptionValue, setDescriptionValue] = React.useState<string>("");
  const [iconValue, setIconValue] = React.useState<Value>([]);
  const [teamValue, setTeamValue] = React.useState<Value>([]);

  function teamValueToString(peopleSelected: Value) {
    const people: string[] = [];
    Object.values(peopleSelected).forEach((value) => {
      value.id && people.push(value.id.toString());
    });
    return people;
  }

  function handleSubmit() {
    setModalOpen(false);
    setData([
      ...data,
      {
        name:
          nameValue && nameValue.length ? nameValue : "Example project name",
        description:
          descriptionValue && descriptionValue.length
            ? descriptionValue
            : "Eu ultrices vitae auctor eu augue ut lectus arcu. ",
        people:
          teamValue && teamValue.length
            ? teamValueToString(teamValue)
            : ["Example Name"],
        icon: iconValue && iconValue.length ? iconValue : [],
      },
    ]);
    setNameValue("");
    setDescriptionValue("");
    setIconValue([]);
    setTeamValue([]);
  }

  function getIcon(iconName: string) {
    switch (iconName) {
      case "InkStrokeFilled":
        return <InkStrokeFilled fill={theme.colors.accent} />;
      case "BuildingFilled":
        return <BuildingFilled fill={theme.colors.accent} />;
      case "InkingToolFilled":
        return <InkingToolFilled fill={theme.colors.accent} />;
      case "ShieldFilled":
        return <ShieldFilled fill={theme.colors.accent} />;
      default:
        return "not sure";
    }
  }

  const tableHeader = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "row wrap",
    marginBottom: theme.sizing.scale600,
  });
  const headerActions = css({
    display: "flex",
    flexFlow: "row wrap",
    flex: "1 0 auto",
    alignItems: "center",
    justifyContent: "flex-end",
  });

  const primaryActions = css({
    marginLeft: theme.sizing.scale600,
    display: "flex",
    alignItems: "center",
  });

  const actions = (
    <div className={headerActions}>
      <div className={primaryActions}>
        <StyledButton
          onClick={() => setModalOpen(true)}
          kind="primary"
          size="compact"
          marginLeft={theme.sizing.scale500}
        >
          <Add
            fill={theme.colors.contentInversePrimary}
            className={css({
              [theme.mediaQuery.small]: {
                marginRight: theme.sizing.scale400,
              },
            })}
          />
          <Block as="span" display={["none", "block", "block", "block"]}>
            Create new project
          </Block>
        </StyledButton>
      </div>
    </div>
  );

  return (
    <>
      <Row paddingBottom>
        <Grid>
          <Cell span={12}>
            <Header />
            <div className={tableHeader}>
              <Breadcrumbs>
                <MainHeading>Projects</MainHeading>
              </Breadcrumbs>
              {actions}
            </div>
            <Divider marginBottom={theme.sizing.scale900} />
            <>
              <FlexGrid
                flexGridColumnCount={[1, 2, 2, 3]}
                flexGridColumnGap={theme.sizing.scale600}
                flexGridRowGap={theme.sizing.scale600}
                marginBottom={theme.sizing.scale600}
              >
                {data.map((item: Project, index) => {
                  const [itemIcon]: Value = item.icon;
                  return (
                    <FlexGridItem key={`${item.name}-${index}`}>
                      <GroupCard
                        title={item.name}
                        text={item.description}
                        people={item.people}
                        icon={
                          itemIcon ? (
                            getIcon(itemIcon.iconName)
                          ) : (
                            <InkStrokeFilled fill={theme.colors.accent} />
                          )
                        }
                      />
                    </FlexGridItem>
                  );
                })}
              </FlexGrid>
            </>
          </Cell>
        </Grid>
      </Row>

      <Modal
        onClose={() => setModalOpen(false)}
        closeable
        isOpen={modalOpen}
        animate
        autoFocus
        role={ROLE.dialog}
        unstable_ModalBackdropScroll
        overrides={{
          Dialog: {
            style: {
              display: "flex",
              flexFlow: "column nowrap",
              paddingBottom: theme.sizing.scale400,
            },
          },
        }}
      >
        <ModalHeader>Create a new project</ModalHeader>
        <ModalBody>
          <form id="my-form">
            <FormControl label={() => "Project name"}>
              <StatefulInput
                onChange={(e: any) => setNameValue(e.target.value)}
                value={nameValue}
                maxLength={60}
                placeholder="Example project name"
              />
            </FormControl>
            <FormControl label={() => "Description"}>
              <StatefulInput
                onChange={(e: any) => setDescriptionValue(e.target.value)}
                value={descriptionValue}
                maxLength={120}
                placeholder="Eu ultrices vitae auctor eu augue ut lectus arcu. "
              />
            </FormControl>
            <FormControl label={() => "Project type"}>
              <Select
                options={[
                  { id: "Design", iconName: "InkStrokeFilled" },
                  { id: "Development", iconName: "BuildingFilled" },
                  { id: "Exploration", iconName: "InkingToolFilled" },
                  { id: "Testing", iconName: "ShieldFilled" },
                ]}
                placeholder="Select a project type..."
                labelKey="id"
                valueKey="iconName"
                onChange={(params) => setIconValue(params.value)}
                value={iconValue}
              />
            </FormControl>
            <FormControl label={() => "Team members"}>
              <Select
                options={[
                  { id: "John Smith" },
                  { id: "Peter Thornton" },
                  { id: "Terry Wall" },
                  { id: "Ken Eames" },
                  { id: "Josh Tyson" },
                  { id: "Andrew Samson" },
                ]}
                placeholder="Select team members..."
                multi
                labelKey="id"
                valueKey="id"
                onChange={(params) => setTeamValue(params.value)}
                value={teamValue}
              />
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <StyledButton
            kind="minimal"
            onClick={() => setModalOpen(false)}
            marginRight={theme.sizing.scale500}
          >
            Cancel
          </StyledButton>
          <StyledButton
            kind="primary"
            form="my-form"
            type="submit"
            onClick={() => handleSubmit()}
          >
            Create project
          </StyledButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
