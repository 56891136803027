import React from "react";
import { Link } from "react-router-dom";
import { useStyletron } from "baseui";
import background403 from "../assets/background403.svg";
import { LabelLarge } from "baseui/typography";

export default function Error403() {
  const [css, theme] = useStyletron();

  const heading = css({
    ...theme.typography.DisplayLarge,
    display: "flex",
    alignItems: "center",
    color: theme.colors.contentInversePrimary,
    letterSpacing: "-0.6px",
    fontWeight: 700,
  });

  const fullPanel = css({
    display: "flex",
    flex: "1 1 100%",
    padding: theme.sizing.scale600,
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#bf2c24",
    backgroundImage: `url(${background403})`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
  });

  // TODO: Make a global export?
  const styledLink = css({
    ...theme.typography.LabelMedium,
    color: theme.colors.contentInversePrimary,
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
      textUnderlinePosition: "under",
    },
  });

  const contain = css({
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.backgroundPrimary,
  });

  return (
    <div className={contain}>
      <div className={fullPanel}>
        <div className={heading}>403</div>
        <div className={css({ textAlign: "center" })}>
          <br />
          <LabelLarge color={theme.colors.contentInversePrimary}>
            Sorry, you don’t have access to this page.
          </LabelLarge>
          <br />
          <br />
          <Link to="/" className={styledLink}>
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
}
