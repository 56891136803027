import React from "react";
import { useStyletron } from "baseui";

interface Props {
  children: React.ReactNode;
  noGutter?: boolean;
  background?: string;
  borderBottom?: boolean;
  fullHeight?: boolean;
  paddingTop?: boolean;
  paddingBottom?: boolean;
}

export function Row(props: Props) {
  const [css, theme] = useStyletron();
  const row = css({
    width: "100%",
    flex: props.fullHeight ? "1 0 auto" : "0 0 auto",
    backgroundColor: props.background ?? undefined,
    overflowX: "hidden",
    borderBottom: !!props.borderBottom
      ? `1px solid ${theme.colors.borderTransparent}`
      : undefined,
    padding: `${props.paddingTop ? theme.sizing.scale400 : 0} ${0} ${
      props.paddingBottom ? theme.sizing.scale600 : 0
    }`,
    [theme.mediaQuery.small]: {
      padding: `${props.paddingTop ? theme.sizing.scale400 : 0} ${0} ${
        props.paddingBottom ? theme.sizing.scale850 : 0
      }`,
    },
    [theme.mediaQuery.large]: {
      padding: `${props.paddingTop ? theme.sizing.scale400 : 0} ${0} ${
        props.paddingBottom ? theme.sizing.scale850 : 0
      }`,
    },
  });

  return <div className={row}>{props.children}</div>;
}
