import React from "react";
import { useStyletron } from "baseui";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Mon",
    uv: 4000,
    pv: 240,
    amt: 2400,
  },
  {
    name: "Tue",
    uv: 3000,
    pv: 139,
    amt: 2210,
  },
  {
    name: "Wed",
    uv: 2000,
    pv: 180,
    amt: 2290,
  },
  {
    name: "Thu",
    uv: 2780,
    pv: 390,
    amt: 2000,
  },
  {
    name: "Fri",
    uv: 1890,
    pv: 480,
    amt: 2181,
  },
];

export default function StatBarChart() {
  const [, theme] = useStyletron();
  return (
    <div style={{ width: "100%", maxWidth: "100%", height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          height={300}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            stroke={theme.colors.backgroundTertiary}
            strokeDasharray="3 3"
          />
          <Tooltip />
          <XAxis dataKey="name" />
          <YAxis />
          <Bar
            dataKey="pv"
            barSize={20}
            fill={theme.colors.accent}
            radius={2}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
