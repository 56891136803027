import React from "react";
import { useStyletron } from "baseui";
import { Asset } from "../utils/mockData";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SIZE as ModalSize,
  ROLE,
} from "baseui/modal";
import StyledButton from "./StyledButton";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  asset?: Asset;
}

export default function AssetModal(props: Props) {
  const [css, theme] = useStyletron();
  const assetDetails = css({
    display: "flex",
    flexFlow: "column wrap",
    flex: 1,
  });
  const assetDetailsRow = css({
    display: "flex",
    flexFlow: "row wrap",
    marginBottom: theme.sizing.scale400,
  });
  const assetDetailsLabel = css({
    marginRight: theme.sizing.scale200,
    fontWeight: 500,
  });

  return (
    <Modal
      onClose={() => props.setIsOpen(false)}
      closeable
      isOpen={props.isOpen}
      animate
      size={ModalSize.auto}
      role={ROLE.dialog}
      unstable_ModalBackdropScroll
      overrides={{
        Dialog: {
          style: {
            display: "flex",
            flexFlow: "column nowrap",
            maxWidth: "98%",
            minWidth: "50vw",
            paddingBottom: theme.sizing.scale400,
          },
        },
      }}
    >
      <ModalHeader>Asset details</ModalHeader>
      <ModalBody
        className={css({ flex: 1, display: "flex", alignItems: "stretch" })}
      >
        <div className={css({ display: "flex", flexFlow: "row wrap" })}>
          <div
            className={css({
              width: "300px",
              height: "300px",
              display: "block",
              backgroundColor: "gray",
              cursor: "pointer",
              objectFit: "contain",
              marginRight: "16px",
            })}
          >
            <img
              src={props.asset?.src}
              alt="Preview"
              className={css({
                display: "block",
                width: "300px",
                height: "300px",
              })}
            />
          </div>
          <div className={assetDetails}>
            <div className={assetDetailsRow}>
              <span className={assetDetailsLabel}>File:</span>
              <span>{props.asset?.fileName}</span>
            </div>
            <div className={assetDetailsRow}>
              <span className={assetDetailsLabel}>Size:</span>
              <span>{props.asset?.size}</span>
            </div>
            <div className={assetDetailsRow}>
              <span className={assetDetailsLabel}>Width:</span>
              <span>{props.asset?.width}</span>
            </div>
            <div className={assetDetailsRow}>
              <span className={assetDetailsLabel}>Height:</span>
              <span>{props.asset?.height}</span>
            </div>
            <div className={assetDetailsRow}>
              <span className={assetDetailsLabel}>Size:</span>
              <span>{props.asset?.size}</span>
            </div>
            <div className={assetDetailsRow}>
              <span className={assetDetailsLabel}>Created on:</span>
              <span>{props.asset?.createdOn}</span>
            </div>
            <div className={assetDetailsRow}>
              <span className={assetDetailsLabel}>Updated on:</span>
              <span>{props.asset?.updatedOn}</span>
            </div>
            <div className={assetDetailsRow}>
              <span className={assetDetailsLabel}>Updated by:</span>
              <span>{props.asset?.updatedBy}</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <StyledButton
          kind="minimal"
          onClick={() => props.setIsOpen(false)}
          marginRight={theme.sizing.scale500}
        >
          Close
        </StyledButton>
        <StyledButton kind="primary" onClick={() => props.setIsOpen(false)}>
          Done
        </StyledButton>
      </ModalFooter>
    </Modal>
  );
}
