import React from "react";
import { useStyletron } from "baseui";
import { Button, KIND, SIZE, ButtonOverrides } from "baseui/button";
import { NavLink } from "react-router-dom";
import { useLocation, matchPath } from "react-router";
import { StatefulTooltip, PLACEMENT, ACCESSIBILITY_TYPE } from "baseui/tooltip";

interface Props {
  to: string;
  children?: React.ReactNode;
  icon?: JSX.Element;
  tooltip?: string;
  subList?: boolean;
  onClick?: () => void;
}

export const NavListItem: React.FunctionComponent<Props> = (props: Props) => {
  const [css, theme] = useStyletron();
  const location = useLocation();
  const pathMatchesRoute = !!matchPath(location.pathname, {
    path: props.to,
    exact: props.to === "/" ? true : false,
  });
  const isActive = pathMatchesRoute;

  const buttonOverrides: ButtonOverrides = {
    BaseButton: {
      style: {
        justifyContent: "flex-start",
        width: "100%",
        paddingTop: theme.sizing.scale100,
        paddingBottom: theme.sizing.scale100,
        paddingLeft: theme.sizing.scale200,
        marginBottom: theme.sizing.scale300,
        fontWeight: isActive ? 600 : 500,
        color: isActive
          ? theme.colors.contentAccent
          : theme.colors.contentSecondary,
        backgroundColor: isActive
          ? theme.colors.backgroundLightAccent
          : theme.colors.backgroundPrimary,
        ":hover": {
          backgroundColor: isActive
            ? undefined
            : theme.colors.backgroundLightAccent,
        },
        [theme.mediaQuery.medium]: {
          borderRadius: theme.borders.radius200,
        },
      },
    },
  };

  const button = (
    <Button
      kind={KIND.tertiary}
      size={SIZE.compact}
      onClick={props.onClick}
      overrides={buttonOverrides}
      startEnhancer={
        props.icon ? (
          <div
            className={css({
              borderRadius: "20px",
              display: "flex",
              padding: props.subList
                ? theme.sizing.scale100
                : theme.sizing.scale200,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: props.subList
                ? undefined
                : isActive
                ? theme.colors.accent
                : theme.colors.backgroundSecondary,
            })}
          >
            {React.cloneElement(props.icon, {
              fill:
                isActive && !props.subList
                  ? theme.colors.contentInversePrimary
                  : theme.colors.contentSecondary,
            })}
          </div>
        ) : undefined
      }
    >
      {props.children}
    </Button>
  );

  return (
    <StatefulTooltip
      accessibilityType={ACCESSIBILITY_TYPE.tooltip}
      content={props.tooltip ? props.tooltip : undefined}
      placement={PLACEMENT.right}
    >
      <li>
        <NavLink
          to={props.to}
          className={css({
            textDecoration: "none",
            display: "block",
          })}
        >
          {button}
        </NavLink>
      </li>
    </StatefulTooltip>
  );
};
