import React from "react";
import { Link } from "react-router-dom";
import { useStyletron } from "baseui";
import { Button, KIND, SIZE, SHAPE } from "baseui/button";
import { ChevronLeft } from "baseui/icon";

interface Props {
  title: React.ReactNode;
  backRoute?: string;
  navItems?: React.ReactNode;
}

export function SubNavigation(props: Props) {
  const [css, theme] = useStyletron();

  const subnav = css({
    width: "100%",
    display: "flex",
    flex: "0 0 50px",
    flexFlow: "column wrap",
    paddingTop: 0,
    paddingBottom: theme.sizing.scale600,
    marginTop: theme.sizing.scale300,
    [theme.mediaQuery.large]: {
      flexFlow: "row wrap",
    },
  });
  const heading = css({
    ...theme.typography.HeadingXSmall,
    flex: "1",
    color: theme.colors.contentPrimary,
    marginTop: 0,
    [theme.mediaQuery.medium]: {
      ...theme.typography.HeadingXSmall,
      lineHeight: 1,
      flex: "0 0 50%",
      // marginBottom: 0,
    },
    [theme.mediaQuery.large]: {
      ...theme.typography.HeadingLarge,
      marginBottom: 0,
    },
  });
  const backButton = css({
    display: "inline-flex",
    alignItems: "center",
    flex: "1 1 100",
    width: "100%",
    [theme.mediaQuery.large]: {
      width: "auto",
    },
  });
  const controls = css({
    position: "relative",
    display: "flex",
    flex: "1",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    alignItems: "center",

    [theme.mediaQuery.large]: {
      justifyContent: "flex-end",
    },
  });

  return (
    <div className={subnav}>
      <h1 className={heading}>
        <div className={backButton}>
          {props.backRoute && (
            <Link to={props.backRoute}>
              <Button
                kind={KIND.secondary}
                size={SIZE.compact}
                shape={SHAPE.round}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      marginRight: $theme.sizing.scale500,
                      // paddingTop: $theme.sizing.scale500,
                      // paddingRight: $theme.sizing.scale500,
                      // paddingBottom: $theme.sizing.scale500,
                      // paddingLeft: $theme.sizing.scale500,
                      [theme.mediaQuery.medium]: {
                        marginRight: $theme.sizing.scale600,
                        paddingLeft: undefined,
                      },
                    }),
                  },
                }}
              >
                <ChevronLeft size={theme.sizing.scale800} />
              </Button>
            </Link>
          )}
          {props.title}
        </div>
      </h1>
      {props.navItems && <div className={controls}>{props.navItems}</div>}
    </div>
  );
}
