import React from "react";
import { useStyletron } from "baseui";

interface Props {
  marginTop?: string | number;
  marginBottom?: string | number;
}

export const Divider: React.FC<Props> = ({ marginTop, marginBottom }) => {
  const [css, theme] = useStyletron();
  const divider = css({
    width: "100%",
    height: "1px",
    backgroundColor: theme.colors.borderOpaque,
    border: "none",
    marginTop: marginTop ?? 0,
    marginBottom: marginBottom ?? 0,
  });

  return <hr className={divider} />;
};
