import React from "react";
import { FileUploader } from "baseui/file-uploader";

function useInterval(callback: any, delay: number | null) {
  const savedCallback = React.useRef(() => {});
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect((): any => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
// useFakeProgress is an elaborate way to show a fake file transfer for illustrative purposes. You
// don't need this is your application.
function useFakeProgress(): [number, () => void, () => void] {
  const [fakeProgress, setFakeProgress] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  function stopFakeProgress() {
    setIsActive(false);
    setFakeProgress(0);
  }
  function startFakeProgress() {
    setIsActive(true);
  }
  useInterval(
    () => {
      if (fakeProgress >= 100) {
        stopFakeProgress();
      } else {
        setFakeProgress(fakeProgress + 10);
      }
    },
    isActive ? 500 : null
  );
  return [fakeProgress, startFakeProgress, stopFakeProgress];
}

interface Props {
  message: string;
  disabled?: boolean;
}

export default function FileUpload(props: Props) {
  const [progressAmount, startFakeProgress, stopFakeProgress] =
    useFakeProgress();
  return (
    <FileUploader
      disabled={props.disabled ?? false}
      onCancel={stopFakeProgress}
      onDrop={(acceptedFiles, rejectedFiles) => {
        console.log(acceptedFiles, rejectedFiles);
        startFakeProgress();
      }}
      overrides={{
        ContentMessage: {
          component: () => <span>{props.message}</span>,
        },
      }}
      progressAmount={progressAmount}
      progressMessage={
        progressAmount ? `Uploading... ${progressAmount}% of 100%` : ""
      }
    />
  );
}
