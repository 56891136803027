import React from "react";
import StyledButton from "../components/StyledButton";
import { Block } from "baseui/block";
import { Checkbox } from "baseui/checkbox";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { FormControl } from "baseui/form-control";
import { InputEmail } from "../components/InputEmail";
import { ParagraphXSmall } from "baseui/typography";
import { StyledAction, StyledBody } from "baseui/card";
import { StyledInput } from "../components/StyledInput";
import { useStyletron } from "baseui";

interface Props {
  onSubmit(): void;
  isLoading: boolean;
}

export function SignUp(props: Props) {
  const [css, theme] = useStyletron();
  const [checked, setChecked] = React.useState(false);

  const formContain = css({
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    maxWidth: "440px",
  });

  const formHeading = css({
    ...theme.typography.HeadingXLarge,
    color: theme.colors.contentPrimary,
    letterSpacing: "-1px",
    fontWeight: 600,
    marginBottom: theme.sizing.scale400,
    [theme.mediaQuery.medium]: {
      marginBottom: theme.sizing.scale800,
    },
  });

  function getFormOverrides(width: string) {
    return {
      ControlContainer: {
        style: {
          width,
          marginRight: "5px",
        },
      },
    };
  }

  return (
    <Block display="flex" flex="1" alignItems="center">
      <div className={formContain}>
        <div className={formHeading}>Create Account</div>
        <br />
        <StyledBody>
          <FormControl label="Username" overrides={getFormOverrides("100%")}>
            <StyledInput type="text" />
          </FormControl>
          <InputEmail />
          <FlexGrid
            flexGridColumnCount={[1, 2]}
            flexGridColumnGap="scale600"
            marginBottom="scale600"
          >
            <FlexGridItem>
              <FormControl
                label="Password"
                overrides={getFormOverrides("100%")}
              >
                <StyledInput type="password" />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem>
              <FormControl label="Confirm" overrides={getFormOverrides("100%")}>
                <StyledInput type="password" />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
          <div className={css({ display: "flex" })}>
            <Checkbox
              checked={checked}
              onChange={(e: any) => setChecked(e.target.checked)}
            >
              <ParagraphXSmall className={css({ marginTop: "0" })}>
                By creating an account, you agree to the Terms of Service and
                Conditions, and our Privacy Policy
              </ParagraphXSmall>
            </Checkbox>
            <br />
            <br />
          </div>
        </StyledBody>
        <StyledAction>
          <div>
            <StyledButton
              onClick={props.onSubmit}
              isLoading={props.isLoading}
              kind="primary"
              fullWidth
            >
              Sign up
            </StyledButton>
          </div>
        </StyledAction>
      </div>
    </Block>
  );
}
