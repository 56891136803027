import React from "react";
import { useStyletron } from "baseui";
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import { FILTER_MENU } from "../utils/mockData";
import { ReactComponent as ArrowSort } from "@fluentui/svg-icons/icons/arrow_sort_20_filled.svg";

interface Props {
  icon?: React.ReactNode;
}

export default function FilterMenu(props: Props) {
  const [css, theme] = useStyletron();
  const optionsMenu = css({
    display: "flex",
    flex: "0 0 auto",
    maxWidth: "100%",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <div className={optionsMenu}>
      <StatefulPopover
        focusLock
        placement={PLACEMENT.bottomRight}
        content={({ close }) => (
          <StatefulMenu
            items={FILTER_MENU}
            onItemSelect={() => close()}
            overrides={{
              List: { style: { width: "160px" } },
            }}
          />
        )}
      >
        <Button size={SIZE.compact} shape={SHAPE.circle} kind={KIND.secondary}>
          {props.icon ?? <ArrowSort fill={theme.colors.contentSecondary} />}
        </Button>
      </StatefulPopover>
    </div>
  );
}
