import React from "react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import NotificationMenu from "./NotificationMenu";
import SupportMenu from "./SupportMenu";
import UserMenu from "./UserMenu";

interface Props {
  breadcrumbs?: React.ReactNode;
  middleContent?: React.ReactNode;
}

export function Header(props: Props) {
  const [css, theme] = useStyletron();

  const header = css({
    display: "flex",
    flexFlow: "row wrap",
    flex: "0 0 auto",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: theme.sizing.scale300,
    paddingBottom: theme.sizing.scale600,
  });

  const containLeft = css({
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
  });

  const containMiddle = css({
    display: "flex",
    order: 3,
    flex: "1 0 100%",
    justifyContent: "center",
    marginTop: theme.sizing.scale200,
    [theme.mediaQuery.large]: {
      order: 2,
      flex: "1 1 600px",
      marginTop: 0,
    },
  });

  const containRight = css({
    display: "none",
    [theme.mediaQuery.medium]: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      order: 2,
      marginLeft: "auto",
      minHeight: "48px",
    },
    [theme.mediaQuery.large]: {
      order: 3,
    },
  });

  return (
    <header className={header}>
      {props.breadcrumbs && (
        <div className={containLeft}>{props.breadcrumbs}</div>
      )}
      {props.middleContent && (
        <div className={containMiddle}>{props.middleContent}</div>
      )}
      <div className={containRight}>
        <Block>
          <SupportMenu />
        </Block>
        <Block marginRight={theme.sizing.scale400}>
          <NotificationMenu hasNotifications />
        </Block>
        <UserMenu />
      </div>
    </header>
  );
}
