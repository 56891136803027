import React from "react";
import { useStyletron } from "baseui";
import OptionsMenu from "./OptionsMenu";

interface ChartCardProps {
  header: React.ReactNode;
  hideOptions?: boolean;
}

export const ChartCard: React.FC<ChartCardProps> = (props) => {
  const [css, theme] = useStyletron();

  const chartCard = css({
    display: "flex",
    flexFlow: "column nowrap",
    boxSizing: "border-box",
    position: "relative",
    borderRadius: theme.borders.radius300,
    flex: 1,
    background: theme.colors.backgroundPrimary,
    paddingTop: theme.sizing.scale600,
    paddingBottom: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale500,
    paddingRight: theme.sizing.scale300,
    textAlign: "left",
    boxShadow: theme.lighting.shadow400,
    [theme.mediaQuery.large]: {
      paddingTop: theme.sizing.scale800,
      paddingBottom: theme.sizing.scale800,
      paddingLeft: theme.sizing.scale800,
      paddingRight: theme.sizing.scale600,
    },
  });

  const headerContain = css({
    display: "flex",
    flex: "1",
    maxWidth: "100%",
    flexFlow: "row nowrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingLeft: theme.sizing.scale400,
    [theme.mediaQuery.large]: {
      paddingLeft: theme.sizing.scale600,
    },
  });

  const header = css({
    ...theme.typography.LabelMedium,
    marginTop: 0,
    marginBottom: theme.sizing.scale400,
    whiteSpace: "nowrap",
    width: "100%",
    maxWidth: "calc(100% - 10px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 500,
  });

  const chartContain = css({
    display: "flex",
    height: "300px",
    flexFlow: "row nowrap",
    alignItems: "center",
    position: "relative",
    flex: "1 1 100%",
    background: theme.colors.backgroundPrimary,
    paddingTop: theme.sizing.scale800,
    paddingRight: theme.sizing.scale300,
    [theme.mediaQuery.large]: {
      paddingRight: theme.sizing.scale600,
    },
  });

  return (
    <div className={chartCard}>
      <div className={headerContain}>
        <div className={header}>{props.header}</div>
        {!props.hideOptions && <OptionsMenu />}
      </div>
      <div className={chartContain}>{props.children}</div>
    </div>
  );
};
