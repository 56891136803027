import React from "react";
import { FormControl } from "baseui/form-control";
import { StyledInput } from "./StyledInput";
import { useStyletron } from "baseui";
import { validate as validateEmail } from "email-validator";
import { ReactComponent as WarningFilled } from "@fluentui/svg-icons/icons/error_circle_16_filled.svg";

export function InputEmail() {
  const [css, theme] = useStyletron();

  // Email validation
  const [value, setValue] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  const [isVisited, setIsVisited] = React.useState(false);
  const shouldShowError = !isValid && isVisited;
  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(validateEmail(value));
    setValue(value);
  };

  const InputNegative = () => {
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          paddingRight: theme.sizing.scale500,
          color: theme.colors.negative400,
        })}
      >
        <WarningFilled fill={theme.colors.negative400} />
      </div>
    );
  };

  return (
    <FormControl
      label="Email"
      error={shouldShowError ? "Please input a valid email address" : null}
      overrides={{
        ControlContainer: {
          style: {
            width: "100%",
            marginRight: "5px",
          },
        },
      }}
    >
      <StyledInput
        id="email-input-id"
        value={value}
        onChange={onChange}
        onBlur={() => setIsVisited(true)}
        error={shouldShowError}
        overrides={shouldShowError ? { After: InputNegative } : {}}
        type="email"
        required
      />
    </FormControl>
  );
}
