import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { COLORS } from "../../utils/mockData";

const data = [
  { name: "Jeans", value: 431 },
  { name: "Jackets", value: 354 },
  { name: "Accessories", value: 335 },
  { name: "Active Wear", value: 182 },
  {
    name: "Chinos",
    value: 283,
  },
  { name: "Sweaters", value: 199 },
];

export default function StackedAreaChart() {
  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          innerRadius={60}
          outerRadius={80}
          cornerRadius={10}
          fill="#8884d8"
          paddingAngle={-15}
          stroke="transparent"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
