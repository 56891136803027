import React from "react";
import { ReactComponent as AlertFilled } from "@fluentui/svg-icons/icons/alert_24_filled.svg";
import { ReactComponent as Alert } from "@fluentui/svg-icons/icons/alert_24_regular.svg";
import { ReactComponent as GiftFilled } from "@fluentui/svg-icons/icons/gift_24_filled.svg";
import { ReactComponent as Megaphone } from "@fluentui/svg-icons/icons/megaphone_24_filled.svg";
import { ReactComponent as TaskList } from "@fluentui/svg-icons/icons/task_list_rtl_20_filled.svg";
import { ReactComponent as ShieldFilled } from "@fluentui/svg-icons/icons/shield_24_filled.svg";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button, ButtonOverrides, SIZE, KIND, SHAPE } from "baseui/button";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { LabelMedium } from "baseui/typography";
import BoldText from "./BoldText";
import { ListItem } from "./ListItem";
import { Divider } from "../components/Divider";

interface Props {
  hasNotifications?: boolean;
}

export default function NotificationMenu(props: Props) {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);

  const contain = css({
    marginLeft: "auto",
  });

  const list = css({
    display: "flex",
    flex: "1 1 auto",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    width: "320px",
    listStyle: "none",
    paddingLeft: 0,
    paddingBottom: theme.sizing.scale400,
    margin: 0,
    backgroundColor: theme.colors.backgroundPrimary,
  });

  const listHeader = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.colors.backgroundPrimary,
    paddingTop: theme.sizing.scale300,
    paddingRight: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
  });

  const listItem = css({
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "flex-start",
  });

  const listItemBody = css({
    ...theme.typography.ParagraphSmall,
    lineHeight: 1.4,
    textAlign: "left",
    marginBottom: theme.sizing.scale200,
  });

  const listItemFooter = css({
    ...theme.typography.LabelXSmall,
    textAlign: "left",
    color: theme.colors.contentSecondary,
  });

  const buttonOverrides: ButtonOverrides = {
    BaseButton: {
      style: {
        position: "relative",
        transitionProperty: "all",
        transitionDuration: theme.animation.timing200,
        backgroundColor: isOpen
          ? theme.colors.backgroundLightAccent
          : theme.colors.backgroundPrimary,
        color: isOpen
          ? theme.colors.backgroundAccent
          : theme.colors.contentSecondary,
        ":hover": {
          backgroundColor: isOpen
            ? theme.colors.backgroundLightAccent
            : theme.colors.backgroundSecondary,
        },
        [theme.mediaQuery.medium]: {
          backgroundColor: isOpen
            ? theme.colors.backgroundLightAccent
            : theme.colors.backgroundTertiary,
        },
        "::before": {
          display: isOpen || !props.hasNotifications ? "none" : "block",
          background: theme.colors.negative,
          top: "12px",
          right: "12px",
          content: '" "',
          height: "10px",
          width: "10px",
          borderRadius: "60px",
          position: "absolute",
        },
      },
    },
  };

  return (
    <div className={contain}>
      <StatefulPopover
        focusLock
        placement={PLACEMENT.bottomRight}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        content={({ close }) => (
          <ul className={list}>
            <li className={listHeader}>
              <LabelMedium>
                Notifications (<BoldText>3</BoldText>)
              </LabelMedium>
              <Button
                size={SIZE.compact}
                shape={SHAPE.circle}
                kind={KIND.tertiary}
              >
                <TaskList fill={theme.colors.contentSecondary} />
              </Button>
            </li>

            <Divider
              marginTop={theme.sizing.scale200}
              marginBottom={theme.sizing.scale400}
            />

            <ListItem
              key="notification-1"
              icon={<Megaphone fill={theme.colors.warning} />}
            >
              <Block className={listItem}>
                <Block className={listItemBody}>
                  6 new messages receieved.
                </Block>
                <Block as="div" className={listItemFooter}>
                  3h ago
                </Block>
              </Block>
            </ListItem>

            <ListItem
              key="notification-2"
              icon={<ShieldFilled fill={theme.colors.contentTertiary} />}
            >
              <Block className={listItem}>
                <Block className={listItemBody}>
                  Two-factor authentication request complete. Check your email
                  inbox for more information.
                </Block>
                <Block as="div" className={listItemFooter}>
                  8h ago
                </Block>
              </Block>
            </ListItem>

            <ListItem
              key="notification-3"
              icon={<GiftFilled fill={"#D66BEA"} />}
            >
              <Block className={listItem}>
                <Block className={listItemBody}>
                  You've unlocked all Pro features. Want to learn more?
                </Block>
                <Block as="div" className={listItemFooter}>
                  4d ago
                </Block>
              </Block>
            </ListItem>
          </ul>
        )}
      >
        <Button
          shape={SHAPE.circle}
          kind={KIND.tertiary}
          overrides={buttonOverrides}
        >
          {isOpen ? (
            <AlertFilled fill="currentColor" />
          ) : (
            <Alert fill="currentColor" />
          )}
        </Button>
      </StatefulPopover>
    </div>
  );
}
