import React from "react";
import { Input, InputProps } from "baseui/input";
import { useStyletron } from "baseui";

export const StyledInput = (props: InputProps) => {
  const [, theme] = useStyletron();
  return (
    <Input
      {...props}
      overrides={{
        Root: {
          style: {
            borderTopLeftRadius: theme.borders.radius300,
            borderBottomLeftRadius: theme.borders.radius300,
            borderTopRightRadius: theme.borders.radius300,
            borderBottomRightRadius: theme.borders.radius300,
          },
        },
      }}
    />
  );
};
