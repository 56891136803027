import React from "react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ReactComponent as CheckmarkCircle } from "@fluentui/svg-icons/icons/checkmark_circle_24_regular.svg";

interface Props {
  count: number | undefined;
  children: React.ReactNode;
  noBackground?: boolean;
}
export default function SelectionPanel(props: Props) {
  const [css, theme] = useStyletron();
  const selectedActions = css({
    ...theme.typography.LabelMedium,
    color: theme.colors.contentPrimary,
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    paddingTop: theme.sizing.scale400,
    paddingBottom: theme.sizing.scale400,
    paddingLeft: theme.sizing.scale600,
    border: props.noBackground
      ? undefined
      : `1px solid ${theme.colors.borderTransparent}`,
    borderRadius: theme.borders.radius200,
    backgroundColor: props.noBackground
      ? undefined
      : theme.colors.contentInversePrimary,
    marginLeft: "auto",
  });

  const selectedText = css({
    marginRight: theme.sizing.scale500,
    fontWeight: 500,
    alignItems: "center",
  });

  const batchActions = css({
    display: "flex",
    marginLeft: "auto",
  });

  return props.count ? (
    <div className={selectedActions}>
      <Block
        as="span"
        display={["none", "flex", "flex", "flex"]}
        className={selectedText}
      >
        <CheckmarkCircle
          fill={theme.colors.accent}
          className={css({ marginRight: theme.sizing.scale300 })}
        />{" "}
        {props.count && `${props.count} selected`}
      </Block>
      <div className={batchActions}>{props.children}</div>
    </div>
  ) : null;
}
