import React from "react";
import { useStyletron } from "baseui";
import { Button, KIND, SIZE, ButtonOverrides } from "baseui/button";
import { StatefulTooltip, PLACEMENT, ACCESSIBILITY_TYPE } from "baseui/tooltip";

interface Props {
  children?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  icon?: React.ReactNode;
  tooltip?: string;
  onClick?: (e: any) => void;
}

export const ListItem: React.FunctionComponent<Props> = (props: Props) => {
  const [, theme] = useStyletron();

  const buttonOverrides: ButtonOverrides = {
    BaseButton: {
      style: {
        width: "100%",
        justifyContent: "flex-start",
        fontWeight: 500,
        color: theme.colors.contentPrimary,
        backgroundColor: theme.colors.backgroundPrimary,
        ":hover": {
          backgroundColor: theme.colors.backgroundTertiary,
        },
      },
    },
    StartEnhancer: { style: { alignSelf: "flex-start" } },
  };

  const button = (
    <Button
      kind={KIND.tertiary}
      size={SIZE.compact}
      onClick={props.onClick}
      overrides={buttonOverrides}
      startEnhancer={props.icon ?? undefined}
      endEnhancer={props.endEnhancer}
    >
      {props.children}
    </Button>
  );

  return (
    <StatefulTooltip
      accessibilityType={ACCESSIBILITY_TYPE.tooltip}
      content={props.tooltip ? props.tooltip : undefined}
      placement={PLACEMENT.right}
    >
      <li>{button}</li>
    </StatefulTooltip>
  );
};
