import React from "react";
import { useStyletron } from "baseui";
import BoldText from "./BoldText";

interface Props {
  startValue: number;
  endValue: number;
  total: number;
}

const TableShowing = (props: Props) => {
  const [css, theme] = useStyletron();
  return (
    <div className={css({ color: theme.colors.contentSecondary })}>
      Showing <BoldText>{props.startValue}</BoldText> to{" "}
      <BoldText>{props.endValue}</BoldText> of{" "}
      <BoldText>{props.total}</BoldText> results
    </div>
  );
};

export default TableShowing;
