import React from "react";
import Account from "./pages/Account";
import Assets from "./pages/Assets";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import Products from "./pages/Products";
import { AppLayout } from "./layouts/AppLayout";
import { AppRoute } from "./utils/AppRoute";
import { BrowserRouter as Router } from "react-router-dom";
import { ProductEdit } from "./pages/ProductEdit";
import { CustomerEdit } from "./pages/CustomerEdit";
import { PublicLayout } from "./layouts/PublicLayout";
import { UserSettings } from "./pages/UserSettings";
import Projects from "./pages/Projects";
import Customers from "./pages/Customers";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Charts from "./pages/Charts";

// ------------------------------------------------------------------
// This file controls which components are rendered at each route.
// ------------------------------------------------------------------

export default function AppRoutes() {
  return (
    <Router>
      <AppRoute path="/" component={Dashboard} layout={AppLayout} exact />
      <AppRoute
        path="/products"
        exact
        component={Products}
        layout={AppLayout}
      />
      <AppRoute
        path="/products/:code"
        layout={AppLayout}
        component={ProductEdit}
      />
      <AppRoute
        path="/product-page"
        layout={AppLayout}
        component={ProductEdit}
      />
      <AppRoute path="/orders" component={Orders} layout={AppLayout} />
      <AppRoute
        path="/customers"
        exact
        component={Customers}
        layout={AppLayout}
      />
      <AppRoute
        path="/customers/:id"
        layout={AppLayout}
        component={CustomerEdit}
      />
      <AppRoute
        path="/customer-page"
        layout={AppLayout}
        component={CustomerEdit}
      />
      <AppRoute path="/charts" component={Charts} layout={AppLayout} exact />
      <AppRoute path="/assets" component={Assets} layout={AppLayout} />
      <AppRoute path="/projects" component={Projects} layout={AppLayout} />
      <AppRoute
        path="/user-settings"
        component={UserSettings}
        layout={AppLayout}
      />
      <AppRoute path="/account" layout={PublicLayout} component={Account} />
      <AppRoute path="/403" layout={PublicLayout} component={Error403} />
      <AppRoute path="/404" layout={PublicLayout} component={Error404} />
      <AppRoute path="/500" layout={PublicLayout} component={Error500} />
    </Router>
  );
}
