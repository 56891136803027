import React from "react";
import { useStyletron } from "baseui";
import { HeadingXSmall } from "baseui/typography";

interface Props {
  title: React.ReactNode;
}

export const Card: React.FC<Props> = (props) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        padding: `${theme.sizing.scale700} ${theme.sizing.scale800} ${theme.sizing.scale800}`,
        maxWidth: "900px",
        marginBottom: theme.sizing.scale900,
        backgroundColor: theme.colors.backgroundPrimary,
        borderRadius: theme.borders.radius300,
        boxShadow: theme.lighting.shadow400,
      })}
    >
      <div
        className={css({
          ...theme.typography.ParagraphMedium,
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginBottom: theme.sizing.scale800,
          minHeight: "40px",
          "::before": {
            background: theme.colors.borderOpaque,
            bottom: "0",
            content: '" "',
            display: "block",
            height: "1px",
            left: "0",
            position: "absolute",
            right: "0",
          },
        })}
      >
        <HeadingXSmall margin={0}>{props.title}</HeadingXSmall>
      </div>
      <div className={css({ flexGrow: 1, position: "relative" })}>
        {props.children}
      </div>
    </div>
  );
};
