import React from "react";
import { useStyletron } from "baseui";
import { FormControl } from "baseui/form-control";
import { StatefulInput } from "baseui/input";
import { Select, Value } from "baseui/select";
import FileUpload from "../components/FileUpload";

interface Props {
  loading: boolean;
  profileEdit: boolean;
  toggleProfileEdit: () => void;
}

export const ProfileTable: React.FC<Props> = (props) => {
  const [css, theme] = useStyletron();
  const [iconValue, setIconValue] = React.useState<Value>([]);

  const row = css({
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    lineHeight: 2.4,
    [theme.mediaQuery.small]: { flexDirection: "row", alignItems: "center" },
  });
  const dt = css({
    boxSizing: "content-box",
    flex: "0 0 25%",
    marginLeft: "0",
    marginRight: "24px",
    minWidth: "168px",
    transform: "none",
    color: theme.colors.contentSecondary,
  });
  const dd = css({
    fontWeight: 500,
    color: theme.colors.contentPrimary,
    marginLeft: 0,
    [theme.mediaQuery.small]: { flex: "1 1 300px" },
  });
  return (
    <>
      {props.profileEdit ? (
        <form id="my-form">
          <FormControl label={() => "Profile photo"}>
            <FileUpload
              disabled={props.loading}
              message="Select a photo to upload, or drag and drop"
            />
          </FormControl>
          <FormControl disabled={props.loading} label={() => "Name"}>
            <StatefulInput
              initialState={{ value: "Tom Watts" }}
              maxLength={60}
              placeholder="John Smith"
            />
          </FormControl>
          <FormControl disabled={props.loading} label={() => "Email"}>
            <StatefulInput
              initialState={{ value: "tom@fakemail.com" }}
              maxLength={120}
              placeholder="johnsmith@email.com"
            />
          </FormControl>
          <FormControl disabled={props.loading} label={() => "Password"}>
            <StatefulInput
              initialState={{ value: "*****************" }}
              maxLength={80}
              placeholder="A strong password"
            />
          </FormControl>
          <FormControl disabled={props.loading} label={() => "Team members"}>
            <Select
              options={[
                { id: "Buck Pucker" },
                { id: "Bernard Fuddle" },
                { id: "Luther Peters" },
                { id: "Glenn Morrice" },
              ]}
              placeholder="Select existing or add new team members"
              labelKey="id"
              valueKey="id"
              multi
              creatable
              onChange={(params) => setIconValue(params.value)}
              value={iconValue}
            />
          </FormControl>
        </form>
      ) : (
        <dl>
          <div className={row}>
            <dt className={dt}>Avatar</dt>
            <dd className={dd}>
              <img
                className="tw-rounded-50 tw-inline-block tw-text-xl tw-text-center tw-self-center tw-object-cover tw-w-4 tw-h-4 tw-leading-chill"
                src="https://avatars2.githubusercontent.com/u/6745684?s=40"
                width="40"
                height="40"
                alt="Tom Watts's avatar"
              />
            </dd>
          </div>
          <div className={row}>
            <dt className={dt}>Name</dt>
            <dd className={dd}>Tom Watts</dd>
          </div>
          <div className={row}>
            <dt className={dt}>Email</dt>
            <dd className={dd}>tom@email.com</dd>
          </div>
          <div className={row}>
            <dt className={dt}>Team members</dt>
            <dd className={dd}>
              <span className="placeholder">Not set</span>
            </dd>
          </div>
        </dl>
      )}
    </>
  );
};
