import React from "react";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Link, RouteComponentProps } from "react-router-dom";
import { Customer, CUSTOMERS_DATA } from "../utils/mockData";
import { SubNavigation } from "../components/SubNav";
import { Header } from "../components/Header";
import { FormControl } from "baseui/form-control";
import { useStyletron } from "baseui";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { Row } from "../components/Row";
import OptionsMenu from "../components/OptionsMenu";
import { ListItem as List } from "baseui/list";
import { ListItem } from "../components/ListItem";
import StyledButton from "../components/StyledButton";
import DropdownMenu from "../components/DropdownMenu";
import { ReactComponent as Delete } from "@fluentui/svg-icons/icons/delete_20_regular.svg";
import { ReactComponent as ArrowReset } from "@fluentui/svg-icons/icons/arrow_reset_20_regular.svg";
import { ReactComponent as PersonArrowRight } from "@fluentui/svg-icons/icons/person_arrow_right_20_regular.svg";
import { ReactComponent as ArrowDownload } from "@fluentui/svg-icons/icons/arrow_download_20_regular.svg";
import { ReactComponent as ChevronRight } from "@fluentui/svg-icons/icons/chevron_right_16_regular.svg";
import { Tabs, Tab } from "baseui/tabs-motion";
import { Grid, Cell } from "baseui/layout-grid";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { PaymentCard } from "baseui/payment-card";
import { ListCol } from "../components/DashboardRecentOrders";

export type RouteParamsProduct = {
  id: string;
};

export const CustomerEdit: React.FC<RouteComponentProps<RouteParamsProduct>> = (
  props
) => {
  // get customer ID from route, otherwise fallback to an example customer
  const selectedCustomer: Customer = CUSTOMERS_DATA.filter(
    (item: Customer) =>
      item.id === (props.match.params.id || CUSTOMERS_DATA[0].id)
  )[0];
  const [css, theme] = useStyletron();
  const [activeKey, setActiveKey] = React.useState<React.Key>(0);

  const listCard = css({
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    borderRadius: theme.borders.radius300,
    flex: 1,
    background: theme.colors.backgroundPrimary,
    paddingTop: theme.sizing.scale600,
    paddingBottom: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale200,
    paddingRight: theme.sizing.scale200,
    textAlign: "left",
    boxShadow: theme.lighting.shadow400,
    [theme.mediaQuery.large]: {
      paddingTop: theme.sizing.scale800,
      paddingBottom: theme.sizing.scale800,
      paddingLeft: theme.sizing.scale500,
      paddingRight: theme.sizing.scale500,
    },
  });

  const listHeader = css({
    display: "flex",
    flex: "1",
    maxWidth: "100%",
    flexFlow: "row nowrap",
    alignItems: "center",
    paddingLeft: theme.sizing.scale100,
    paddingRight: theme.sizing.scale0,
    [theme.mediaQuery.large]: {
      paddingLeft: theme.sizing.scale500,
      paddingRight: theme.sizing.scale100,
    },
  });

  const listHeading = css({
    ...theme.typography.HeadingXSmall,
    flex: 1,
    color: theme.colors.contentPrimary,
    fontWeight: 600,
    lineHeight: 1,
    marginTop: 0,
    marginBottom: 0,
  });

  const tableHeading = css({
    ...theme.typography.LabelSmall,
    flex: 1,
    color: theme.colors.contentTertiary,
    fontWeight: 500,
    lineHeight: 1,
    marginTop: 0,
    marginBottom: 0,
  });

  const listOverrides = { Root: { style: { minWidth: "900px" } } };

  return (
    <>
      <Row borderBottom>
        <Grid>
          <Cell span={12}>
            <Header
              breadcrumbs={
                <Breadcrumbs
                  overrides={{
                    ListItem: {
                      style: {
                        fontSize: "14px",
                      },
                    },
                    Separator: {
                      component: () => (
                        <span
                          className={css({
                            position: "relative",
                            top: "2px",
                            verticalAlign: "middle",
                            padding: `0 ${theme.sizing.scale200}`,
                          })}
                        >
                          <ChevronRight fill={theme.colors.contentSecondary} />
                        </span>
                      ),
                    },
                  }}
                >
                  <Link
                    to="/customers"
                    className={css({
                      color: theme.colors.accent,
                      textDecoration: "none",
                    })}
                  >
                    Customers
                  </Link>
                  <span
                    className={css({
                      fontWeight: 400,
                      color: theme.colors.contentSecondary,
                    })}
                  >
                    Edit Customer
                  </span>
                </Breadcrumbs>
              }
            />

            <SubNavigation
              title={
                <div
                  className={css({
                    display: "flex",
                    flexFlow: "column wrap",
                    alignItems: "flex-start",
                    [theme.mediaQuery.medium]: {
                      flexFlow: "row nowrap",
                      alignItems: "center",
                    },
                  })}
                >
                  {selectedCustomer.name}
                </div>
              }
              backRoute="/customers"
              navItems={
                <>
                  <DropdownMenu label="More options">
                    <ListItem
                      key="item-reset-password"
                      icon={
                        <PersonArrowRight
                          fill={theme.colors.contentSecondary}
                        />
                      }
                    >
                      Sign in as customer
                    </ListItem>
                    <ListItem
                      key="item-reset-password"
                      icon={<ArrowReset fill={theme.colors.contentSecondary} />}
                    >
                      Reset password
                    </ListItem>
                    <ListItem
                      key="item-export-data"
                      icon={
                        <ArrowDownload fill={theme.colors.contentSecondary} />
                      }
                    >
                      Export data
                    </ListItem>
                    <ListItem
                      key="item-delete"
                      icon={<Delete fill={theme.colors.contentSecondary} />}
                    >
                      Delete account
                    </ListItem>
                  </DropdownMenu>
                  <span
                    className={css({
                      marginLeft: "auto",
                      [theme.mediaQuery.large]: { marginLeft: 0 },
                    })}
                  ></span>
                  <StyledButton
                    kind="primary"
                    size="compact"
                    marginLeft={theme.sizing.scale500}
                  >
                    Save
                  </StyledButton>
                </>
              }
            />
          </Cell>
        </Grid>
      </Row>
      <Row
        background={theme.colors.backgroundPrimary}
        fullHeight
        paddingTop
        paddingBottom
      >
        <Grid>
          <Cell span={12}>
            <Tabs
              activeKey={activeKey}
              onChange={({ activeKey }) => setActiveKey(activeKey)}
            >
              <Tab title="Customer Details">
                <div className={css({ display: "flex", flexFlow: "row wrap" })}>
                  <div
                    className={css({
                      flex: "0 1 700px",
                      marginRight: theme.sizing.scale800,
                    })}
                  >
                    <FormControl label={() => "Name"}>
                      <Input value={selectedCustomer.name} />
                    </FormControl>
                    <FormControl label={() => "Description"}>
                      <Textarea value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras turpis nulla, facilisis at felis non, scelerisque vehicula quam. Sed quis nisi nec massa facilisis gravida." />
                    </FormControl>

                    <FlexGrid
                      flexGridColumnCount={2}
                      flexGridColumnGap="scale800"
                    >
                      <FlexGridItem>
                        <FormControl label={() => "Address"}>
                          <Input value={selectedCustomer.address} />
                        </FormControl>
                      </FlexGridItem>
                      <FlexGridItem>
                        <FormControl label={() => "Email"}>
                          <Input value={selectedCustomer.email} />
                        </FormControl>
                      </FlexGridItem>
                      <FlexGridItem>
                        <FormControl label={() => "Last Order"}>
                          <Input value={selectedCustomer.lastOrder} />
                        </FormControl>
                      </FlexGridItem>
                      <FlexGridItem>
                        <FormControl label={() => "Total orders"}>
                          <Input value={selectedCustomer.orders} />
                        </FormControl>
                      </FlexGridItem>
                    </FlexGrid>
                  </div>
                </div>
              </Tab>
              <Tab title="Billing details">
                <div className={css({ display: "flex", flexFlow: "row wrap" })}>
                  <div
                    className={css({
                      flex: "0 1 400px",
                      marginRight: theme.sizing.scale800,
                    })}
                  >
                    <FormControl label={"Card number"}>
                      <FormControl>
                        <PaymentCard
                          value="**** **** **** **78"
                          placeholder="Card number"
                        />
                      </FormControl>
                    </FormControl>
                    <FlexGrid
                      flexGridColumnCount={2}
                      flexGridColumnGap="scale800"
                    >
                      <FlexGridItem>
                        <FormControl label="Expiration date">
                          <Input value="01/01" placeholder="MM/YY" />
                        </FormControl>
                      </FlexGridItem>
                      <FlexGridItem>
                        <FormControl label="CVC">
                          <Input value={"123"} placeholder="CVC" />
                        </FormControl>
                      </FlexGridItem>
                    </FlexGrid>
                    <p>
                      <StyledButton kind="secondary" size="compact">
                        Edit billing details
                      </StyledButton>
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab title="Invoices">
                <div className={listCard}>
                  <div className={listHeader}>
                    <h4 className={listHeading}>All invoices</h4>
                    <OptionsMenu
                      icon={
                        <ArrowDownload fill={theme.colors.contentPrimary} />
                      }
                    />
                  </div>
                  <ul
                    className={css({
                      width: "100%",
                      maxWidth: "100%",
                      overflowX: "auto",
                      paddingLeft: 0,
                      paddingRight: 0,
                    })}
                  >
                    <List sublist overrides={listOverrides}>
                      <ListCol width="25%">
                        <span className={tableHeading}>Order Number</span>
                      </ListCol>
                      <ListCol width="25%">
                        <span className={tableHeading}>Date</span>
                      </ListCol>
                      <ListCol width="20%">
                        <span className={tableHeading}>Item</span>
                      </ListCol>
                      <ListCol width="6%">
                        <span className={tableHeading}>Payment</span>
                      </ListCol>
                      <ListCol width="6%">
                        <span className={tableHeading}>Total</span>
                      </ListCol>
                      <ListCol width="6%">
                        <span className={tableHeading}>Status</span>
                      </ListCol>
                      <ListCol width="5%" numeric>
                        <span className={tableHeading}>&nbsp;</span>
                      </ListCol>
                    </List>

                    <List sublist overrides={listOverrides}>
                      <ListCol width="25%">17 May 2021 &nbsp;17:44 PM</ListCol>
                      <ListCol width="25%">Kevin Peters</ListCol>
                      <ListCol width="20%">Completed</ListCol>
                      <ListCol width="6%">Completed</ListCol>
                      <ListCol width="6%">$443.54</ListCol>
                      <ListCol width="6%">Completed</ListCol>
                      <ListCol width="5%" numeric>
                        <OptionsMenu />
                      </ListCol>
                    </List>
                    <List sublist overrides={listOverrides}>
                      <ListCol width="25%">17 May 2021 &nbsp;10:51 AM</ListCol>
                      <ListCol width="25%">Frank Badger</ListCol>
                      <ListCol width="20%">Completed</ListCol>
                      <ListCol width="6%">Completed</ListCol>
                      <ListCol width="6%">$98.75</ListCol>
                      <ListCol width="6%">Completed</ListCol>
                      <ListCol width="5%" numeric>
                        <OptionsMenu />
                      </ListCol>
                    </List>
                    <List sublist overrides={listOverrides}>
                      <ListCol width="25%">17 May 2021 &nbsp;10:51 AM</ListCol>
                      <ListCol width="25%">Lisa McMannus</ListCol>
                      <ListCol width="20%">Completed</ListCol>
                      <ListCol width="6%">Completed</ListCol>
                      <ListCol width="6%">$643.98</ListCol>
                      <ListCol width="6%">Completed</ListCol>
                      <ListCol width="5%" numeric>
                        <OptionsMenu />
                      </ListCol>
                    </List>
                  </ul>
                </div>
              </Tab>
            </Tabs>
          </Cell>
        </Grid>
      </Row>
    </>
  );
};
