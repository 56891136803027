import React from "react";
import { useStyletron } from "baseui";
import { HeadingXSmall } from "baseui/typography";

interface Props {
  heading: string;
  subheading?: string;
}

export const SectionHeader = (props: Props) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <HeadingXSmall margin={0}>{props.heading}</HeadingXSmall>
      <div
        className={css({
          ...theme.typography.ParagraphMedium,
          marginBottom: theme.sizing.scale600,
          color: theme.colors.contentSecondary,
        })}
      >
        {props.subheading}
      </div>
    </>
  );
};
