import React from "react";
import { useStyletron } from "baseui";
import OptionsMenu from "./OptionsMenu";

interface ListCardProps {
  header: React.ReactNode;
  subheader?: React.ReactNode;
  hideOptions?: boolean;
}

export const ListCard: React.FC<ListCardProps> = (props) => {
  const [css, theme] = useStyletron();

  const listCard = css({
    display: "flex",
    flexFlow: "column nowrap",
    boxSizing: "border-box",
    position: "relative",
    borderRadius: theme.borders.radius300,
    flex: 1,
    background: theme.colors.backgroundPrimary,
    paddingTop: theme.sizing.scale600,
    paddingBottom: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale200,
    paddingRight: theme.sizing.scale200,
    textAlign: "left",
    boxShadow: theme.lighting.shadow400,
    [theme.mediaQuery.large]: {
      paddingTop: theme.sizing.scale800,
      paddingBottom: theme.sizing.scale800,
      paddingLeft: theme.sizing.scale500,
      paddingRight: theme.sizing.scale500,
    },
  });

  const listHeader = css({
    display: "flex",
    flex: "1",
    maxWidth: "100%",
    flexFlow: "row nowrap",
    alignItems: "center",
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale0,
    [theme.mediaQuery.large]: {
      paddingLeft: theme.sizing.scale500,
      paddingRight: theme.sizing.scale100,
    },
  });

  const headingContain = css({
    display: "flex",
    flex: "1",
    maxWidth: "100%",
    flexFlow: "column nowrap",
  });

  const listHeading = css({
    ...theme.typography.HeadingXSmall,
    flex: 1,
    color: theme.colors.contentPrimary,
    fontWeight: 600,
    lineHeight: 1,
    marginTop: 0,
    marginBottom: props.subheader ? theme.sizing.scale200 : 0,
    whiteSpace: "nowrap",
    maxWidth: "calc(100% - 10px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
  });
  const listSubHeading = css({
    ...theme.typography.LabelMedium,
    color: theme.colors.contentTertiary,
    marginTop: 0,
    marginBottom: theme.sizing.scale400,
    whiteSpace: "nowrap",
    maxWidth: "calc(100% - 10px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 500,
    [theme.mediaQuery.large]: {
      marginBottom: theme.sizing.scale800,
    },
  });

  return (
    <div className={listCard}>
      <div className={listHeader}>
        <div className={headingContain}>
          <h4 className={listHeading}>{props.header}</h4>
          {props.subheader && (
            <p className={listSubHeading}>{props.subheader}</p>
          )}
        </div>
        {!props.hideOptions && <OptionsMenu />}
      </div>
      {props.children}
    </div>
  );
};
