import React from "react";
import { useStyletron } from "baseui";
interface Props {
  hasMargin?: boolean;
}

export const MainHeading: React.FC<Props> = ({ hasMargin, children }) => {
  const [css, theme] = useStyletron();
  return (
    <h1
      className={css({
        fontWeight: 700,
        fontSize: theme.sizing.scale850,
        lineHeight: theme.sizing.scale850,
        marginBottom: hasMargin ? theme.sizing.scale900 : 0,
        marginTop: 0,
        color: theme.colors.contentPrimary,
      })}
    >
      {children}
    </h1>
  );
};
