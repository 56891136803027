import React from "react";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Link, RouteComponentProps } from "react-router-dom";
import { Product, PRODUCT_DATA } from "../utils/mockData";
import { SubNavigation } from "../components/SubNav";
import { Header } from "../components/Header";
import { FormControl } from "baseui/form-control";
import { useStyletron } from "baseui";
import { Input } from "baseui/input";
import { Select, Value } from "baseui/select";
import { Textarea } from "baseui/textarea";
import { Row } from "../components/Row";
import FileUpload from "../components/FileUpload";
import { HeadingXSmall } from "baseui/typography";
import { ListItem } from "../components/ListItem";
import ProductImage from "../components/ProductImage";
import StatusTag from "../components/StatusTag";
import StyledButton from "../components/StyledButton";
import DropdownMenu from "../components/DropdownMenu";
import { ReactComponent as Delete } from "@fluentui/svg-icons/icons/delete_20_regular.svg";
import { ReactComponent as CheckmarkCircle } from "@fluentui/svg-icons/icons/checkmark_circle_20_regular.svg";
import { ReactComponent as ChevronRight } from "@fluentui/svg-icons/icons/chevron_right_16_regular.svg";
import { Grid, Cell } from "baseui/layout-grid";
import { Breadcrumbs } from "baseui/breadcrumbs";

export type RouteParamsProduct = {
  code: string;
};

export const ProductEdit: React.FC<RouteComponentProps<RouteParamsProduct>> = (
  props
) => {
  // get product ID from route, otherwise fallback to an example product
  const selectedProduct: Product = PRODUCT_DATA.filter(
    (item: Product) =>
      item.code === (props.match.params.code || PRODUCT_DATA[0].code)
  )[0];
  const tags = selectedProduct.tags.map((tag) => ({ label: tag }));
  const [value, setValue] = React.useState<Value>(tags);
  const [css, theme] = useStyletron();

  const productImageRow = css({
    display: "flex",
    flexFlow: "column nowrap",
  });

  const productImageContain = css({
    display: "flex",
    marginBottom: theme.sizing.scale800,
  });
  return (
    <>
      <Row borderBottom>
        <Grid>
          <Cell span={12}>
            <Header
              breadcrumbs={
                <Breadcrumbs
                  overrides={{
                    ListItem: {
                      style: {
                        fontSize: "14px",
                      },
                    },
                    Separator: {
                      component: () => (
                        <span
                          className={css({
                            position: "relative",
                            top: "2px",
                            verticalAlign: "middle",
                            padding: `0 ${theme.sizing.scale200}`,
                          })}
                        >
                          <ChevronRight fill={theme.colors.contentSecondary} />
                        </span>
                      ),
                    },
                  }}
                >
                  <Link
                    to="/products"
                    className={css({
                      color: theme.colors.accent,
                      textDecoration: "none",
                    })}
                  >
                    Products
                  </Link>
                  <span
                    className={css({
                      fontWeight: 400,
                      color: theme.colors.contentSecondary,
                    })}
                  >
                    Edit product
                  </span>
                </Breadcrumbs>
              }
            />
            <SubNavigation
              title={
                <div
                  className={css({
                    display: "flex",
                    flex: "1",
                    flexFlow: "row wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    [theme.mediaQuery.medium]: {
                      flexFlow: "row nowrap",
                      alignItems: "center",
                    },
                  })}
                >
                  {selectedProduct.name}
                  &nbsp;
                  <StatusTag status={selectedProduct.status} showIcon />
                </div>
              }
              backRoute="/products"
              navItems={
                <>
                  {/* <StyledBaseButton
                  kind={KIND.primary}
                  className={css({ borderRadius: theme.borders.radius200 })}
                >
                  MAYBE: Could make 3 custom buttons (<ButtonPrimary><ButtonSecondary><ButtonTertiary>)
                  where I get to control every aspect and use custom props?
                </StyledBaseButton> */}
                  <DropdownMenu label="More options">
                    <ListItem
                      key="item-change-status"
                      icon={
                        <CheckmarkCircle fill={theme.colors.contentSecondary} />
                      }
                    >
                      Change status
                    </ListItem>
                    <ListItem
                      key="item-delete"
                      icon={<Delete fill={theme.colors.contentSecondary} />}
                    >
                      Delete
                    </ListItem>
                  </DropdownMenu>
                  <span
                    className={css({
                      marginLeft: "auto",
                      [theme.mediaQuery.large]: { marginLeft: 0 },
                    })}
                  ></span>
                  <StyledButton
                    kind="secondary"
                    size="compact"
                    marginLeft={theme.sizing.scale500}
                  >
                    Preview
                  </StyledButton>
                  <StyledButton
                    kind="primary"
                    size="compact"
                    marginLeft={theme.sizing.scale500}
                  >
                    Save
                  </StyledButton>
                </>
              }
            />
          </Cell>
        </Grid>
      </Row>

      <Row
        background={theme.colors.backgroundPrimary}
        fullHeight
        paddingTop
        paddingBottom
      >
        <Grid>
          <Cell span={12}>
            <div className={css({ display: "flex", flexFlow: "row wrap" })}>
              <div
                className={css({
                  flex: "0 1 700px",
                  marginRight: theme.sizing.scale800,
                  marginTop: theme.sizing.scale600,
                })}
              >
                <HeadingXSmall margin={0}>Product information</HeadingXSmall>
                <FormControl label={() => "Name"}>
                  <Input value={selectedProduct.name} />
                </FormControl>
                <FormControl label={() => "Description"}>
                  <Textarea value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras turpis nulla, facilisis at felis non, scelerisque vehicula quam. Sed quis nisi nec massa facilisis gravida." />
                </FormControl>

                <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800">
                  <FlexGridItem>
                    <FormControl label={() => "Code"}>
                      <Input value={selectedProduct.code} />
                    </FormControl>
                  </FlexGridItem>
                  <FlexGridItem>
                    <FormControl label={() => "Price"}>
                      <Input value={selectedProduct.price} />
                    </FormControl>
                  </FlexGridItem>
                  <FlexGridItem>
                    <FormControl label={() => "Stock"}>
                      <Input value={selectedProduct.stock} />
                    </FormControl>
                  </FlexGridItem>
                </FlexGrid>
                <FormControl label={() => "Tags"}>
                  <Select
                    creatable
                    multi
                    options={[
                      { id: "Leather", label: "Leather" },
                      { id: "Nylon", label: "Nylon" },
                      { id: "Streetwear", label: "Streetwear" },
                      { id: "Shoes", label: "Shoes" },
                      { id: "Accessories", label: "Accessories" },
                      { id: "Casual", label: "Casual" },
                    ]}
                    labelKey="label"
                    valueKey="id"
                    onChange={({ value }) => setValue(value)}
                    value={value}
                  />
                </FormControl>
                <FlexGrid flexGridColumnCount={1}>
                  <FlexGridItem>
                    <FormControl
                      label={() => (
                        <HeadingXSmall margin={0} paddingBottom="scale400">
                          Product photos
                        </HeadingXSmall>
                      )}
                    >
                      <div className={productImageRow}>
                        <div className={productImageContain}>
                          <ProductImage
                            src={selectedProduct.src}
                            alt="Product thumbnail 1"
                            marginRight={theme.sizing.scale800}
                            width="100px"
                          />
                          <ProductImage
                            src={selectedProduct.src}
                            alt="Product thumbnail 2"
                            marginRight={theme.sizing.scale800}
                            width="100px"
                          />
                          <ProductImage
                            src={selectedProduct.src}
                            alt="Product thumbnail 3"
                            marginRight={theme.sizing.scale800}
                            width="100px"
                          />
                        </div>
                        <FileUpload message="Select photos to upload, or drag and drop" />
                      </div>
                    </FormControl>
                  </FlexGridItem>
                </FlexGrid>
                <br />
                <HeadingXSmall margin={0} paddingBottom="scale400">
                  SEO
                </HeadingXSmall>
                <FormControl label={() => "SEO title"}>
                  <Input value={selectedProduct.name} />
                </FormControl>
                <FormControl label={() => "SEO Description"}>
                  <Textarea value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras turpis nulla, facilisis at felis non, scelerisque vehicula quam. Sed quis nisi nec massa facilisis gravida." />
                </FormControl>
                <FormControl label={() => "SEO keywords"}>
                  <Select
                    creatable
                    multi
                    labelKey="label"
                    valueKey="label"
                    onChange={({ value }) => setValue(value)}
                    value={tags}
                  />
                </FormControl>
              </div>

              <div className={css({ flex: "0 0 300px" })}>
                <FormControl
                  label={() => (
                    <HeadingXSmall margin={0} paddingBottom="scale400">
                      Thumbnail photo
                    </HeadingXSmall>
                  )}
                >
                  <div
                    className={css({
                      width: "236px",
                      height: "auto",
                      display: "block",
                      marginRight: theme.sizing.scale200,
                    })}
                  >
                    <ProductImage
                      src={selectedProduct.src}
                      alt="Product thumbnail 2"
                      width="236px"
                    />
                    <StyledButton
                      kind="secondary"
                      size="compact"
                      marginTop={theme.sizing.scale300}
                    >
                      Choose thumbnail photo
                    </StyledButton>
                  </div>
                </FormControl>
              </div>
            </div>
          </Cell>
        </Grid>
      </Row>
    </>
  );
};
