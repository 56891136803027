import React from "react";
import ProductTable from "../components/ProductTable";
import { Row } from "../components/Row";
import { Cell, Grid } from "baseui/layout-grid";
import { Header } from "../components/Header";

export default function Products() {
  return (
    <Row paddingBottom>
      <Grid>
        <Cell span={12}>
          <Header />
          <ProductTable />
        </Cell>
      </Grid>
    </Row>
  );
}
