import React from "react";
import { useStyletron } from "baseui";
import { Checkbox } from "baseui/checkbox";

export const NotificationToggles = () => {
  const [css] = useStyletron();
  const [checkboxes, setCheckboxes] = React.useState([
    true,
    false,
    false,
    true,
  ]);
  const allChecked = checkboxes.every(Boolean);
  const isIndeterminate = checkboxes.some(Boolean) && !allChecked;
  return (
    <div className={css({ lineHeight: 1 })}>
      <Checkbox
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          setCheckboxes([
            target.checked,
            target.checked,
            target.checked,
            target.checked,
          ]);
        }}
        isIndeterminate={isIndeterminate}
        checked={allChecked}
      >
        Toggle all notifications
      </Checkbox>
      <br />
      <br />
      <Checkbox
        checked={checkboxes[0]}
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          setCheckboxes([
            target.checked,
            checkboxes[1],
            checkboxes[2],
            checkboxes[3],
          ]);
        }}
      >
        Order completed
      </Checkbox>
      <br />
      <Checkbox
        checked={checkboxes[1]}
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          setCheckboxes([
            checkboxes[0],
            target.checked,
            checkboxes[2],
            checkboxes[3],
          ]);
        }}
      >
        New message
      </Checkbox>
      <br />
      <Checkbox
        checked={checkboxes[2]}
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          setCheckboxes([
            checkboxes[0],
            checkboxes[1],
            target.checked,
            checkboxes[3],
          ]);
        }}
      >
        New account created
      </Checkbox>
      <br />
      <Checkbox
        checked={checkboxes[3]}
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          setCheckboxes([
            checkboxes[0],
            checkboxes[1],
            checkboxes[2],
            target.checked,
          ]);
        }}
      >
        Reply to your comment
      </Checkbox>
    </div>
  );
};
