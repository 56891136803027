import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";

interface Props {
  path?: string;
  exact?: boolean;
  layout: React.ComponentType<any>;
  component: React.ComponentType<any>;
  render?(props: RouteComponentProps<any>): React.ReactNode;
}

export const AppRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);
