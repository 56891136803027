import { Client as Styletron } from "styletron-engine-atomic";
import { createDarkTheme } from "baseui";
import { DarkTheme } from "baseui";

// Base Web Theme Overrides
export const baseOverrides = {
  AppContainer: {
    style: () => ({
      height: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      [darkTheme.mediaQuery.medium]: {
        flexFlow: "row nowrap",
      },
    }),
  },
};

// Base Web darkTheme
const primitives = {
  accent: "#37dbde",
  primaryA: DarkTheme.colors.primaryA,
  primaryB: DarkTheme.colors.backgroundPrimary,
  primary: DarkTheme.colors.white,
  primary50: DarkTheme.colors.primary50,
  primary100: DarkTheme.colors.primary100,
  primary200: DarkTheme.colors.primary200,
  primary300: DarkTheme.colors.primary300,
  primary400: DarkTheme.colors.primary400,
  primary500: DarkTheme.colors.primary500,
  primary600: DarkTheme.colors.primary600,
  primary700: DarkTheme.colors.primary700,
  // Accent Palette
  accent50: DarkTheme.colors.accent50,
  accent100: DarkTheme.colors.accent100,
  accent200: DarkTheme.colors.accent200,
  accent300: DarkTheme.colors.accent300,
  accent400: DarkTheme.colors.accent400,
  accent500: DarkTheme.colors.accent500,
  accent600: DarkTheme.colors.accent600,
  accent700: DarkTheme.colors.accent700,
};

const overrides = {
  breakpoints: {
    small: 480,
    medium: 769,
    large: 1200,
  },
  mediaQuery: {
    small: "@media screen and (min-width: 480px)",
    medium: "@media screen and (min-width: 769px)",
    large: "@media screen and (min-width: 1280px)",
  },
  // grid: {
  //   columns: [4, 8, 12],
  //   gutters: [16, 36, 36],
  //   margins: [16, 36, 64],
  //   gaps: 0,
  //   maxWidth: 1280,
  // },

  colors: {
    backgroundLightAccent: "#dafbf4", // "#f2fcfc",
    backgroundAccent: "#1A856D", // "#2ebabd",
    buttonPrimaryHover: "#1A856D",
  },
  lighting: {
    shadow400: "rgb(0 0 0 / 7%) 0px 1px 4px",
  },
  typography: {
    HeadingXLarge: {
      fontWeight: "700",
    },
    // DisplayMedium: {
    //   fontFamily: 'Georgia',
    // },
    // ParagraphXSmall: {
    //   fontFamily: 'Georgia',
    // },
    // DisplayXSmall: {
    //   fontFamily: 'Georgia',
    // },
  },
};
export const engine = new Styletron();
export const darkTheme = createDarkTheme(primitives, overrides);
