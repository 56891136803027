import React from "react";
import { Button, ButtonOverrides, KIND, SHAPE, SIZE } from "baseui/button";
import { styled } from "baseui";

interface Props {
  kind: KIND[keyof KIND];
  size?: SIZE[keyof SIZE];
  shape?: SHAPE[keyof SHAPE];
  fullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
  type?: "button" | "reset" | "submit" | undefined;
  form?: string;
  overrides?: ButtonOverrides;
  onClick?(): void;
  // type?: "warning" | "negative", will need to use withStyle if I want a custom prop
}

const StyledButton: React.FC<Props> = (props) => {
  const {
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    fullWidth,
    ...otherProps
  } = props;
  const CustomButton = styled(Button, ({ $theme }) => ({
    borderTopLeftRadius: !props.shape ? $theme.borders.radius200 : undefined,
    borderBottomLeftRadius: !props.shape ? $theme.borders.radius200 : undefined,
    borderTopRightRadius: !props.shape ? $theme.borders.radius200 : undefined,
    borderBottomRightRadius: !props.shape
      ? $theme.borders.radius200
      : undefined,
    marginLeft: marginLeft ?? undefined,
    marginRight: marginRight ?? undefined,
    marginTop: marginTop ?? undefined,
    marginBottom: marginBottom ?? undefined,
    width: fullWidth ? "100%" : undefined,
  }));

  return <CustomButton {...otherProps} />;
};

export default StyledButton;
