import React from "react";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { useStyletron } from "baseui";
import DashboardStats from "../components/DashboardStats";
import DashboardGraphs from "../components/DashboardGraphs";
import DashboardLists from "../components/DashboardLists";
import { MainHeading } from "../components/MainHeading";
import DashboardRecentOrders from "../components/DashboardRecentOrders";
import { Header } from "../components/Header";

export default function Dashboard() {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        paddingLeft: theme.sizing.scale600,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale600,
        [theme.mediaQuery.large]: {
          paddingBottom: theme.sizing.scale800,
          paddingLeft: theme.sizing.scale800,
          paddingRight: theme.sizing.scale800,
        },
      })}
    >
      <div
        className={css({
          width: "100%",
          maxWidth: "1280px",
          margin: "0 auto",
        })}
      >
        <Header />
        <Breadcrumbs>
          <MainHeading hasMargin>Dashboard</MainHeading>
        </Breadcrumbs>

        <DashboardStats />
        <DashboardGraphs />
        <DashboardLists />
        <DashboardRecentOrders />
      </div>
    </div>
  );
}
