import * as React from "react";
import { ReactComponent as CartFilled } from "@fluentui/svg-icons/icons/cart_20_filled.svg";
import { ReactComponent as DocumentDismiss } from "@fluentui/svg-icons/icons/document_dismiss_20_regular.svg";
import { ReactComponent as DocumentError } from "@fluentui/svg-icons/icons/document_error_20_regular.svg";
import { ReactComponent as DocumentQuestion } from "@fluentui/svg-icons/icons/document_question_mark_20_regular.svg";
import { ReactComponent as FolderFilled } from "@fluentui/svg-icons/icons/folder_20_filled.svg";
import { ReactComponent as HomeFilled } from "@fluentui/svg-icons/icons/home_20_filled.svg";
import { ReactComponent as ImageMultipleFilled } from "@fluentui/svg-icons/icons/image_multiple_20_filled.svg";
import { ReactComponent as NavigationFilled } from "@fluentui/svg-icons/icons/navigation_20_filled.svg";
import { ReactComponent as PeopleCommunityFilled } from "@fluentui/svg-icons/icons/people_team_20_filled.svg";
import { ReactComponent as PersonAdd } from "@fluentui/svg-icons/icons/person_add_20_regular.svg";
import { ReactComponent as Settings } from "@fluentui/svg-icons/icons/settings_20_regular.svg";
import { ReactComponent as PersonArrow } from "@fluentui/svg-icons/icons/person_arrow_right_20_regular.svg";
import { ReactComponent as DataLine } from "@fluentui/svg-icons/icons/data_pie_20_regular.svg";
import { ReactComponent as Person } from "@fluentui/svg-icons/icons/person_20_regular.svg";
import { ReactComponent as PersonQuestion } from "@fluentui/svg-icons/icons/person_question_mark_20_regular.svg";
import { ReactComponent as TagFilled } from "@fluentui/svg-icons/icons/tag_20_filled.svg";
import { ReactComponent as TagRegular } from "@fluentui/svg-icons/icons/tag_20_regular.svg";
import { styled, useStyletron, withStyle } from "baseui";
import { Block } from "baseui/block";
import { Button, KIND, SHAPE } from "baseui/button";
import { ANCHOR, Drawer } from "baseui/drawer";
import { ReactComponent as Logo } from "../assets/brandlogo.svg";
import { NavListItem } from "../components/NavListItem";
import { NavSubList } from "../components/NavSubList";
import NotificationMenu from "../components/NotificationMenu";
import SupportMenu from "../components/SupportMenu";
import UserMenu from "../components/UserMenu";

// ------------------------------------------------------------------
// This file controls the default app layout (sidebar, main, etc)
// ------------------------------------------------------------------

export const AppLayout: React.FC = (props) => {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);

  const sideNav = css({
    display: "none",
    position: "relative",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: "1 0 220px",
    maxWidth: "220px",
    width: "220px",
    padding: theme.sizing.scale400,
    overflow: "hidden",
    top: "0",
    left: "0",
    bottom: "0",
    overflowY: "auto",
    background: theme.colors.backgroundPrimary,
    boxShadow: theme.lighting.shadow400,
    color: theme.colors.backgroundInversePrimary,
    [theme.mediaQuery.medium]: {
      display: "flex",
      flexFlow: "column nowrap",
    },
  });

  const SideNav = styled("ul", () => ({
    display: "flex",
    flex: "1 1 auto",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    width: "100%",
    listStyle: "none",
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  }));

  const MobileSideNav = withStyle(SideNav, () => ({
    marginTop: theme.sizing.scale600,
  }));

  const mobileHeader = css({
    display: "flex",
    justifyContent: "space-between",
    padding: theme.sizing.scale400,
    backgroundColor: theme.colors.backgroundPrimary,
    [theme.mediaQuery.medium]: {
      display: "none",
    },
  });

  const main = css({
    display: "flex",
    flex: "1 1 100%",
    flexFlow: "column nowrap",
    width: "100%",
    backgroundColor: theme.colors.backgroundTertiary,
    overflowX: "hidden",
  });

  const sideNavBrand = css({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.sizing.scale400,
    marginBottom: theme.sizing.scale800,
    marginLeft: theme.sizing.scale600,
    width: "100%",
  });

  const sideNavBrandMobile = css({
    display: "flex",
    alignItems: "center",
    marginLeft: theme.sizing.scale400,
    marginBottom: theme.sizing.scale800,
  });

  const sideNavBrandName = css({
    ...theme.typography.LabelMedium,
    paddingLeft: theme.sizing.scale550,
    fontWeight: 600,
    color: theme.colors.contentPrimary,
  });

  const headerActionsContain = css({
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: theme.sizing.scale100,
  });

  return (
    <>
      <nav className={sideNav}>
        <span className={sideNavBrand}>
          <Logo width="24px" color={theme.colors.accent} />
          <span className={sideNavBrandName}>AmplyVolt</span>
        </span>
        <SideNav>
          <NavListItem key="dashboard" to="/" icon={<HomeFilled />}>
            Dashboard
          </NavListItem>
          <NavListItem key="projects" to="/projects" icon={<FolderFilled />}>
            Projects
          </NavListItem>
          <NavListItem key="products" to="/products" icon={<TagFilled />}>
            Products
          </NavListItem>
          <NavListItem key="orders" to="/orders" icon={<CartFilled />}>
            Orders
          </NavListItem>
          <NavListItem
            key="customers"
            to="/customers"
            icon={<PeopleCommunityFilled />}
          >
            Customers
          </NavListItem>
          <NavListItem key="assets" to="/assets" icon={<ImageMultipleFilled />}>
            Assets
          </NavListItem>
          <NavSubList
            key="other-layouts"
            to="/account"
            subMenu={
              <>
                <NavListItem
                  subList
                  key="user-settings"
                  to="/user-settings"
                  icon={<Settings fill={theme.colors.contentSecondary} />}
                >
                  User settings
                </NavListItem>
                <NavListItem
                  subList
                  key="product-page"
                  to="/product-page"
                  icon={<TagRegular />}
                >
                  Product page
                </NavListItem>
                <NavListItem
                  subList
                  key="customer-page"
                  to="/customer-page"
                  icon={<Person />}
                >
                  Customer page
                </NavListItem>
                <NavListItem
                  subList
                  key="charts-page"
                  to="/charts"
                  icon={<DataLine />}
                >
                  Charts
                </NavListItem>
              </>
            }
          >
            Other layouts
          </NavSubList>
          <NavSubList
            key="account-pages"
            to="/account"
            subMenu={
              <>
                <NavListItem
                  subList
                  key="sign-up"
                  to="/account/sign-up"
                  icon={<PersonAdd />}
                >
                  Create account
                </NavListItem>
                <NavListItem
                  subList
                  key="sign-in"
                  to="/account/sign-in"
                  icon={<PersonArrow />}
                >
                  Sign in
                </NavListItem>
                <NavListItem
                  subList
                  key="forgot-password"
                  to="/account/forgot-password"
                  icon={<PersonQuestion />}
                >
                  Forgot password
                </NavListItem>
              </>
            }
          >
            Account pages
          </NavSubList>
          <NavSubList
            key="error-pages"
            to="/account"
            subMenu={
              <>
                <NavListItem
                  subList
                  key="403"
                  to="/403"
                  icon={<DocumentDismiss />}
                >
                  403
                </NavListItem>
                <NavListItem
                  subList
                  key="404"
                  to="/404"
                  icon={<DocumentQuestion />}
                >
                  404
                </NavListItem>
                <NavListItem
                  subList
                  key="500"
                  to="/500"
                  icon={<DocumentError />}
                >
                  500
                </NavListItem>
              </>
            }
          >
            Error pages
          </NavSubList>

          {/* TODO: Expandable menu items - the prefs menu is a good base, then make a div below it transition height? */}
          {/* <li className={css({ marginTop: "auto" })}>
                <PrefsMenu />
            </li> */}
        </SideNav>
      </nav>

      {/* TODO: MOBILE only header and menu [make component] */}
      <div className={mobileHeader}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          shape={SHAPE.circle}
          kind={KIND.secondary}
        >
          <NavigationFilled fill={theme.colors.contentPrimary} />
        </Button>
        <div className={headerActionsContain}>
          <Block>
            <SupportMenu />
          </Block>
          <Block marginRight={theme.sizing.scale400}>
            <NotificationMenu hasNotifications />
          </Block>
          <UserMenu />
        </div>
      </div>
      <Drawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        anchor={ANCHOR.left}
        overrides={{
          Root: {
            style: {
              display: "block",
              [theme.mediaQuery.medium]: {
                display: "none",
              },
            },
          },
          DrawerContainer: {
            style: {
              maxWidth: "250px",
            },
          },
          DrawerBody: {
            style: {
              marginLeft: 0,
              marginRight: 0,
              padding: `${theme.sizing.scale200} ${theme.sizing.scale500}`,
            },
          },
        }}
      >
        {/* <Link to="/" className={logoContain}>
          <Logo width="24px" color={theme.colors.accent} />
        </Link> */}
        <span className={sideNavBrandMobile}>
          <Logo width="24px" color={theme.colors.accent} />
          <span className={sideNavBrandName}>AmplyVolt</span>
        </span>
        <MobileSideNav>
          <NavListItem
            key="mobile-dashboard"
            to="/"
            icon={<HomeFilled />}
            onClick={() => setIsOpen(!isOpen)}
          >
            Dashboard
          </NavListItem>
          <NavListItem
            key="mobile-projects"
            to="/projects"
            icon={<FolderFilled />}
            onClick={() => setIsOpen(!isOpen)}
          >
            Projects
          </NavListItem>
          <NavListItem
            key="mobile-products"
            to="/products"
            icon={<TagFilled />}
            onClick={() => setIsOpen(!isOpen)}
          >
            Products
          </NavListItem>
          <NavListItem
            key="mobile-orders"
            to="/orders"
            icon={<CartFilled />}
            onClick={() => setIsOpen(!isOpen)}
          >
            Orders
          </NavListItem>
          <NavListItem
            key="mobile-customers"
            to="/customers"
            icon={<PeopleCommunityFilled />}
            onClick={() => setIsOpen(!isOpen)}
          >
            Customers
          </NavListItem>
          <NavListItem
            key="mobile-assets"
            to="/assets"
            icon={<ImageMultipleFilled />}
            onClick={() => setIsOpen(!isOpen)}
          >
            Assets
          </NavListItem>
          <NavSubList
            key="mobile-other-layouts"
            to="/account"
            subMenu={
              <>
                <NavListItem
                  subList
                  key="mobile-user-settings"
                  to="/user-settings"
                  icon={<Settings fill={theme.colors.contentSecondary} />}
                >
                  User settings
                </NavListItem>
                <NavListItem
                  subList
                  key="mobile-product-page"
                  to="/product-page"
                  icon={<TagRegular />}
                >
                  Product page
                </NavListItem>
                <NavListItem
                  subList
                  key="mobile-customer-page"
                  to="/customer-page"
                  icon={<Person />}
                >
                  Customer page
                </NavListItem>
                <NavListItem
                  subList
                  key="mobile-charts-page"
                  to="/charts"
                  icon={<DataLine />}
                >
                  Charts
                </NavListItem>
              </>
            }
          >
            Other layouts
          </NavSubList>
          <NavSubList
            key="mobile-account-pages"
            to="/account"
            subMenu={
              <>
                <NavListItem
                  subList
                  key="sign-up"
                  to="/account/sign-up"
                  icon={<PersonAdd />}
                >
                  Create account
                </NavListItem>
                <NavListItem
                  subList
                  key="sign-in"
                  to="/account/sign-in"
                  icon={<PersonArrow />}
                >
                  Sign in
                </NavListItem>
                <NavListItem
                  subList
                  key="forgot-password"
                  to="/account/forgot-password"
                  icon={<PersonQuestion />}
                >
                  Forgot password
                </NavListItem>
              </>
            }
          >
            Account pages
          </NavSubList>
          <NavSubList
            key="mobile-error-pages"
            to="/account"
            subMenu={
              <>
                <NavListItem
                  subList
                  key="403"
                  to="/403"
                  icon={<DocumentDismiss />}
                >
                  403
                </NavListItem>
                <NavListItem
                  subList
                  key="404"
                  to="/404"
                  icon={<DocumentQuestion />}
                >
                  404
                </NavListItem>
                <NavListItem
                  subList
                  key="500"
                  to="/500"
                  icon={<DocumentError />}
                >
                  500
                </NavListItem>
              </>
            }
          >
            Error pages
          </NavSubList>
        </MobileSideNav>
      </Drawer>

      <main className={main}>{props.children}</main>
    </>
  );
};
