import React from "react";
import { useStyletron } from "baseui";

interface Props {
  label: string;
  value?: number;
  tooltip?: string;
  color: string;
}

export default function ChartLegend(props: Props) {
  const [css, theme] = useStyletron();

  const statCard = css({
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    boxSizing: "border-box",
    position: "relative",
    width: "auto",
    minWidth: "50%",
    maxWidth: "50%",
    paddingTop: theme.sizing.scale200,
    paddingBottom: theme.sizing.scale200,
    // paddingLeft: theme.sizing.scale500,
    paddingRight: theme.sizing.scale300,
    textAlign: "left",
    [theme.mediaQuery.small]: {
      minWidth: "33%",
      maxWidth: "33%",
    },
  });
  const legendColor = css({
    display: "flex",
    alignItems: "center",
    marginRight: theme.sizing.scale400,
    backgroundColor: props.color,
    width: "30px",
    minWidth: "30px",
    height: "30px",
    borderRadius: theme.borders.radius200,
  });
  const statData = css({
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "calc(100% - 32px)",
  });
  const statTitle = css({
    ...theme.typography.LabelXSmall,
    color: theme.colors.contentTertiary,
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: "nowrap",
    maxWidth: "100%",
    minWidth: "50px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 500,
  });
  const statNumber = css({
    ...theme.typography.LabelLarge,
    color: theme.colors.contentSecondary,
    fontSize: theme.sizing.scale500,
    fontWeight: 700,
    lineHeight: 1,
    marginTop: theme.sizing.scale0,
    marginBottom: 0,
    [theme.mediaQuery.large]: {
      fontSize: theme.sizing.scale600,
    },
  });

  return (
    <div className={statCard}>
      <div className={legendColor}></div>
      <div className={statData}>
        <p className={statTitle}>{props.label}</p>
        <span className={statNumber}>
          {(props.value ? props.value : 0).toLocaleString()}
        </span>
      </div>
    </div>
  );
}
