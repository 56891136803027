import React from "react";
import StyledButton from "../components/StyledButton";
import { Block } from "baseui/block";
import { InputEmail } from "../components/InputEmail";
import { StyledAction, StyledBody } from "baseui/card";
import { useStyletron } from "baseui";
import { ParagraphMedium } from "baseui/typography";

interface Props {
  onSubmit(): void;
  isLoading: boolean;
}

export function ForgotPassword(props: Props) {
  const [css, theme] = useStyletron();

  const formContain = css({
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
  });

  const formHeading = css({
    ...theme.typography.HeadingXLarge,
    color: theme.colors.contentPrimary,
    letterSpacing: "-1px",
    fontWeight: 600,
  });

  return (
    <Block
      display="flex"
      flex="1"
      width="100%"
      maxWidth="440px"
      alignItems="center"
    >
      <div className={formContain}>
        <div className={formHeading}>Forgot password?</div>
        <ParagraphMedium>
          Enter the email address you used when you joined and we’ll email you a
          link to reset your password.
        </ParagraphMedium>
        <br />
        <StyledBody>
          <InputEmail />
        </StyledBody>
        <StyledAction>
          <StyledButton
            onClick={props.onSubmit}
            isLoading={props.isLoading}
            kind="primary"
            fullWidth
          >
            Send reset link
          </StyledButton>
        </StyledAction>
      </div>
    </Block>
  );
}
