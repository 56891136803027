import React from "react";
import {
  AreaChart as Chart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useStyletron } from "baseui";

const data = [
  {
    name: "Jan",
    Organic: 4000,
    Paid: 2400,
    amt: 2400,
    hmm: 3,
  },
  {
    name: "Feb",
    Organic: 3000,
    Paid: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    Organic: 2000,
    Paid: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    Organic: 2780,
    Paid: 3908,
    amt: 2000,
  },
  {
    name: "May",
    Organic: 1890,
    Paid: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    Organic: 2390,
    Paid: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    Organic: 3490,
    Paid: 4300,
    amt: 2100,
  },
];

export default function AreaChart() {
  const [, theme] = useStyletron();
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
        height: 300,
        overflow: "hidden",
      }}
    >
      <ResponsiveContainer>
        <Chart
          height={250}
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#da68c0" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#da68c0" stopOpacity={0.1} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="15%"
                stopColor={theme.colors.accent}
                stopOpacity={0.2}
              />
              <stop
                offset="85%"
                stopColor={theme.colors.accent}
                stopOpacity={0.1}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis type="number" domain={[0, 10000]} />
          <CartesianGrid
            stroke={theme.colors.backgroundTertiary}
            strokeDasharray="3 3"
          />
          <Tooltip />
          <Area
            // unit="%"
            type="monotone"
            dataKey="Organic"
            stroke="#da68c0"
            strokeWidth="2"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <Area
            // unit="%"
            type="monotone"
            dataKey="Paid"
            stroke={theme.colors.accent}
            strokeWidth="2"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </Chart>
      </ResponsiveContainer>
    </div>
  );
}
