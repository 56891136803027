import React from "react";
import { useStyletron } from "baseui";
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import { OPTIONS_MENU } from "../utils/mockData";
import { ReactComponent as MoreVerticalFilled } from "@fluentui/svg-icons/icons/more_horizontal_20_filled.svg";

interface Props {
  icon?: React.ReactNode;
  noBackground?: boolean;
}

export default function OptionsMenu(props: Props) {
  const [css, theme] = useStyletron();
  const optionsMenu = css({
    flex: "0 0 auto",
    maxWidth: "100%",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <div className={optionsMenu}>
      <StatefulPopover
        focusLock
        placement={PLACEMENT.bottomRight}
        content={({ close }) => (
          <StatefulMenu
            items={OPTIONS_MENU}
            onItemSelect={() => close()}
            overrides={{
              List: { style: { width: "160px" } },
            }}
          />
        )}
      >
        <Button
          size={SIZE.compact}
          shape={SHAPE.circle}
          kind={!!props.noBackground ? KIND.tertiary : KIND.secondary}
        >
          {props.icon ?? (
            <MoreVerticalFilled fill={theme.colors.contentPrimary} />
          )}
        </Button>
      </StatefulPopover>
    </div>
  );
}
