import React from "react";
import { useState } from "react";
import { Checkbox } from "baseui/checkbox";
import {
  BuilderOverrides,
  TableBuilder,
  TableBuilderColumn,
} from "baseui/table-semantic";
import SelectionBanner from "./SelectionBanner";
import { Button, ButtonOverrides, KIND, SHAPE, SIZE } from "baseui/button";
import { Tag, KIND as TAG_KIND, VARIANT, SIZE as TAG_SIZE } from "baseui/tag";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { MainHeading } from "./MainHeading";
import FilterMenu from "./FilterMenu";
import { Asset, ASSETS_DATA } from "../utils/mockData";
import { Pagination } from "baseui/pagination";
import TableShowing from "./TableShowing";
import StyledButton from "./StyledButton";
import { Input } from "baseui/input";
import { StatefulTooltip } from "baseui/tooltip";
import { ReactComponent as UploadFilled } from "@fluentui/svg-icons/icons/arrow_upload_20_filled.svg";
import { ReactComponent as Info } from "@fluentui/svg-icons/icons/info_24_regular.svg";
import { ReactComponent as Delete } from "@fluentui/svg-icons/icons/delete_20_regular.svg";
import { ReactComponent as Search } from "@fluentui/svg-icons/icons/search_24_regular.svg";
import AssetModal from "./AssetModal";

export default function AssetsTable() {
  const [css, theme] = useStyletron();
  const [data, setData] = useState(ASSETS_DATA);
  const [modalData, setModalData] = useState<Asset>();
  const [page, setPage] = useState(1);
  const [value, setValue] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);

  const hasAny = Boolean(data.length);
  const hasAll = hasAny && data.every((x) => x.selected);
  const hasSome = hasAny && data.some((x) => x.selected);
  const selectedCount = data.length && data.filter((x) => x.selected).length;

  //#region STYLES
  const buttonOverridess: ButtonOverrides = {
    BaseButton: {
      style: {
        paddingLeft: theme.sizing.scale400,
        paddingRight: theme.sizing.scale400,
      },
    },
  };

  const tableOverrides: BuilderOverrides = {
    Root: {
      style: {
        width: "100%",
        borderTopWidth: "0",
        borderRightWidth: "0",
        borderBottomWidth: "0",
        borderLeftWidth: "0",
      },
    },
    Table: {
      style: {
        borderLeftColor: "transparent",
      },
    },
    TableBodyRow: {
      style: {
        borderBottom: `1px solid ${theme.colors.borderTransparent}`,
      },
    },
  };

  const tableHeader = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "row wrap",
    marginBottom: theme.sizing.scale600,
  });

  const headerActions = css({
    display: "flex",
    flexFlow: "row wrap",
    flex: "1 0 auto",
    alignItems: "center",
    justifyContent: "flex-end",
  });

  const primaryActions = css({
    marginLeft: theme.sizing.scale600,
    display: "flex",
    alignItems: "center",
  });

  const tableFilters = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexFlow: "row wrap",
    paddingTop: theme.sizing.scale200,
    paddingBottom: theme.sizing.scale200,
    marginBottom: theme.sizing.scale600,
    borderRadius: theme.borders.radius200,
  });

  const tableSearch = css({
    display: "flex",
    flex: "1",
  });

  const tableColumnOverrides = (args?: {
    width: string;
    align: "left" | "right";
  }) => {
    const width = args && args.width;
    const alignment = args && args.align;
    return {
      TableHeadCell: {
        style: {
          ...theme.typography.LabelXSmall,
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale500,
          textAlign: alignment ?? "left",
          lineHeight: 1.6,
          width: width ?? "auto",
          color: theme.colors.contentTertiary,
          fontWeight: 600,
          "::before": {
            borderLeftWidth: "0",
          },
        },
      },
      TableBodyCell: {
        style: { width: width ?? "auto", verticalAlign: "center" },
      },
    };
  };
  // #endregion

  //#region FUNCTIONS
  function handlePageChange(nextPage: number) {
    if (nextPage < 1) {
      return;
    }
    if (nextPage > Math.ceil(data.length / 3)) {
      return;
    }
    setPage(nextPage);
  }

  function toggleAll() {
    setData((data) =>
      data.map((row: Asset) => ({
        ...row,
        selected: !hasAll,
      }))
    );
  }

  function toggle(event: any) {
    const { name, checked } = event.currentTarget;
    setData((data) =>
      data.map((row: Asset) => ({
        ...row,
        selected: String(row.order) === name ? checked : row.selected,
      }))
    );
  }

  function handleEdit(asset: Asset) {
    setModalOpen(true);
    setModalData(asset);
  }
  //#endregion

  //#region JSX
  const selectionBanner = (
    <div className={headerActions}>
      <SelectionBanner count={selectedCount} noBackground>
        <>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.pill}
            onClick={() => {}}
            overrides={buttonOverridess}
          >
            <Delete
              fill={theme.colors.negative}
              className={css({
                [theme.mediaQuery.large]: {
                  marginRight: theme.sizing.scale200,
                },
              })}
            />
            <Block
              as="span"
              display={["none", "none", "none", "block"]}
              color={theme.colors.contentSecondary}
            >
              Delete
            </Block>
          </Button>
        </>
      </SelectionBanner>
      <div className={primaryActions}>
        <FilterMenu />
        <StyledButton
          kind="primary"
          size="compact"
          marginLeft={theme.sizing.scale500}
        >
          <UploadFilled
            fill={theme.colors.contentInversePrimary}
            className={css({
              [theme.mediaQuery.small]: {
                marginRight: theme.sizing.scale200,
              },
            })}
          />
          <Block as="span" display={["none", "block", "block", "block"]}>
            Upload file
          </Block>
        </StyledButton>
      </div>
    </div>
  );
  //#endregion

  return (
    <>
      <div className={tableHeader}>
        <Breadcrumbs>
          <MainHeading>Assets</MainHeading>
        </Breadcrumbs>
        {selectionBanner}
      </div>

      <div className={tableFilters}>
        <div className={tableSearch}>
          <Input
            value={value}
            startEnhancer={<Search fill={theme.colors.contentSecondary} />}
            onChange={(e: any) => setValue(e.target.value)}
            placeholder="Search assets"
            clearable
            clearOnEscape
          />
        </div>
      </div>

      <TableBuilder data={data} overrides={tableOverrides}>
        <TableBuilderColumn
          overrides={tableColumnOverrides({ width: "0.1%", align: "left" })}
          header={
            <Checkbox
              checked={hasAll}
              isIndeterminate={!hasAll && hasSome}
              onChange={toggleAll}
            />
          }
        >
          {(row: Asset) => (
            <Checkbox
              name={row.order}
              checked={row.selected}
              onChange={toggle}
            />
          )}
        </TableBuilderColumn>

        <TableBuilderColumn
          header="PREVIEW"
          overrides={tableColumnOverrides({ width: "6%", align: "left" })}
        >
          {(row: Asset) => (
            <div className={css({ display: "flex", alignItems: "center" })}>
              <div
                onClick={() => handleEdit(row)}
                className={css({
                  width: "60px",
                  height: "60px",
                  display: "block",
                  backgroundColor: "gray",
                  cursor: "pointer",
                })}
              >
                <img
                  src={row.src}
                  alt="Logo"
                  className={css({
                    width: "60px",
                    height: "60px",
                  })}
                />
              </div>
            </div>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="HANDLE"
          numeric
          overrides={tableColumnOverrides({ width: "4%", align: "left" })}
        >
          {(row: Asset) => (
            <Tag
              color={theme.colors.tagAccentLightFont}
              size={TAG_SIZE.small}
              variant={VARIANT.outlined}
              kind={TAG_KIND.custom}
              closeable={false}
              overrides={{
                Root: {
                  style: {
                    backgroundColor: theme.colors.backgroundLightAccent,
                    borderTopColor: theme.colors.backgroundLightAccent,
                    borderRightColor: theme.colors.backgroundLightAccent,
                    borderBottomColor: theme.colors.backgroundLightAccent,
                    borderLeftColor: theme.colors.backgroundLightAccent,
                    height: "auto",
                  },
                },
              }}
            >
              {row.handle}
            </Tag>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="CREATED ON"
          overrides={tableColumnOverrides({ width: "10%", align: "left" })}
        >
          {(row: Asset) => row.createdOn}
        </TableBuilderColumn>

        <TableBuilderColumn
          header="UPDATED ON"
          overrides={tableColumnOverrides({ width: "10%", align: "left" })}
        >
          {(row: Asset) => row.createdOn}
        </TableBuilderColumn>

        <TableBuilderColumn
          header="UPDATED BY"
          overrides={tableColumnOverrides()}
        >
          {(row: Asset) => row.updatedBy}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="FILE NAME"
          overrides={tableColumnOverrides({ width: "22%", align: "left" })}
        >
          {(row: Asset) => row.fileName}
        </TableBuilderColumn>
        <TableBuilderColumn header="WIDTH" overrides={tableColumnOverrides()}>
          {(row: Asset) => row.width}
        </TableBuilderColumn>
        <TableBuilderColumn header="HEIGHT" overrides={tableColumnOverrides()}>
          {(row: Asset) => row.height}
        </TableBuilderColumn>
        <TableBuilderColumn header="SIZE" overrides={tableColumnOverrides()}>
          {(row: Asset) => row.size}
        </TableBuilderColumn>
        <TableBuilderColumn
          overrides={tableColumnOverrides({ width: "0.1%", align: "left" })}
        >
          {(row: Asset) => (
            <StatefulTooltip accessibilityType={"tooltip"} content="Details">
              <Button
                onClick={() => handleEdit(row)}
                shape={SHAPE.circle}
                kind={KIND.tertiary}
              >
                <Info fill={theme.colors.contentTertiary} />
              </Button>
            </StatefulTooltip>
          )}
        </TableBuilderColumn>
      </TableBuilder>

      <div
        className={css({
          display: "flex",
          flexFlow: "column wrap",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale600,
          paddingLeft: theme.sizing.scale200,
          [theme.mediaQuery.large]: {
            flexFlow: "row wrap",
            paddingTop: theme.sizing.scale100,
          },
        })}
      >
        <TableShowing startValue={1} endValue={10} total={data.length} />
        <Pagination
          currentPage={page}
          numPages={Math.ceil(data.length / 3)}
          onPageChange={({ nextPage }) => handlePageChange(nextPage)}
        />
      </div>

      <AssetModal
        isOpen={modalOpen}
        setIsOpen={() => setModalOpen(false)}
        asset={modalData}
      />
    </>
  );
}
