import React from "react";
import { useStyletron } from "baseui";
import { Button, ButtonOverrides, KIND, SIZE } from "baseui/button";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { ReactComponent as ChevronDown } from "@fluentui/svg-icons/icons/chevron_down_16_filled.svg";

interface Props {
  label: React.ReactNode;
  // icon?: IconNames;
}

const DropdownMenu: React.FC<Props> = (props) => {
  const [css, theme] = useStyletron();

  const contain = css({
    display: "flex",
    flex: "0 0 auto",
    maxWidth: "100%",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
  });

  const buttonOverrides: ButtonOverrides = {
    BaseButton: {
      style: {
        width: "100%",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        justifyContent: "flex-start",
        color: theme.colors.contentPrimary,
        [theme.mediaQuery.medium]: {
          borderTopLeftRadius: theme.borders.radius200,
          borderTopRightRadius: theme.borders.radius200,
          borderBottomLeftRadius: theme.borders.radius200,
          borderBottomRightRadius: theme.borders.radius200,
        },
      },
    },
  };

  return (
    <div className={contain}>
      <StatefulPopover
        ignoreBoundary
        placement={PLACEMENT.bottom}
        content={({ close }) => (
          <ul
            className={css({
              display: "flex",
              flex: "1 1 auto",
              flexFlow: "column nowrap",
              justifyContent: "flex-start",
              width: "220px",
              listStyle: "none",
              padding: 0,
              margin: 0,
            })}
          >
            {props.children}
          </ul>
        )}
      >
        <Button
          kind={KIND.secondary}
          size={SIZE.compact}
          overrides={buttonOverrides}
          // startEnhancer={
          //   !!props.icon ? (
          //     props.icon
          //   ) : undefined
          // }
        >
          {props.label} &nbsp;
          <ChevronDown fill={theme.colors.contentSecondary} />
        </Button>
      </StatefulPopover>
    </div>
  );
};

export default DropdownMenu;
