import React from "react";
import { useStyletron } from "baseui";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import OptionsMenu from "./OptionsMenu";
import ChartLegend from "./ChartLegend";
import { COLORS } from "../utils/mockData";
import { Avatar } from "baseui/avatar";
import { Tag, VARIANT, KIND, SIZE } from "baseui/tag";
import PieChart from "./charts/PieChart";

interface AvatarCellProps {
  id: number;
  title: string;
  src: string;
  name: string;
}

const categoriesData = [
  { name: "Jeans", value: 431 },
  { name: "Jackets", value: 354 },
  { name: "Accessories", value: 335 },
  { name: "Active Wear", value: 182 },
  {
    name: "Chinos",
    value: 283,
  },
  { name: "Sweaters", value: 199 },
];

export default function DashboardRecentOrders() {
  const [css, theme] = useStyletron();

  // TODO: Make component. Used by CustomerEdit.tsx as well
  const listCard = css({
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    borderRadius: theme.borders.radius300,
    flex: 1,
    background: theme.colors.backgroundPrimary,
    paddingTop: theme.sizing.scale600,
    paddingBottom: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale200,
    paddingRight: theme.sizing.scale200,
    textAlign: "left",
    boxShadow: theme.lighting.shadow400,
    [theme.mediaQuery.large]: {
      paddingTop: theme.sizing.scale800,
      paddingBottom: theme.sizing.scale800,
      paddingLeft: theme.sizing.scale500,
      paddingRight: theme.sizing.scale500,
    },
  });

  const listHeader = css({
    display: "flex",
    flex: "1",
    maxWidth: "100%",
    flexFlow: "row nowrap",
    alignItems: "center",
    paddingLeft: theme.sizing.scale100,
    paddingRight: theme.sizing.scale0,
    [theme.mediaQuery.large]: {
      paddingLeft: theme.sizing.scale500,
      paddingRight: theme.sizing.scale100,
    },
  });

  const listHeading = css({
    ...theme.typography.HeadingXSmall,
    flex: 1,
    color: theme.colors.contentPrimary,
    fontWeight: 600,
    lineHeight: 1,
    marginTop: 0,
    marginBottom: 0,
  });

  const bodyCellOverrides = {
    TableBodyCell: {
      style: {
        verticalAlign: "center",
        paddingTop: theme.sizing.scale300,
        paddingBottom: theme.sizing.scale300,
      },
    },
  };

  const DATA = [
    {
      id: 1,
      largeNumber: 123,
      avatarSrc:
        "https://avatars.dicebear.com/api/personas/1.svg?width=285&mood=happy",
      name: "Kevin Peters",
      date: "10 May 2021, 12:42pm",
    },
    {
      id: 2,
      largeNumber: 123,
      avatarSrc:
        "https://avatars.dicebear.com/api/personas/2.svg?width=285&mood=happy",
      name: "Frank Badger",
      date: "12 May 2021, 1:12am",
    },
    {
      id: 3,
      largeNumber: 123,
      avatarSrc:
        "https://avatars.dicebear.com/api/personas/3.svg?width=285&mood=happy",
      name: "Lisa McKewen",
      date: "12 May 2021, 8:15pm",
    },
    {
      id: 4,
      largeNumber: 123,
      avatarSrc:
        "https://avatars.dicebear.com/api/personas/4.svg?width=285&mood=happy",
      name: "George Mason",
      date: "15 May 2021, 2:34pm",
    },
    {
      id: 5,
      largeNumber: 123,
      avatarSrc:
        "https://avatars.dicebear.com/api/personas/5.svg?width=285&mood=happy",
      name: "Sarah Hopkins",
      date: "17 May 2021, 5:21pm",
    },
  ];

  function AvatarCell({ id, title, src, name }: AvatarCellProps) {
    return (
      <div className={css({ display: "flex", alignItems: "center" })}>
        <Avatar
          name={name}
          size={theme.sizing.scale900}
          src={src}
          overrides={{
            Root: {
              style: {
                backgroundColor: COLORS[id],
              },
            },
          }}
        />
        <div
          className={css({
            paddingLeft: theme.sizing.scale550,
            whiteSpace: "nowrap",
          })}
        >
          <p
            className={css({
              ...theme.typography.LabelSmall,
              margin: 0,
            })}
          >
            {title}
          </p>
        </div>
      </div>
    );
  }
  function NumberCell({ value }: { value: number }) {
    return (
      <span className={css({ ...theme.typography.LabelSmall })}>
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value)}
      </span>
    );
  }
  function DateCell({ date }: { date: string }) {
    return (
      <span
        className={css({
          ...theme.typography.ParagraphSmall,
          whiteSpace: "nowrap",
        })}
      >
        {date}
      </span>
    );
  }
  return (
    <div
      className={css({
        display: "flex",
        flexFlow: "row wrap",
      })}
    >
      <div
        className={css({
          flex: "1 1 100%",
          marginBottom: theme.sizing.scale600,
          maxWidth: "100%",
          [theme.mediaQuery.large]: {
            flex: `1 1 calc(65% - ${theme.sizing.scale600})`,
            marginRight: theme.sizing.scale600,
          },
        })}
      >
        <div className={listCard}>
          <div className={listHeader}>
            <h4 className={listHeading}>Recent Orders</h4>
            <OptionsMenu />
          </div>
          <div className={css({ marginTop: theme.sizing.scale600 })}>
            <TableBuilder data={DATA}>
              <TableBuilderColumn
                header="Customer"
                overrides={bodyCellOverrides}
              >
                {(row) => (
                  <AvatarCell
                    id={row.id}
                    title={row.name}
                    src={row.avatarSrc}
                    name={row.name}
                  />
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header="Date" overrides={bodyCellOverrides}>
                {(row) => <DateCell date={row.date} />}
              </TableBuilderColumn>
              <TableBuilderColumn header="Status" overrides={bodyCellOverrides}>
                {() => (
                  <Tag
                    color={theme.colors.positive}
                    size={SIZE.medium}
                    variant={VARIANT.solid}
                    kind={KIND.positive}
                    closeable={false}
                    overrides={{
                      Root: {
                        style: {
                          backgroundColor: theme.colors.positive50,
                          borderTopColor: theme.colors.positive50,
                          borderRightColor: theme.colors.positive50,
                          borderBottomColor: theme.colors.positive50,
                          borderLeftColor: theme.colors.positive50,
                          height: "auto",
                        },
                      },
                      Text: {
                        style: {
                          color: theme.colors.positive,
                        },
                      },
                    }}
                  >
                    Complete
                  </Tag>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                numeric
                overrides={{
                  TableHeadCell: {
                    style: {
                      textAlign: "right",
                    },
                  },
                  TableBodyCell: {
                    style: {
                      verticalAlign: "middle",
                      paddingTop: theme.sizing.scale300,
                      paddingBottom: theme.sizing.scale300,
                    },
                  },
                }}
                header="Amount"
              >
                {(row) => <NumberCell value={row.largeNumber} />}
              </TableBuilderColumn>
            </TableBuilder>
          </div>
        </div>
      </div>
      <div
        className={css({
          flex: "1 1 100%",
          width: "100%",
          marginBottom: theme.sizing.scale600,
          [theme.mediaQuery.medium]: {
            flex: "1 1 35%",
            width: "35%",
          },
        })}
      >
        <div className={listCard}>
          <div className={listHeader}>
            <h4 className={listHeading}>Sales by Category</h4>
            <OptionsMenu />
          </div>
          <div style={{ width: "100%", height: 230 }}>
            <PieChart />
          </div>
          <div
            className={css({
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-around",
              width: "100%",
            })}
          >
            {categoriesData.map((entry, index) => (
              <ChartLegend
                key={`legend-${entry.name}`}
                label={entry.name}
                value={entry.value}
                color={COLORS[index % COLORS.length]}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export const ListCol: React.FC<{ width?: string; numeric?: boolean }> = (
  props
) => {
  const [css, theme] = useStyletron();
  return (
    <p
      className={css({
        ...theme.typography.LabelSmall,
        flex: `1 1 ${props.width ? props.width : "auto"}`,
        paddingLeft: theme.sizing.scale100,
        paddingRight: theme.sizing.scale100,
        textAlign: !!props.numeric ? "right" : "left",
        color: theme.colors.contentSecondary,
        fontWeight: 400,
        lineHeight: 1,
        marginTop: 0,
        marginBottom: 0,
      })}
    >
      {props.children}
    </p>
  );
};
