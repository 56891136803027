import React from "react";
import { ReactComponent as Settings } from "@fluentui/svg-icons/icons/settings_20_regular.svg";
import { ReactComponent as SignOut } from "@fluentui/svg-icons/icons/sign_out_20_regular.svg";
import { ReactComponent as WeatherMoon } from "@fluentui/svg-icons/icons/weather_moon_20_regular.svg";
import { ReactComponent as WeatherSunny } from "@fluentui/svg-icons/icons/weather_sunny_20_regular.svg";
import { useStyletron } from "baseui";
import { Avatar } from "baseui/avatar";
import { Button, ButtonOverrides, KIND, SHAPE } from "baseui/button";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { NavLink } from "react-router-dom";
import { ThemeContext } from "../App";
import { ListItem } from "./ListItem";
import { Divider } from "../components/Divider";

export default function UserMenu() {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);
  const { uitheme, themeToggler } = React.useContext(ThemeContext); // Get a reference to App.tsx context

  const contain = css({
    marginLeft: "auto",
  });

  const list = css({
    display: "flex",
    flex: "1 1 auto",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    width: "200px",
    listStyle: "none",
    padding: 0,
    margin: 0,
    backgroundColor: theme.colors.backgroundPrimary,
  });

  const link = css({
    textDecoration: "none",
    display: "block",
  });

  const containDetails = css({
    display: "flex",
    flexFlow: "column nowrap",
    padding: `${theme.sizing.scale500} ${theme.sizing.scale500} ${theme.sizing.scale300}`,
  });

  const name = css({
    ...theme.typography.LabelMedium,
    fontWeight: 600,
    lineHeight: 1.4,
  });

  const role = css({
    ...theme.typography.ParagraphSmall,
    color: theme.colors.contentSecondary,
  });

  const UserDetails = () => (
    <div className={containDetails}>
      <span className={name}>Tom Watts</span>
      <span className={role}>Administrator</span>
    </div>
  );

  const buttonOverrides: ButtonOverrides = {
    BaseButton: {
      style: {
        transitionProperty: "all",
        transitionDuration: theme.animation.timing200,
        boxShadow: isOpen
          ? `${theme.colors.accent} 0px 0px 0px 2px`
          : undefined,
        ":hover": {
          boxShadow: `${theme.colors.accent} 0px 0px 0px 2px`,
        },
      },
    },
  };

  const currentTheme = uitheme === "light" ? "Light" : "Dark";

  const handleThemeToggle = (e: any) => {
    e.preventDefault();
    themeToggler(uitheme === "dark" ? "light" : "dark");
  };

  const sunIcon = <WeatherMoon fill={theme.colors.contentPrimary} />;
  const moonIcon = <WeatherSunny fill={theme.colors.contentPrimary} />;
  return (
    <div className={contain}>
      <StatefulPopover
        focusLock
        placement={PLACEMENT.bottomRight}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        content={({ close }) => (
          <ul className={list}>
            <UserDetails />
            <ListItem
              key="dark-mode"
              icon={uitheme === "dark" ? moonIcon : sunIcon}
              onClick={handleThemeToggle}
            >
              Appearance:
              <strong>&nbsp;{currentTheme}</strong>
            </ListItem>
            <NavLink
              to={"/user-settings/general"}
              onClick={() => close()}
              className={link}
            >
              <ListItem
                key="settings"
                icon={<Settings fill={theme.colors.contentPrimary} />}
              >
                User settings
              </ListItem>
            </NavLink>
            <Divider marginTop={theme.sizing.scale400} />
            <NavLink
              to={"/account/sign-in"}
              onClick={() => close()}
              className={link}
            >
              <ListItem
                key="sign-out"
                icon={<SignOut fill={theme.colors.contentPrimary} />}
              >
                Sign out
              </ListItem>
            </NavLink>
          </ul>
        )}
      >
        <Button
          shape={SHAPE.circle}
          kind={KIND.secondary}
          overrides={buttonOverrides}
        >
          <Avatar
            name={"Tom Watts"}
            src="https://avatars2.githubusercontent.com/u/6745684?s=40"
          />
        </Button>
      </StatefulPopover>
    </div>
  );
}
