import React from "react";
import { useState } from "react";
import { Checkbox } from "baseui/checkbox";
import {
  BuilderOverrides,
  TableBuilder,
  TableBuilderColumn,
} from "baseui/table-semantic";
import SelectionBanner from "./SelectionBanner";
import { Button, ButtonOverrides, KIND, SHAPE, SIZE } from "baseui/button";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { MainHeading } from "./MainHeading";
import FilterMenu from "./FilterMenu";
import { Order, ORDERS_DATA } from "../utils/mockData";
import { Pagination } from "baseui/pagination";
import TableShowing from "./TableShowing";
import StatusTag from "./StatusTag";
import StyledButton from "./StyledButton";
import { ReactComponent as Add } from "@fluentui/svg-icons/icons/add_20_filled.svg";
import { ReactComponent as Edit } from "@fluentui/svg-icons/icons/edit_20_regular.svg";
import { ReactComponent as Delete } from "@fluentui/svg-icons/icons/delete_20_regular.svg";

export default function OrdersTable() {
  const [css, theme] = useStyletron();
  const [data, setData] = useState(ORDERS_DATA);
  const [page, setPage] = useState(1);

  const hasAny = Boolean(data.length);
  const hasAll = hasAny && data.every((x) => x.selected);
  const hasSome = hasAny && data.some((x) => x.selected);
  const selectedCount = data.length && data.filter((x) => x.selected).length;

  //#region STYLES
  const buttonOverridess: ButtonOverrides = {
    BaseButton: {
      style: {
        paddingLeft: theme.sizing.scale400,
        paddingRight: theme.sizing.scale400,
      },
    },
  };

  const tableOverrides: BuilderOverrides = {
    Root: {
      style: {
        width: "100%",
        borderTopWidth: "0",
        borderRightWidth: "0",
        borderBottomWidth: "0",
        borderLeftWidth: "0",
      },
    },
    Table: {
      style: {
        borderLeftColor: "transparent",
      },
    },
    TableBodyRow: {
      style: {
        borderBottom: `1px solid ${theme.colors.borderTransparent}`,
      },
    },
  };

  const tableHeader = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "row wrap",
    marginBottom: theme.sizing.scale800,
  });

  const headerActions = css({
    display: "flex",
    flexFlow: "row wrap",
    flex: "1 0 auto",
    alignItems: "center",
    justifyContent: "flex-end",
  });

  const primaryActions = css({
    marginLeft: theme.sizing.scale600,
    display: "flex",
    alignItems: "center",
  });

  const tableColumnOverrides = (args?: {
    width: string;
    align: "left" | "right";
  }) => {
    const width = args && args.width;
    const alignment = args && args.align;
    return {
      TableHeadCell: {
        style: {
          ...theme.typography.LabelXSmall,
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale500,
          textAlign: alignment ?? "left",
          lineHeight: 1.6,
          width: width ?? "auto",
          color: theme.colors.contentTertiary,
          fontWeight: 600,
          "::before": {
            borderLeftWidth: "0",
          },
        },
      },
      TableBodyCell: {
        style: { width: width ?? "auto", verticalAlign: "center" },
      },
    };
  };
  // #endregion

  //#region FUNCTIONS
  const handlePageChange = (nextPage: number) => {
    if (nextPage < 1) {
      return;
    }
    if (nextPage > Math.ceil(data.length / 3)) {
      return;
    }
    setPage(nextPage);
  };

  function toggleAll() {
    setData((data) =>
      data.map((row: Order) => ({
        ...row,
        selected: !hasAll,
      }))
    );
  }

  function toggle(event: any) {
    const { name, checked } = event.currentTarget;
    setData((data) =>
      data.map((row: Order) => ({
        ...row,
        selected: String(row.order) === name ? checked : row.selected,
      }))
    );
  }
  //#endregion

  //#region JSX
  const selectionBanner = (
    <div className={headerActions}>
      <SelectionBanner count={selectedCount} noBackground>
        <>
          <div
            className={css({
              marginRight: theme.sizing.scale200,
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.compact}
              shape={SHAPE.pill}
              onClick={() => {}}
              overrides={buttonOverridess}
            >
              <Edit
                fill={theme.colors.contentPrimary}
                className={css({
                  [theme.mediaQuery.large]: {
                    marginRight: theme.sizing.scale200,
                  },
                })}
              />
              <Block
                as="span"
                display={["none", "none", "none", "block"]}
                color={theme.colors.contentSecondary}
              >
                Edit
              </Block>
            </Button>
          </div>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.pill}
            onClick={() => {}}
            overrides={buttonOverridess}
          >
            <Delete
              fill={theme.colors.negative}
              className={css({
                [theme.mediaQuery.large]: {
                  marginRight: theme.sizing.scale200,
                },
              })}
            />
            <Block
              as="span"
              display={["none", "none", "none", "block"]}
              color={theme.colors.contentSecondary}
            >
              Delete
            </Block>
          </Button>
        </>
      </SelectionBanner>
      <div className={primaryActions}>
        <FilterMenu />
        <StyledButton
          kind="primary"
          size="compact"
          marginLeft={theme.sizing.scale500}
        >
          <Add
            fill={theme.colors.contentInversePrimary}
            className={css({
              [theme.mediaQuery.small]: {
                marginRight: theme.sizing.scale400,
              },
            })}
          />
          <Block as="span" display={["none", "block", "block", "block"]}>
            Add customer
          </Block>
        </StyledButton>
      </div>
    </div>
  );
  //#endregion

  return (
    <>
      <div className={tableHeader}>
        <Breadcrumbs>
          <MainHeading>Orders</MainHeading>
        </Breadcrumbs>
        {selectionBanner}
      </div>

      <TableBuilder data={data} overrides={tableOverrides}>
        <TableBuilderColumn
          overrides={tableColumnOverrides({ width: "0.1%", align: "left" })}
          header={
            <Checkbox
              checked={hasAll}
              isIndeterminate={!hasAll && hasSome}
              onChange={toggleAll}
            />
          }
        >
          {(row: Order) => (
            <Checkbox
              name={row.order}
              checked={row.selected}
              onChange={toggle}
            />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn header="ORDER" overrides={tableColumnOverrides()}>
          {(row: Order) => row.order}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="TOTAL"
          numeric
          overrides={tableColumnOverrides({ width: "14%", align: "right" })}
        >
          {(row: Order) => (
            <span
              className={css({ color: theme.colors.positive, fontWeight: 500 })}
            >
              {row.total}
            </span>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="DATE"
          overrides={tableColumnOverrides({ width: "20%", align: "left" })}
        >
          {(row: Order) => row.date}
        </TableBuilderColumn>

        <TableBuilderColumn
          header="CUSTOMER"
          overrides={tableColumnOverrides()}
        >
          {(row: Order) => row.customer}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="STOCK"
          numeric
          overrides={tableColumnOverrides({ width: "8%", align: "right" })}
        >
          {(row: Order) => (
            <span
              className={css({
                color:
                  row.stock === 0
                    ? theme.colors.negative
                    : theme.colors.contentPrimary,
              })}
            >
              {row.stock}
            </span>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="STATUS"
          overrides={tableColumnOverrides({ width: "14%", align: "left" })}
        >
          {(row: Order) => <StatusTag status={row.status} />}
        </TableBuilderColumn>
      </TableBuilder>

      <div
        className={css({
          display: "flex",
          flexFlow: "column wrap",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale600,
          paddingLeft: theme.sizing.scale200,
          [theme.mediaQuery.large]: {
            flexFlow: "row wrap",
            paddingTop: theme.sizing.scale100,
          },
        })}
      >
        <TableShowing startValue={1} endValue={10} total={data.length} />
        <Pagination
          currentPage={page}
          numPages={Math.ceil(data.length / 3)}
          onPageChange={({ nextPage }) => handlePageChange(nextPage)}
        />
      </div>
    </>
  );
}
