import { Client as Styletron } from "styletron-engine-atomic";
import { createTheme } from "baseui";

// Base Web Theme Overrides
export const baseOverrides = {
  AppContainer: {
    style: () => ({
      height: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      [theme.mediaQuery.medium]: {
        flexFlow: "row nowrap",
      },
    }),
  },
};

// Base Web Theme
const primitives = {
  accent: "#37dbde",
  accent50: "#effefd",
  accent100: "#dff6f6", // was: #a6d3ed
  accent200: "#57aada", // change to match accent
  accent300: "#2db3b5", // was: #1888c9
  accent400: "#0079c1", // change to match accent
  accent500: "#006eae", // change to match accent
  accent600: "#005d93", // change to match accent
  accent700: "#00456d", // change to match accent
  positive100: "#ccf5e0",
  positive200: "#01a550",
  primaryFontFamily: "Inter, sans-serif",
};

const overrides = {
  breakpoints: {
    small: 480,
    medium: 769,
    large: 1200,
  },
  mediaQuery: {
    small: "@media screen and (min-width: 480px)",
    medium: "@media screen and (min-width: 769px)",
    large: "@media screen and (min-width: 1280px)",
  },
  // grid: {
  //   columns: [4, 8, 12],
  //   gutters: [16, 36, 36],
  //   margins: [16, 36, 64],
  //   gaps: 0,
  //   maxWidth: 1280,
  // },

  colors: {
    tableHeadBackgroundColor: "#ffffff",
    backgroundLightAccent: "#e0ffff", // "#f2fcfc",
    backgroundSecondary: "#e8e9eb",
    backgroundTertiary: "#fafbfb",
    buttonSecondaryFill: "#eeeef1",
    inputFill: "#eeeef1",
    contentPrimary: "#2e3535",
    contentSecondary: "#505f5f",
    contentTertiary: "#748787",
    contentAccent: "#346162", // "#2ebabd",
    backgroundAccent: "#2ebabd", // "#2ebabd",
    // contentAccent: "#293D53",
    buttonTertiaryHover: "#f4f4f7", // alt: #f2f2f5
    buttonSecondaryText: "#2e3535",
    buttonSecondaryHover: "#e2e2e5",
    buttonPrimaryFill: primitives.accent,
    buttonPrimaryHover: "#2ebabd",
    tickFillSelected: primitives.accent,
    tagPositiveLightBackground: "#e1f3eb",
    tagAccentLightFont: "#1da7a7",
    positive: "#08c07c",
    negative: "#ff5e5e",
    borderSelected: primitives.accent,
    toastPositiveBackground: "#08c07c",
  },
  lighting: {
    shadow400: "rgb(0 0 0 / 7%) 0px 1px 4px",
  },
  typography: {
    HeadingXLarge: {
      fontWeight: "700",
    },
    // DisplayMedium: {
    //   fontFamily: 'Georgia',
    // },
    // ParagraphXSmall: {
    //   fontFamily: 'Georgia',
    // },
    // DisplayXSmall: {
    //   fontFamily: 'Georgia',
    // },
  },
};
export const engine = new Styletron();
export const theme = createTheme(primitives, overrides);
