import React from "react";
import { useStyletron } from "baseui";
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { ReactComponent as DismissFilled } from "@fluentui/svg-icons/icons/dismiss_16_filled.svg";

interface Props {
  src: string;
  alt: string;
  width?: string;
  marginRight?: string;
}

const ProductImage: React.FC<Props> = (props) => {
  const [css, theme] = useStyletron();
  return (
    <div
      key={props.alt}
      className={css({
        position: "relative",
        marginRight: props.marginRight ?? undefined,
      })}
    >
      <span
        className={css({
          color: "black",
          display: "block",
          position: "absolute",
          top: "-10px",
          right: "-10px",
          margin: "auto",
        })}
      >
        <Button size={SIZE.compact} shape={SHAPE.circle} kind={KIND.secondary}>
          <DismissFilled fill={theme.colors.contentSecondary} />
        </Button>
      </span>
      <img
        src={props.src}
        alt={props.alt}
        className={css({
          width: props.width ?? "100px",
          height: "auto",
        })}
      />
      {props.children}
    </div>
  );
};

export default ProductImage;
