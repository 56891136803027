import React from "react";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { useStyletron } from "baseui";
import { MainHeading } from "../components/MainHeading";
import { Header } from "../components/Header";
import AreaChart from "../components/AreaChart";
import StatBarChart from "../components/BarChart";
import { ListCard } from "../components/ListCard";
import StackedAreaChart from "../components/charts/StackedAreaChart";
import AreaChartByValue from "../components/charts/AreaChartByValue";
import PieChart from "../components/charts/PieChart";

export default function Charts() {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        paddingLeft: theme.sizing.scale600,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale600,
        [theme.mediaQuery.large]: {
          paddingBottom: theme.sizing.scale800,
          paddingLeft: theme.sizing.scale800,
          paddingRight: theme.sizing.scale800,
        },
      })}
    >
      <div
        className={css({
          width: "100%",
          maxWidth: "1280px",
          margin: "0 auto",
        })}
      >
        <Header />
        <Breadcrumbs>
          <MainHeading hasMargin>Charts</MainHeading>
        </Breadcrumbs>

        <>
          <div
            className={css({
              display: "flex",
              flexFlow: "row wrap",
            })}
          >
            <div
              className={css({
                flex: "1 1 100%",
                maxWidth: "100%",
                marginBottom: theme.sizing.scale600,
                [theme.mediaQuery.large]: {
                  flex: "1 1 50%",
                  maxWidth: "calc(50% - 16px)",
                  marginRight: theme.sizing.scale600,
                },
              })}
            >
              <ListCard
                hideOptions
                header="Bar chart"
                subheader="This is a bar chart"
              >
                <StatBarChart />
              </ListCard>
            </div>

            <div
              className={css({
                flex: "1 1 100%",
                maxWidth: "100%",
                marginBottom: theme.sizing.scale600,
                [theme.mediaQuery.large]: {
                  maxWidth: "50%",
                },
              })}
            >
              <ListCard
                hideOptions
                header="Area chart"
                subheader="This is an area chart"
              >
                <AreaChart />
              </ListCard>
            </div>

            <div
              className={css({
                flex: "1 1 100%",
                maxWidth: "100%",
                marginBottom: theme.sizing.scale600,
                [theme.mediaQuery.large]: {
                  flex: "1 1 50%",
                  maxWidth: "calc(50% - 16px)",
                  marginRight: theme.sizing.scale600,
                },
              })}
            >
              <ListCard
                hideOptions
                header="Stacked area chart"
                subheader="This is an area chart"
              >
                <StackedAreaChart />
              </ListCard>
            </div>

            <div
              className={css({
                flex: "1 1 100%",
                maxWidth: "100%",
                marginBottom: theme.sizing.scale600,
                [theme.mediaQuery.large]: {
                  maxWidth: "50%",
                },
              })}
            >
              <ListCard
                hideOptions
                header="Area chart filled by value"
                subheader="This is an area chart"
              >
                <AreaChartByValue />
              </ListCard>
            </div>

            <div
              className={css({
                flex: "1 1 100%",
                maxWidth: "100%",
                marginBottom: theme.sizing.scale600,
                [theme.mediaQuery.large]: {
                  flex: "1 1 33.33%",
                  maxWidth: "calc(33.33% - 16px)",
                  marginRight: theme.sizing.scale600,
                },
              })}
            >
              <ListCard
                hideOptions
                header="Stacked area chart"
                subheader="This is an area chart"
              >
                <div style={{ width: "100%", height: 230 }}>
                  <PieChart />
                </div>
              </ListCard>
            </div>
            <div
              className={css({
                flex: "1 1 100%",
                maxWidth: "100%",
                marginBottom: theme.sizing.scale600,
                [theme.mediaQuery.large]: {
                  flex: "1 1 33.33%",
                  maxWidth: "calc(33.33% - 16px)",
                  marginRight: theme.sizing.scale600,
                },
              })}
            >
              <ListCard
                hideOptions
                header="Stacked area chart"
                subheader="This is an area chart"
              >
                <div style={{ width: "100%", height: 230 }}>
                  <PieChart />
                </div>
              </ListCard>
            </div>
            <div
              className={css({
                flex: "1 1 100%",
                maxWidth: "100%",
                marginBottom: theme.sizing.scale600,
                [theme.mediaQuery.large]: {
                  maxWidth: "33.33%",
                },
              })}
            >
              <ListCard
                hideOptions
                header="Stacked area chart"
                subheader="This is an area chart"
              >
                <div style={{ width: "100%", height: 230 }}>
                  <PieChart />
                </div>
              </ListCard>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
