import React from "react";
import { useStyletron } from "baseui";

const BoldText: React.FC = ({ children }) => {
  const [css] = useStyletron();
  return (
    <span
      className={css({
        fontWeight: 600,
        color: "inherit",
      })}
    >
      {children}
    </span>
  );
};

export default BoldText;
