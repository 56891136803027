import React from "react";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { Navigation } from "baseui/side-navigation";
import { MainHeading } from "../components/MainHeading";
import { Row } from "../components/Row";
import { useStyletron } from "baseui";
import { Route, Switch, RouteComponentProps, Redirect } from "react-router-dom";
import StyledButton from "../components/StyledButton";
import { ProfileTable } from "../components/ProfileTable";
import { Card } from "../components/Card";
import { SectionHeader } from "../components/SectionHeader";
import { PaymentForm } from "../components/PaymentForm";
import { SettingsNavItem, SETTINGS_NAV_ITEMS } from "../utils/mockData";
import { delay } from "../utils/methods";
import { ParagraphSmall } from "baseui/typography";
import { NotificationToggles } from "../components/NotificationToggles";
import { ReactComponent as Shield } from "@fluentui/svg-icons/icons/shield_20_regular.svg";
import { Cell, Grid } from "baseui/layout-grid";
import { Header } from "../components/Header";
import { SnackbarProvider, useSnackbar } from "baseui/snackbar";
import { ReactComponent as CheckmarkCircleFilled } from "@fluentui/svg-icons/icons/checkmark_circle_24_filled.svg";

interface NavProps {
  $active: boolean;
}

export const UserSettings: React.FC<RouteComponentProps<any>> = (props) => {
  const currentRoute = props.location.pathname;
  const subRoute = currentRoute.substring(currentRoute.lastIndexOf("/") + 1);
  const [location, setLocation] = React.useState(subRoute);
  const [css, theme] = useStyletron();

  const contain = css({
    display: "flex",
    flexFlow: "column wrap",
    [theme.mediaQuery.large]: {
      flexFlow: "row nowrap",
    },
  });

  const sidebar = css({
    flex: "0 0 auto",
    marginBottom: theme.sizing.scale800,
    [theme.mediaQuery.large]: {
      flex: "0 0 300px",
      marginRight: theme.sizing.scale800,
    },
  });

  const content = css({
    flex: "1",
    minWidth: "0",
  });

  const navOverrides = {
    Root: {
      style: {
        backgroundColor: theme.colors.backgroundPrimary,
      },
    },
    NavItem: {
      style: ({ $active }: NavProps) => {
        if (!$active)
          return {
            borderBottom: `1px solid ${theme.colors.borderTransparent}`,
            ":hover": {
              color: theme.colors.accent300,
            },
          };
        return {
          borderBottom: `1px solid ${theme.colors.borderTransparent}`,
          backgroundImage: "none",
          backgroundColor: theme.colors.backgroundPrimary,
          borderLeftColor: theme.colors.accent,
          color: theme.colors.backgroundInversePrimary,
          fontWeight: 500,
          ":hover": {
            color: theme.colors.accent300,
          },
        };
      },
    },
  };

  const handleChange = ({
    event,
    item,
  }: {
    item: SettingsNavItem;
    event: any;
  }): void => {
    event.preventDefault();
    setLocation(item.itemId);
    props.history.push(`/user-settings/${item.itemId}`);
  };

  return (
    <SnackbarProvider>
      <Row paddingBottom>
        <Grid>
          <Cell span={12}>
            <Header />
            <Breadcrumbs>
              <MainHeading hasMargin>User settings</MainHeading>
            </Breadcrumbs>
            <br />
            <div className={contain}>
              <div className={sidebar}>
                <Navigation
                  items={SETTINGS_NAV_ITEMS}
                  activeItemId={location}
                  onChange={handleChange}
                  overrides={navOverrides}
                />
              </div>
              <div className={content}>
                <Switch>
                  {/* Redirect top level route to the first settings page */}
                  <Redirect
                    from="/user-settings"
                    exact
                    to={"/user-settings/general"}
                  />
                  <Route
                    path={"/user-settings/general"}
                    exact
                    component={() => <SettingsGeneral />}
                  />
                  <Route
                    path={"/user-settings/account"}
                    exact
                    component={() => <SettingsAccount />}
                  />
                  <Route
                    path={"/user-settings/security"}
                    exact
                    component={() => <SettingsSecurity />}
                  />
                  <Route
                    path={"/user-settings/notifications"}
                    exact
                    component={() => <SettingsNotifications />}
                  />
                </Switch>
              </div>
            </div>
          </Cell>
        </Grid>
      </Row>
    </SnackbarProvider>
  );
};

const SettingsGeneral = () => {
  const [css, theme] = useStyletron();
  const [profileEdit, setProfileEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { enqueue } = useSnackbar();

  const toggleProfileEdit = (): void => {
    setProfileEdit(!profileEdit);
  };
  const submit = async () => {
    setLoading(true);
    await delay(2000);
    toggleProfileEdit();
    enqueue({
      message: "Your profile changes have been saved.",
      startEnhancer: () => (
        <CheckmarkCircleFilled fill={theme.colors.backgroundAccent} />
      ),
    });
    setLoading(false);
  };

  return (
    <>
      <SectionHeader heading="General" subheading="Your account information." />
      <Card title="Profile">
        <>
          <ProfileTable
            toggleProfileEdit={toggleProfileEdit}
            profileEdit={profileEdit}
            loading={loading}
          />
          {profileEdit ? (
            <div className={css({ width: "100%", display: "flex" })}>
              <StyledButton
                kind="secondary"
                size="compact"
                onClick={toggleProfileEdit}
                disabled={loading}
              >
                Cancel
              </StyledButton>
              <StyledButton
                kind="primary"
                size="compact"
                onClick={submit}
                isLoading={loading}
                overrides={{
                  BaseButton: {
                    style: {
                      marginLeft: "auto",
                    },
                  },
                }}
              >
                Save profile
              </StyledButton>
            </div>
          ) : (
            <StyledButton
              onClick={toggleProfileEdit}
              kind="secondary"
              size="compact"
            >
              Edit profile
            </StyledButton>
          )}
        </>
      </Card>
    </>
  );
};

const SettingsAccount = () => {
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const [paymentEdit, setPaymentEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const togglePaymentEdit = (): void => {
    setPaymentEdit(!paymentEdit);
  };
  const submit = async () => {
    setLoading(true);
    await delay(2000);
    togglePaymentEdit();
    enqueue({
      message: "Your payment details have been saved.",
      startEnhancer: () => (
        <CheckmarkCircleFilled fill={theme.colors.backgroundAccent} />
      ),
    });
    setLoading(false);
  };

  return (
    <>
      <SectionHeader
        heading="Account"
        subheading="Account settings and payment details."
      />
      <Card title="Payment details">
        <>
          <PaymentForm disabled={!loading && paymentEdit} />
          {paymentEdit ? (
            <div className={css({ width: "100%", display: "flex" })}>
              <StyledButton
                kind="secondary"
                size="compact"
                onClick={togglePaymentEdit}
                disabled={loading}
              >
                Cancel
              </StyledButton>
              <StyledButton
                kind="primary"
                size="compact"
                onClick={submit}
                isLoading={loading}
                overrides={{
                  BaseButton: {
                    style: {
                      marginLeft: "auto",
                    },
                  },
                }}
              >
                Save payment details
              </StyledButton>
            </div>
          ) : (
            <StyledButton
              kind="secondary"
              size="compact"
              onClick={togglePaymentEdit}
            >
              Edit payment details
            </StyledButton>
          )}
        </>
      </Card>
      <SectionHeader
        heading="Manage data"
        subheading="Manage and download your account data"
      />
      <Card title="Export account data">
        <>
          <StyledButton kind="secondary" size="compact">
            Start export
          </StyledButton>
        </>
      </Card>
      <SectionHeader
        heading="Deactivation and deletion"
        subheading="Irreversible and destructive actions, tread carefully."
      />
      <Card title="Delete account">
        <>
          <ParagraphSmall>
            Temporarily deactivate or permanently delete your account.
          </ParagraphSmall>
          <StyledButton
            kind="primary"
            size="compact"
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: theme.colors.negative,
                  color: theme.colors.contentInversePrimary,
                },
              },
            }}
          >
            Delete or deactivate account
          </StyledButton>
        </>
      </Card>
    </>
  );
};

const SettingsSecurity = () => {
  return (
    <>
      <SectionHeader
        heading="Security"
        subheading="Security and account protection."
      />
      <Card title="Two-factor authentication">
        <>
          <ParagraphSmall>
            Two-factor authentication adds an additional layer of security to
            your account by requiring more than just a password to log in. Use
            an application such as Authy, 1Password, LastPass, or Google
            Authenticator to get an authorization code when prompted.
          </ParagraphSmall>
          <StyledButton kind="primary" size="compact">
            <Shield fill="currentColor" />
            &nbsp; Enable two-factor authentication
          </StyledButton>
        </>
      </Card>
    </>
  );
};

const SettingsNotifications = () => {
  const [, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const submit = async () => {
    setLoading(true);
    await delay(1000);
    enqueue({
      message: "Email notification preferences saved.",
      startEnhancer: () => (
        <CheckmarkCircleFilled fill={theme.colors.backgroundAccent} />
      ),
      actionMessage: "Undo",
    });
    setLoading(false);
  };
  return (
    <>
      <SectionHeader
        heading="Notifications"
        subheading="Choose which notifications you recieve."
      />
      <Card title="Email notifications">
        <>
          <NotificationToggles />
          <br />
          <StyledButton
            kind="primary"
            size="compact"
            isLoading={loading}
            onClick={submit}
          >
            Save changes
          </StyledButton>
        </>
      </Card>
    </>
  );
};
