import React from "react";
import { useStyletron } from "baseui";
import { Tag, KIND, VARIANT, SIZE as TAG_SIZE } from "baseui/tag";
import { ReactComponent as DraftsFilled } from "@fluentui/svg-icons/icons/drafts_20_filled.svg";
import { ReactComponent as CheckmarkCircleFilled } from "@fluentui/svg-icons/icons/checkmark_circle_20_filled.svg";
import { ReactComponent as ClockFilled } from "@fluentui/svg-icons/icons/clock_20_filled.svg";

export enum ProductStatus {
  Draft = "Draft",
  Published = "Published",
  OnHold = "On hold",
}

interface Props {
  status: ProductStatus;
  showIcon?: boolean;
}

const StatusTag = (props: Props) => {
  const [css, theme] = useStyletron();

  const getColor = () => {
    switch (props.status) {
      case ProductStatus.Draft:
        return theme.colors.contentSecondary;
      case ProductStatus.Published:
        return theme.colors.positive300;
      default:
        return theme.colors.accent300;
    }
  };

  const getBgColor = () => {
    switch (props.status) {
      case ProductStatus.Draft:
        return theme.colors.borderOpaque;
      case ProductStatus.Published:
        return theme.colors.positive100;
      default:
        return theme.colors.accent100;
    }
  };

  const getIcon = () => {
    switch (props.status) {
      case ProductStatus.Draft:
        return (
          <DraftsFilled
            fill={theme.colors.contentSecondary}
            className={css({
              marginRight: theme.sizing.scale200,
            })}
          />
        );
      case ProductStatus.Published:
        return (
          <CheckmarkCircleFilled
            fill={theme.colors.positive300}
            className={css({
              marginRight: theme.sizing.scale200,
            })}
          />
        );
      default:
        return (
          <ClockFilled
            fill={theme.colors.accent300}
            className={css({
              marginRight: theme.sizing.scale200,
            })}
          />
        );
    }
  };

  return (
    <Tag
      color={getColor()}
      size={TAG_SIZE.medium}
      variant={VARIANT.outlined}
      kind={KIND.custom}
      onClick={() => {}}
      closeable={false}
      overrides={{
        Root: {
          style: {
            backgroundColor: getBgColor(),
            borderTopColor: getBgColor(),
            borderRightColor: getBgColor(),
            borderBottomColor: getBgColor(),
            borderLeftColor: getBgColor(),
            height: "auto",
            paddingLeft: theme.sizing.scale300,
            paddingRight: theme.sizing.scale300,
          },
        },
        Text: {
          style: {
            color: getColor(),
          },
        },
      }}
    >
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          flexFLow: "row nowrap",
        })}
      >
        {props.showIcon && getIcon()}
        <span
          className={css({
            ...theme.typography.LabelXSmall,
            textTransform: "uppercase",
            lineHeight: 1,
            fontWeight: 600,
          })}
        >
          {props.status}
        </span>
      </div>
    </Tag>
  );
};

export default StatusTag;
