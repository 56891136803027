import React from "react";
import { AccountAccess } from "../components/AccountAccess";
import { Block } from "baseui/block";
import { ReactComponent as Logo } from "../assets/brandlogo.svg";
import { Link } from "react-router-dom";
import { useStyletron } from "baseui";
import { SignUp } from "../forms/SignUp";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { SignIn } from "../forms/SignIn";
import { ForgotPassword } from "../forms/ForgotPassword";

export default function Account() {
  const [css, theme] = useStyletron();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const heading = css({
    ...theme.typography.HeadingXSmall,
    display: "flex",
    alignItems: "center",
    marginLeft: theme.sizing.scale400,
    color: theme.colors.contentPrimary,
    letterSpacing: "-0.6px",
    fontWeight: 700,
  });

  // TODO: Make a global export?
  const styledLink = css({
    ...theme.typography.LabelMedium,
    color: theme.colors.contentPrimary,
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
      textUnderlinePosition: "under",
    },
  });

  const handleSignUp = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      history.push("/account/sign-in");
    }, 2000);
  };

  const handleSignIn = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      history.push("/");
    }, 2000);
  };

  return (
    <AccountAccess
      headerComponent={
        <>
          <Block
            display={["flex", "flex", "none"]}
            marginBottom={theme.sizing.scale800}
          >
            <Logo width="25px" color="accent" />
            <div className={heading}>AmpltyVolt</div>
          </Block>

          <Switch>
            <Redirect from="/account" exact to={"/account/sign-up"} />
            <Route
              path={"/account/sign-up"}
              exact
              component={() => (
                <Block
                  marginBottom={theme.sizing.scale800}
                  color={theme.colors.contentSecondary}
                >
                  Already have an account?{" "}
                  <Link to="/account/sign-in" className={styledLink}>
                    Sign in
                  </Link>
                </Block>
              )}
            />
            <Route
              path={"/account/sign-in"}
              exact
              component={() => (
                <Block
                  marginBottom={theme.sizing.scale800}
                  color={theme.colors.contentSecondary}
                >
                  Don't have an account?{" "}
                  <Link to="/account/sign-up" className={styledLink}>
                    Sign up
                  </Link>
                </Block>
              )}
            />
            <Route
              path={"/account/forgot-password"}
              exact
              component={() => (
                <Block
                  marginBottom={theme.sizing.scale800}
                  color={theme.colors.contentSecondary}
                >
                  <Link to="/account/sign-in" className={styledLink}>
                    Back to sign in
                  </Link>
                </Block>
              )}
            />
          </Switch>
        </>
      }
      formComponent={
        <Switch>
          <Redirect from="/account" exact to={"/account/sign-up"} />
          <Route
            path={"/account/sign-up"}
            exact
            component={() => (
              <SignUp onSubmit={() => handleSignUp()} isLoading={loading} />
            )}
          />
          <Route
            path={"/account/sign-in"}
            exact
            component={() => (
              <SignIn onSubmit={() => handleSignIn()} isLoading={loading} />
            )}
          />
          <Route
            path={"/account/forgot-password"}
            exact
            component={() => (
              <ForgotPassword
                onSubmit={() => handleSignUp()}
                isLoading={loading}
              />
            )}
          />
        </Switch>
      }
    />
  );
}
