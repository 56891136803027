import AppRoutes from "./AppRoutes";
import React from "react";
import { baseOverrides, engine, theme } from "./utils/theme";
import { darkTheme } from "./utils/themeDark";
// import { BaseProvider, DarkTheme } from "baseui";
import { BaseProvider } from "baseui";
import { DebugEngine, Provider as StyletronProvider } from "styletron-react";
// import { useDarkMode } from "./utils/useDarkMode";

const THEME = {
  light: theme,
  dark: darkTheme, // TODO: configure dark theme
};

const defaultState = {
  uitheme: "light",
  themeToggler: (theme: "light" | "dark") => {},
};

export const ThemeContext = React.createContext(defaultState);

function App() {
  const debug =
    process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

  const [uitheme, setTheme] = React.useState<string>("light");
  const [mountedComponent, setMountedComponent] =
    React.useState<boolean>(false);
  const setMode = (mode: "light" | "dark") => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };
  const themeToggler = () => {
    uitheme === "light" ? setMode("dark") : setMode("light");
  };
  React.useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    localTheme ? setTheme(localTheme) : setMode("light");
    setMountedComponent(true);
  }, []);

  if (!mountedComponent) return <div />;

  return (
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <BaseProvider
        theme={uitheme === "light" ? THEME.light : THEME.dark}
        overrides={baseOverrides}
      >
        <ThemeContext.Provider value={{ uitheme, themeToggler }}>
          <AppRoutes />
        </ThemeContext.Provider>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;
