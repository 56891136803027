import React from "react";
import StyledButton from "../components/StyledButton";
import { Block } from "baseui/block";
import { FormControl } from "baseui/form-control";
import { InputEmail } from "../components/InputEmail";
import { StyledAction, StyledBody } from "baseui/card";
import { StyledInput } from "../components/StyledInput";
import { useStyletron } from "baseui";
import { Link } from "react-router-dom";

interface Props {
  onSubmit(): void;
  isLoading: boolean;
}

export function SignIn(props: Props) {
  const [css, theme] = useStyletron();

  const formContain = css({
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
  });

  const styledLink = css({
    ...theme.typography.LabelMedium,
    color: theme.colors.contentSecondary,
    marginTop: theme.sizing.scale800,
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
      textUnderlinePosition: "under",
    },
  });

  const formHeading = css({
    ...theme.typography.HeadingXLarge,
    color: theme.colors.contentPrimary,
    letterSpacing: "-1px",
    fontWeight: 600,
    marginBottom: theme.sizing.scale400,
    [theme.mediaQuery.medium]: {
      marginBottom: theme.sizing.scale800,
    },
  });

  function getFormOverrides(width: string) {
    return {
      ControlContainer: {
        style: {
          width,
          marginRight: "5px",
        },
      },
    };
  }

  return (
    <Block
      display="flex"
      flex="1"
      width="100%"
      maxWidth="440px"
      alignItems="center"
    >
      <div className={formContain}>
        <div className={formHeading}>Sign in</div>
        <br />
        <StyledBody>
          <InputEmail />
          <FormControl label="Password" overrides={getFormOverrides("100%")}>
            <StyledInput type="password" />
          </FormControl>
        </StyledBody>
        <StyledAction>
          <StyledButton
            onClick={props.onSubmit}
            isLoading={props.isLoading}
            kind="primary"
            fullWidth
          >
            Sign in
          </StyledButton>
        </StyledAction>
        <Link to="/account/forgot-password" className={styledLink}>
          Forgot your password?
        </Link>
      </div>
    </Block>
  );
}
