import { Value } from "baseui/select";
import activeSweater from "../assets/active-sweater.png";
import bomberJacket from "../assets/bomber-jacket.png";
import carsonShirt from "../assets/carson-shirt.png";
import dressChinos from "../assets/dress-chinos.png";
import lunaPants from "../assets/luna-pants.png";
import midnightHoodie from "../assets/midnight-hoodie.png";
import slimJeans from "../assets/slim-jeans.png";
import stretchSweatPants from "../assets/stretch-sweat-pants.png";
import xLightJeans from "../assets/x-light-jeans.png";
import zipperJacket from "../assets/zipper-jacket.png";
import { ProductStatus } from "../components/StatusTag";

//#region HELPERS
function getFileName(name: string) {
  return name.replace(/^.*?([^\\/]*)$/, "$1");
}
//#endregion

export const OPTIONS_MENU = [
  { label: "Open" },
  { label: "Import" },
  { label: "Copy" },
  { label: "Export" },
];

export const FILTER_MENU = [
  { label: "Newest first" },
  { label: "Oldest first" },
  { label: "Highest price" },
  { label: "Lowest price" },
];

export const COLORS = [
  "#0088FE",
  "#00a082",
  "#FFBB28",
  "#FF8042",
  "#D66BEA",
  "#6CDDDD",
  "#4F5073",
  "#EA7F2F",
];

//#region DATA TYPES
export type Product = {
  code: string;
  name: string;
  selected: boolean;
  stock: number;
  tags: string[];
  price: string;
  status: ProductStatus;
  src: string;
};

export type Order = {
  date: string;
  order: string;
  selected: boolean;
  stock: number;
  customer: string;
  total: string;
  status: ProductStatus;
  location: string;
};

export type Customer = {
  id: string;
  name: string;
  email: string;
  address: string;
  orders: number;
  lastOrder: string;
  selected: boolean;
};

export type Asset = {
  createdOn: string;
  updatedOn: string;
  order: string;
  selected: boolean;
  fileName: string;
  updatedBy: string;
  handle: string;
  height: number;
  width: number;
  size: string;
  src: string;
};

export type SettingsNavItem = {
  title: string;
  itemId: string;
};

export type Project = {
  name: string;
  description: string;
  people: string[];
  icon: Value;
};

//#endregion

//#region MOCK DATA
export const PRODUCT_DATA: Product[] = [
  {
    code: "SK35-49",
    name: "Example Product",
    selected: false,
    stock: 33,
    tags: ["Demin", "Jeans"],
    price: "$46.40",
    status: ProductStatus.OnHold,
    src: slimJeans,
  },
  {
    code: "SK32-13",
    name: "Slim Jeans",
    selected: false,
    stock: 33,
    tags: ["Demin", "Jeans"],
    price: "$46.40",
    status: ProductStatus.OnHold,
    src: slimJeans,
  },
  {
    code: "SK91-22",
    name: "Active Sweater",
    selected: false,
    stock: 0,
    tags: ["Cotton", "Sweater", "Active wear"],
    price: "$46.40",
    status: ProductStatus.Published,
    src: activeSweater,
  },
  {
    code: "SK22-92",
    name: "Dress Chinos",
    selected: false,
    stock: 1440,
    tags: ["Cotton", "Chinos"],
    price: "$46.40",
    status: ProductStatus.Draft,
    src: dressChinos,
  },
  {
    code: "SK421-3",
    name: "Midnight Hoodie",
    selected: false,
    stock: 256,
    tags: ["Cotton", "Hoodie"],
    price: "$46.40",
    status: ProductStatus.Draft,
    src: midnightHoodie,
  },
  {
    code: "SK45-42",
    name: "Zipper Jacket",
    selected: false,
    stock: 65,
    tags: ["Fleece", "Jacket", "Zipper"],
    price: "$46.40",
    status: ProductStatus.OnHold,
    src: zipperJacket,
  },
  {
    code: "SK41-33",
    name: "Stretch Sweat Pants",
    selected: false,
    stock: 0,
    tags: ["Cotton", "Pants", "Active wear"],
    price: "$46.40",
    status: ProductStatus.Published,
    src: stretchSweatPants,
  },
  {
    code: "SK97-144",
    name: "Bomber Jacket",
    selected: false,
    stock: 52,
    tags: ["Fleece", "Jacket"],
    price: "$46.40",
    status: ProductStatus.Draft,
    src: bomberJacket,
  },
  {
    code: "SK28-208",
    name: "Carson Shirt",
    selected: false,
    stock: 0,
    tags: ["Cotton", "Shirt"],
    price: "$46.40",
    status: ProductStatus.Draft,
    src: carsonShirt,
  },
  {
    code: "SK86-163",
    name: "Luna Track Pants",
    selected: false,
    stock: 8,
    tags: ["Cotton", "Pants"],
    price: "$46.40",
    status: ProductStatus.OnHold,
    src: lunaPants,
  },
  {
    code: "SK76-86",
    name: "X Light Jeans",
    selected: false,
    stock: 0,
    tags: ["Demin", "Jeans"],
    price: "$46.40",
    status: ProductStatus.OnHold,
    src: xLightJeans,
  },
];

export const PROJECTS_DATA: Project[] = [
  {
    name: "Design concepts",
    description: "Integer iaculis dui nec turpis pulvinar ornare.",
    people: ["John Smith", "Ken Eames"],
    icon: [{ id: "blue", iconName: "InkStrokeFilled" }],
  },
  {
    name: "Branding",
    description: "Praesent eget velit et mi cursus luctus sed id ipsum.",
    people: [
      "John Smith",
      "Peter Thornton",
      "Terry Wall",
      "Ken Eames",
      "Josh Tyson",
    ],
    icon: [{ id: "red", iconName: "BuildingFilled" }],
  },
  {
    name: "Wireframing",
    description: "Nulla et turpis vehicula, scelerisque ante vel.",
    people: ["John Smith"],
    icon: [{ id: "pink", iconName: "InkingToolFilled" }],
  },
  {
    name: "User acceptance testing",
    description: "Vestibulum vitae augue et justo feugiat lacinia.",
    people: [
      "John Smith",
      "Peter Thornton",
      "Terry Wall",
      "a b",
      "c d",
      "e f",
      "g h",
      "i j",
      "k l",
      "m n",
    ],
    icon: [{ id: "white", iconName: "ShieldFilled" }],
  },
];

export const ORDERS_DATA: Order[] = [
  {
    date: "2021-05-06",
    order: "#143567",
    selected: false,
    stock: 33,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.OnHold,
    location: "Melbourne, AU",
  },
  {
    date: "2021-05-06",
    order: "#243567",
    selected: false,
    stock: 0,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.Published,
    location: "Melbourne, AU",
  },
  {
    date: "2021-05-06",
    order: "#343567",
    selected: false,
    stock: 1440,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.Draft,
    location: "Melbourne, AU",
  },
  {
    date: "2021-05-06",
    order: "#443567",
    selected: false,
    stock: 256,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.Draft,
    location: "Melbourne, AU",
  },
  {
    date: "2021-05-06",
    order: "#543567",
    selected: false,
    stock: 65,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.OnHold,
    location: "Melbourne, AU",
  },
  {
    date: "2021-05-06",
    order: "#643567",
    selected: false,
    stock: 0,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.Published,
    location: "Melbourne, AU",
  },
  {
    date: "2021-05-06",
    order: "#743567",
    selected: false,
    stock: 52,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.Draft,
    location: "Melbourne, AU",
  },
  {
    date: "2021-05-06",
    order: "#843567",
    selected: false,
    stock: 0,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.Draft,
    location: "Melbourne, AU",
  },
  {
    date: "2021-05-06",
    order: "#943567",
    selected: false,
    stock: 8,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.OnHold,
    location: "Melbourne, AU",
  },
  {
    date: "2021-05-06",
    order: "#043567",
    selected: false,
    stock: 0,
    customer: "John Peters",
    total: "$46.40",
    status: ProductStatus.OnHold,
    location: "Melbourne, AU",
  },
];
export const CUSTOMERS_DATA: Customer[] = [
  {
    id: "1111",
    name: "Example customer",
    email: "2021-05-06",
    address: "#143567",
    selected: false,
    orders: 33,
    lastOrder: "$46.40",
  },
  {
    id: "1324",
    name: "John Smith",
    email: "2021-05-06",
    address: "#143567",
    selected: false,
    orders: 33,
    lastOrder: "$46.40",
  },
  {
    id: "5432",
    name: "Peter Thornton",
    email: "2021-05-06",
    address: "#243567",
    selected: false,
    orders: 0,
    lastOrder: "$46.40",
  },
  {
    id: "6474",
    name: "Terry Wall",
    email: "2021-05-06",
    address: "#343567",
    selected: false,
    orders: 1440,
    lastOrder: "$46.40",
  },
  {
    id: "5364",
    name: "Ken Eames",
    email: "2021-05-06",
    address: "#443567",
    selected: false,
    orders: 256,
    lastOrder: "$46.40",
  },
  {
    id: "9248",
    name: "Josh Tyson",
    email: "2021-05-06",
    address: "#543567",
    selected: false,
    orders: 65,
    lastOrder: "$46.40",
  },
  {
    id: "2387",
    name: "Andrew Samson",
    email: "2021-05-06",
    address: "#643567",
    selected: false,
    orders: 0,
    lastOrder: "$46.40",
  },
  {
    id: "2347",
    name: "Thomas Paul",
    email: "2021-05-06",
    address: "#743567",
    selected: false,
    orders: 52,
    lastOrder: "$46.40",
  },
  {
    id: "7653",
    name: "Nathan Sanders",
    email: "2021-05-06",
    address: "#843567",
    selected: false,
    orders: 0,
    lastOrder: "$46.40",
  },
  {
    id: "3348",
    name: "Jeremy Mellon",
    email: "2021-05-06",
    address: "#943567",
    selected: false,
    orders: 8,
    lastOrder: "$46.40",
  },
  {
    id: "4951",
    name: "Jack Jackson",
    email: "2021-05-06",
    address: "#043567",
    selected: false,
    orders: 0,
    lastOrder: "$46.40",
  },
];

export const ASSETS_DATA: Asset[] = [
  {
    createdOn: "2021-05-06",
    order: "#143567",
    selected: false,
    fileName: getFileName(slimJeans),
    updatedBy: "John Peters",
    handle: "HuT70udBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: slimJeans,
  },
  {
    createdOn: "2021-05-06",
    order: "#243567",
    selected: false,
    fileName: getFileName(activeSweater),
    updatedBy: "John Peters",
    handle: "D7w70ueBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: activeSweater,
  },
  {
    createdOn: "2021-05-06",
    order: "#343567",
    selected: false,
    fileName: getFileName(dressChinos),
    updatedBy: "John Peters",
    handle: "Aaq7wudBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: dressChinos,
  },
  {
    createdOn: "2021-05-06",
    order: "#443567",
    selected: false,
    fileName: getFileName(midnightHoodie),
    updatedBy: "John Peters",
    handle: "NhH40udBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: midnightHoodie,
  },
  {
    createdOn: "2021-05-06",
    order: "#543567",
    selected: false,
    fileName: getFileName(zipperJacket),
    updatedBy: "John Peters",
    handle: "7Sd40udBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: zipperJacket,
  },
  {
    createdOn: "2021-05-06",
    order: "#643567",
    selected: false,
    fileName: getFileName(stretchSweatPants),
    updatedBy: "John Peters",
    handle: "Cp890udBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: stretchSweatPants,
  },
  {
    createdOn: "2021-05-06",
    order: "#743567",
    selected: false,
    fileName: getFileName(bomberJacket),
    updatedBy: "John Peters",
    handle: "Xuu70udBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: bomberJacket,
  },
  {
    createdOn: "2021-05-06",
    order: "#843567",
    selected: false,
    fileName: getFileName(carsonShirt),
    updatedBy: "John Peters",
    handle: "SpQa0udBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: carsonShirt,
  },
  {
    createdOn: "2021-05-06",
    order: "#943567",
    selected: false,
    fileName: getFileName(lunaPants),
    updatedBy: "John Peters",
    handle: "Eu0f0udBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: lunaPants,
  },
  {
    createdOn: "2021-05-06",
    order: "#043567",
    selected: false,
    fileName: getFileName(xLightJeans),
    updatedBy: "John Peters",
    handle: "UiUk0udBgd",
    height: 238,
    width: 236,
    updatedOn: "2021-05-06",
    size: "293kb",
    src: xLightJeans,
  },
];

export const SETTINGS_NAV_ITEMS: SettingsNavItem[] = [
  {
    title: "General",
    itemId: "general",
  },
  {
    title: "Account",
    itemId: "account",
  },
  {
    title: "Security",
    itemId: "security",
  },
  {
    title: "Notifications",
    itemId: "notifications",
  },
];
//#endregion
