import React from "react";
import { useStyletron } from "baseui";
import OptionsMenu from "./OptionsMenu";
import { Avatar } from "baseui/avatar";
import { COLORS } from "../utils/mockData";

interface Props {
  text: string;
  title?: number | string;
  icon?: React.ReactNode;
  people?: string[];
}

export function GroupCard(props: Props) {
  const [css, theme] = useStyletron();

  const card = css({
    display: "flex",
    flexFlow: "column nowrap",
    boxSizing: "border-box",
    position: "relative",
    borderRadius: theme.borders.radius300,
    flex: 1,
    background: theme.colors.backgroundPrimary,
    paddingTop: theme.sizing.scale600,
    paddingBottom: theme.sizing.scale700,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale600,
    textAlign: "left",
    boxShadow: theme.lighting.shadow400,
    transition: "box-shadow 500ms",
    ":hover": {
      cursor: "pointer",
      boxShadow: theme.lighting.shadow700,
    },
    [theme.mediaQuery.large]: {
      paddingTop: theme.sizing.scale600,
      paddingBottom: theme.sizing.scale600,
      paddingLeft: theme.sizing.scale700,
      paddingRight: theme.sizing.scale700,
    },
  });

  const cardHeader = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: theme.sizing.scale600,
  });

  const cardFooter = css({
    display: "flex",
    alignItems: "center",
    width: "100%",
  });

  const cardIcon = css({
    display: "flex",
    alignItems: "center",
    padding: theme.sizing.scale200,
    borderRadius: "40px",
    width: "auto",
    backgroundColor: theme.colors.backgroundLightAccent,
    [theme.mediaQuery.medium]: {
      padding: theme.sizing.scale500,
    },
  });

  const cardBody = css({
    display: "flex",
    flex: "1",
    maxWidth: "100%",
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.sizing.scale600,
  });

  let remainder: number = 0;
  const stackedAvatars =
    props.people &&
    props.people.map((name, index) => {
      if (index > 5) {
        remainder += 1;
      }

      return (
        index < 5 && (
          <Avatar
            name={name}
            key={`avatar-${name}`}
            size="scale1000"
            overrides={{
              Root: {
                style: {
                  marginLeft: index > 0 ? "-6px" : 0,
                  backgroundColor: COLORS[index],
                  boxShadow:
                    "rgb(255, 255, 255) 0px 0px 0px 0px, rgb(255, 255, 255) 0px 0px 0px 3px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
                },
              },
            }}
          />
        )
      );
    });

  return (
    <div className={card} key="group-card">
      <div className={cardHeader}>
        {props.icon && <div className={cardIcon}>{props.icon}</div>}
        <OptionsMenu noBackground />
      </div>
      <div className={cardBody}>
        <GroupTitle>
          {(props.title ? props.title : 0).toLocaleString()}
        </GroupTitle>
        <GroupText>{props.text}</GroupText>
      </div>
      <div className={cardFooter}>
        {stackedAvatars}
        {!!remainder && (
          <Avatar
            name={`+ ${remainder}`}
            key="avatar-remainder"
            size="scale1000"
            overrides={{
              Root: {
                style: {
                  marginLeft: "-6px",
                  backgroundColor: theme.colors.contentTertiary,
                  boxShadow:
                    "rgb(255, 255, 255) 0px 0px 0px 0px, rgb(255, 255, 255) 0px 0px 0px 3px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
}

export const GroupTitle: React.FunctionComponent = (props) => {
  const [css, theme] = useStyletron();
  const statValue = css({
    ...theme.typography.HeadingMedium,
    color: theme.colors.contentPrimary,
    fontSize: theme.sizing.scale500,
    fontWeight: 600,
    lineHeight: 1,
    marginTop: 0,
    marginBottom: theme.sizing.scale300,
    [theme.mediaQuery.medium]: {
      fontSize: theme.sizing.scale600,
    },
    [theme.mediaQuery.large]: {
      marginBottom: theme.sizing.scale300,
    },
  });
  return <h3 className={statValue}>{props.children}</h3>;
};

export const GroupText: React.FunctionComponent = (props) => {
  const [css, theme] = useStyletron();
  const statTitle = css({
    ...theme.typography.ParagraphSmall,
    color: theme.colors.contentTertiary,
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: "nowrap",
    maxWidth: "calc(100% - 10px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
  });
  return <h4 className={statTitle}>{props.children}</h4>;
};
