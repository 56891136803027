import React from "react";
import { useState } from "react";
import { Checkbox } from "baseui/checkbox";
import {
  BuilderOverrides,
  TableBuilder,
  TableBuilderColumn,
} from "baseui/table-semantic";
import SelectionBanner from "./SelectionBanner";
import { Button, ButtonOverrides, KIND, SHAPE, SIZE } from "baseui/button";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { MainHeading } from "./MainHeading";
import FilterMenu from "./FilterMenu";
import { Customer, CUSTOMERS_DATA } from "../utils/mockData";
import { Pagination } from "baseui/pagination";
import TableShowing from "./TableShowing";
import StyledButton from "./StyledButton";
import { ReactComponent as Add } from "@fluentui/svg-icons/icons/add_20_filled.svg";
import { ReactComponent as EditFilled } from "@fluentui/svg-icons/icons/edit_20_filled.svg";
import { ReactComponent as Delete } from "@fluentui/svg-icons/icons/delete_20_regular.svg";
import { StatefulTooltip } from "baseui/tooltip";
import { useHistory } from "react-router";

export default function CustomersTable() {
  const [css, theme] = useStyletron();
  const [data, setData] = useState(CUSTOMERS_DATA);
  const [page, setPage] = useState(1);
  const history = useHistory();

  const hasAny = Boolean(data.length);
  const hasAll = hasAny && data.every((x) => x.selected);
  const hasSome = hasAny && data.some((x) => x.selected);
  const selectedCount = data.length && data.filter((x) => x.selected).length;

  //#region STYLES
  const buttonOverridess: ButtonOverrides = {
    BaseButton: {
      style: {
        paddingLeft: theme.sizing.scale400,
        paddingRight: theme.sizing.scale400,
      },
    },
  };

  const tableOverrides: BuilderOverrides = {
    Root: {
      style: {
        width: "100%",
        borderTopWidth: "0",
        borderRightWidth: "0",
        borderBottomWidth: "0",
        borderLeftWidth: "0",
      },
    },
    Table: {
      style: {
        borderLeftColor: "transparent",
      },
    },
    TableBodyRow: {
      style: {
        borderBottom: `1px solid ${theme.colors.borderTransparent}`,
      },
    },
  };

  const tableHeader = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "row wrap",
    marginBottom: theme.sizing.scale800,
  });

  const headerActions = css({
    display: "flex",
    flexFlow: "row wrap",
    flex: "1 0 auto",
    alignItems: "center",
    justifyContent: "flex-end",
  });

  const primaryActions = css({
    marginLeft: theme.sizing.scale600,
    display: "flex",
    alignItems: "center",
  });

  const tableColumnOverrides = (args?: {
    width: string;
    align: "left" | "right";
  }) => {
    const width = args && args.width;
    const alignment = args && args.align;
    return {
      TableHeadCell: {
        style: {
          ...theme.typography.LabelXSmall,
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale500,
          textAlign: alignment ?? "left",
          lineHeight: 1.6,
          width: width ?? "auto",
          color: theme.colors.contentTertiary,
          fontWeight: 600,
          "::before": {
            borderLeftWidth: "0",
          },
        },
      },
      TableBodyCell: {
        style: { width: width ?? "auto", verticalAlign: "center" },
      },
    };
  };
  // #endregion

  const handlePageChange = (nextPage: number) => {
    if (nextPage < 1) {
      return;
    }
    if (nextPage > Math.ceil(data.length / 3)) {
      return;
    }
    setPage(nextPage);
  };

  function toggleAll() {
    setData((data) =>
      data.map((row: Customer) => ({
        ...row,
        selected: !hasAll,
      }))
    );
  }

  function toggle(event: any) {
    const { name, checked } = event.currentTarget;
    setData((data) =>
      data.map((row: Customer) => ({
        ...row,
        selected: String(row.id) === name ? checked : row.selected,
      }))
    );
  }
  //#endregion

  //#region JSX
  const selectionBanner = (
    <div className={headerActions}>
      <SelectionBanner count={selectedCount} noBackground>
        <>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.pill}
            onClick={() => {}}
            overrides={buttonOverridess}
          >
            <Delete
              fill={theme.colors.negative}
              className={css({
                [theme.mediaQuery.large]: {
                  marginRight: theme.sizing.scale200,
                },
              })}
            />
            <Block
              as="span"
              display={["none", "none", "none", "block"]}
              color={theme.colors.contentSecondary}
            >
              Delete
            </Block>
          </Button>
        </>
      </SelectionBanner>
      <div className={primaryActions}>
        <FilterMenu />
        <StyledButton
          kind="primary"
          size="compact"
          marginLeft={theme.sizing.scale500}
        >
          <Add
            fill={theme.colors.contentInversePrimary}
            className={css({
              [theme.mediaQuery.small]: {
                marginRight: theme.sizing.scale400,
              },
            })}
          />
          <Block as="span" display={["none", "block", "block", "block"]}>
            Add customer
          </Block>
        </StyledButton>
      </div>
    </div>
  );
  //#endregion

  function onRowClick(code: string) {
    history.push(`/customers/${code}`);
  }

  return (
    <>
      <div className={tableHeader}>
        <Breadcrumbs>
          <MainHeading>Customers</MainHeading>
        </Breadcrumbs>
        {selectionBanner}
      </div>

      <TableBuilder data={data} overrides={tableOverrides}>
        <TableBuilderColumn
          overrides={tableColumnOverrides({ width: "0.1%", align: "left" })}
          header={
            <Checkbox
              checked={hasAll}
              isIndeterminate={!hasAll && hasSome}
              onChange={toggleAll}
            />
          }
        >
          {(row: Customer) => (
            <Checkbox name={row.id} checked={row.selected} onChange={toggle} />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn header="NAME" overrides={tableColumnOverrides()}>
          {(row: Customer) => row.name}
        </TableBuilderColumn>
        <TableBuilderColumn header="EMAIL" overrides={tableColumnOverrides()}>
          {(row: Customer) => row.email}
        </TableBuilderColumn>

        <TableBuilderColumn
          header="ADDRESS"
          overrides={tableColumnOverrides({ width: "20%", align: "left" })}
        >
          {(row: Customer) => row.address}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="ORDERS"
          numeric
          overrides={tableColumnOverrides({ width: "8%", align: "right" })}
        >
          {(row: Customer) => (
            <span
              className={css({
                color:
                  row.orders === 0
                    ? theme.colors.negative
                    : theme.colors.contentPrimary,
              })}
            >
              {row.orders}
            </span>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="LAST ORDER"
          overrides={tableColumnOverrides()}
        >
          {(row: Customer) => row.lastOrder}
        </TableBuilderColumn>
        <TableBuilderColumn
          overrides={tableColumnOverrides({ width: "0.1%", align: "left" })}
        >
          {(row: Customer) => (
            <StatefulTooltip accessibilityType={"tooltip"} content="Edit">
              <Button
                onClick={() => onRowClick(row.id)}
                shape={SHAPE.circle}
                kind={KIND.tertiary}
              >
                <EditFilled fill={theme.colors.contentTertiary} />
              </Button>
            </StatefulTooltip>
          )}
        </TableBuilderColumn>
      </TableBuilder>

      <div
        className={css({
          display: "flex",
          flexFlow: "column wrap",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale600,
          paddingLeft: theme.sizing.scale200,
          [theme.mediaQuery.large]: {
            flexFlow: "row wrap",
            paddingTop: theme.sizing.scale100,
          },
        })}
      >
        <TableShowing startValue={1} endValue={10} total={data.length} />
        <Pagination
          currentPage={page}
          numPages={Math.ceil(data.length / 3)}
          onPageChange={({ nextPage }) => handlePageChange(nextPage)}
        />
      </div>
    </>
  );
}
