import React from "react";
import { StatTitle, StatValue, StatDeviation } from "./StatCard";
import { ChartCard } from "./ChartCard";
import { useStyletron } from "baseui";
import AreaChart from "./AreaChart";
import StatBarChart from "./BarChart";

export default function DashboardGraphs() {
  const [css, theme] = useStyletron();
  const flexItem = {
    flex: "1 0 100%",
    marginBottom: theme.sizing.scale600,
  };

  return (
    <>
      <div
        className={css({
          display: "flex",
          flexFlow: "row wrap",
        })}
      >
        <div
          className={css({
            ...flexItem,
            flex: "1 1 100%",
            maxWidth: "100%",
            [theme.mediaQuery.large]: {
              flex: "1 1 35%",
              maxWidth: "calc(35% - 16px)",
              marginRight: theme.sizing.scale600,
            },
          })}
        >
          <ChartCard
            header={
              <>
                <StatTitle>Active users</StatTitle>
                <StatValue>2,587</StatValue>
                <StatDeviation
                  deviation="+1.5%"
                  timePeriod="5 days"
                ></StatDeviation>
              </>
            }
          >
            <StatBarChart />
          </ChartCard>
        </div>
        <div
          className={css({
            ...flexItem,
            flex: "1 1 100%",
            maxWidth: "100%",
            [theme.mediaQuery.large]: {
              flex: 2,
              maxWidth: "65%",
            },
          })}
        >
          <ChartCard
            header={
              <>
                <StatTitle>Audience</StatTitle>
                <StatValue>301,399</StatValue>
                <StatDeviation
                  deviation="+42.5%"
                  timePeriod="3 days"
                ></StatDeviation>
              </>
            }
          >
            <AreaChart />
          </ChartCard>
        </div>
      </div>
    </>
  );
}
