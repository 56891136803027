import React from "react";
import { ReactComponent as Book } from "@fluentui/svg-icons/icons/book_24_regular.svg";
import { ReactComponent as ChatHelpFilled } from "@fluentui/svg-icons/icons/chat_help_24_filled.svg";
import { ReactComponent as ChatHelp } from "@fluentui/svg-icons/icons/chat_help_24_regular.svg";
import { ReactComponent as ChatMultiple } from "@fluentui/svg-icons/icons/chat_multiple_24_regular.svg";
import { ReactComponent as Keyboard } from "@fluentui/svg-icons/icons/keyboard_24_regular.svg";
import { ReactComponent as Mail } from "@fluentui/svg-icons/icons/mail_24_regular.svg";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button, ButtonOverrides, KIND, SHAPE } from "baseui/button";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { LabelMedium } from "baseui/typography";
import { ListItem } from "./ListItem";
import { Divider } from "../components/Divider";

export default function SupportMenu() {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);

  const contain = css({
    marginLeft: "auto",
  });

  const list = css({
    display: "flex",
    flex: "1 1 auto",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    width: "320px",
    listStyle: "none",
    paddingLeft: 0,
    paddingBottom: theme.sizing.scale400,
    margin: 0,
    backgroundColor: theme.colors.backgroundPrimary,
  });

  const listHeader = css({
    backgroundColor: theme.colors.backgroundPrimary,
    paddingTop: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
    paddingBottom: theme.sizing.scale400,
  });

  const listItem = css({
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "flex-start",
  });

  const listItemBody = css({
    ...theme.typography.LabelSmall,
    lineHeight: 1.2,
    textAlign: "left",
    marginBottom: theme.sizing.scale100,
  });

  const listItemFooter = css({
    ...theme.typography.ParagraphXSmall,
    textAlign: "left",
    lineHeight: 1.2,
    color: theme.colors.contentSecondary,
  });

  const buttonOverrides: ButtonOverrides = {
    BaseButton: {
      style: {
        position: "relative",
        transitionProperty: "all",
        transitionDuration: theme.animation.timing200,
        backgroundColor: isOpen
          ? theme.colors.backgroundLightAccent
          : "transparent",
        color: isOpen
          ? theme.colors.backgroundAccent
          : theme.colors.contentSecondary,
        ":hover": {
          backgroundColor: isOpen
            ? theme.colors.backgroundLightAccent
            : theme.colors.backgroundSecondary,
        },
      },
    },
  };

  return (
    <div className={contain}>
      <StatefulPopover
        focusLock
        placement={PLACEMENT.bottomRight}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        content={({ close }) => (
          <ul className={list}>
            <li className={listHeader}>
              <LabelMedium>Help & Feedback</LabelMedium>
            </li>

            <Divider
              marginTop={theme.sizing.scale200}
              marginBottom={theme.sizing.scale400}
            />

            <ListItem
              key="support-item-1"
              icon={<Book fill={theme.colors.contentSecondary} />}
            >
              <Block className={listItem}>
                <Block className={listItemBody}>Documentation</Block>
                <Block as="div" className={listItemFooter}>
                  Learn the ins and outs of the product
                </Block>
              </Block>
            </ListItem>

            <ListItem
              key="support-item-2"
              icon={<Keyboard fill={theme.colors.contentSecondary} />}
            >
              <Block className={listItem}>
                <Block className={listItemBody}>Keyboard shortcuts</Block>
                <Block as="div" className={listItemFooter}>
                  Shortcuts to help navigate and perform actions faster
                </Block>
              </Block>
            </ListItem>

            <ListItem
              key="support-item-3"
              icon={<ChatMultiple fill={theme.colors.contentSecondary} />}
            >
              <Block className={listItem}>
                <Block className={listItemBody}>Contact support</Block>
                <Block as="div" className={listItemFooter}>
                  Chat with support or send a message to resolve an issue
                </Block>
              </Block>
            </ListItem>

            <ListItem
              key="support-item-4"
              icon={<Mail fill={theme.colors.contentSecondary} />}
            >
              <Block className={listItem}>
                <Block className={listItemBody}>Share feedback</Block>
                <Block as="div" className={listItemFooter}>
                  Request a feature or share feedback with us
                </Block>
              </Block>
            </ListItem>
          </ul>
        )}
      >
        <Button
          shape={SHAPE.circle}
          kind={KIND.tertiary}
          overrides={buttonOverrides}
        >
          {isOpen ? (
            <ChatHelpFilled fill="currentColor" />
          ) : (
            <ChatHelp fill="currentColor" />
          )}
        </Button>
      </StatefulPopover>
    </div>
  );
}
