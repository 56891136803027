import React from "react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ThemeContext } from "../App";
import background from "../assets/backgroundaccent1.svg";
import backgroundDark from "../assets/backgroundaccent1dark.svg";
import { ReactComponent as Logo } from "../assets/brandlogo.svg";
import StyledButton from "./StyledButton";
import { useHistory } from "react-router-dom";

interface Props {
  headerComponent: React.ReactNode;
  formComponent: React.ReactNode;
}

// This is a layout, but /layouts is already used.
// Maybe that should become /routelayouts, or whatever,
// and this goes into /layouts
export const AccountAccess = (props: Props) => {
  const [css, theme] = useStyletron();
  const { uitheme } = React.useContext(ThemeContext);
  const history = useHistory();

  const contain = css({
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.backgroundPrimary,
  });

  const heading = css({
    ...theme.typography.HeadingXLarge,
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.6px",
    fontWeight: 700,
    marginTop: theme.sizing.scale800,
    color: theme.colors.white,
  });

  const leftPanel = css({
    display: "none",
    flex: "0 1 48%",
    padding: theme.sizing.scale600,
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: theme.colors.accent400,
    backgroundImage:
      uitheme === "dark" ? `url(${backgroundDark})` : `url(${background})`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    [theme.mediaQuery.medium]: {
      display: "flex",
    },
  });

  const rightPanel = css({
    flex: "1",
    padding: theme.sizing.scale900,
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowY: "auto",
    [theme.mediaQuery.medium]: {
      flex: "1 1 52%",
      justifyContent: "center",
    },
  });

  const leftPanelHeader = css({
    display: "flex",
    flex: "1",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
  });

  const rightPanelHeader = css({
    display: "flex",
    flexFlow: "column wrap",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    [theme.mediaQuery.medium]: {
      flexFlow: "row wrap",
      justifyContent: "flex-end",
    },
  });

  const logoContain = css({
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "440px",
    padding: theme.sizing.scale900,
    marginTop: "auto",
    marginBottom: "auto",
  });

  return (
    <div className={contain}>
      <div className={leftPanel}>
        <Block display="flex" flex="0 0 auto" width="100%">
          <div className={leftPanelHeader}>
            <StyledButton
              kind="minimal"
              onClick={() => history.push("/")}
              overrides={{
                Root: {
                  style: {
                    color: "white",
                    ":hover": { color: theme.colors.contentSecondary },
                  },
                },
              }}
            >
              Home
            </StyledButton>
          </div>
        </Block>

        <div className={logoContain}>
          <Logo width="80px" color={theme.colors.white} />
          <div className={heading}>AmplyVolt</div>
        </div>
      </div>

      <div className={rightPanel}>
        <Block
          display="flex"
          flex="0 0 auto"
          width="100%"
          justifySelf="flex-start"
        >
          <div className={rightPanelHeader}>{props.headerComponent}</div>
        </Block>

        {props.formComponent}
      </div>
    </div>
  );
};
