import React from "react";
import { useStyletron } from "baseui";
import { ReactComponent as ArrowCircleUp } from "@fluentui/svg-icons/icons/arrow_circle_up_24_filled.svg";

interface Props {
  label: string;
  value?: number | string;
  icon?: React.ReactNode;
}

export function StatCard(props: Props) {
  const [css, theme] = useStyletron();

  const statCard = css({
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    boxSizing: "border-box",
    position: "relative",
    borderRadius: theme.borders.radius300,
    flex: 1,
    background: theme.colors.backgroundPrimary,
    paddingTop: theme.sizing.scale600,
    paddingBottom: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale500,
    paddingRight: theme.sizing.scale300,
    textAlign: "left",
    boxShadow: theme.lighting.shadow400,
    [theme.mediaQuery.large]: {
      paddingTop: theme.sizing.scale800,
      paddingBottom: theme.sizing.scale800,
      paddingLeft: theme.sizing.scale800,
      paddingRight: theme.sizing.scale600,
    },
  });
  const statIcon = css({
    display: "flex",
    alignItems: "center",
    padding: theme.sizing.scale200,
    borderRadius: "40px",
    backgroundColor: theme.colors.backgroundLightAccent,
    [theme.mediaQuery.medium]: {
      padding: theme.sizing.scale500,
    },
  });
  const statContents = css({
    display: "flex",
    flex: "1",
    maxWidth: "100%",
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingLeft: theme.sizing.scale400,
  });

  return (
    <div className={statCard}>
      {props.icon && <div className={statIcon}>{props.icon}</div>}
      <div className={statContents}>
        <StatTitle>{props.label}</StatTitle>
        <StatValue>
          {(props.value ? props.value : 0).toLocaleString()}
        </StatValue>
      </div>
    </div>
  );
}

export const StatTitle: React.FunctionComponent = (props) => {
  const [css, theme] = useStyletron();
  const statTitle = css({
    ...theme.typography.LabelMedium,
    color: theme.colors.contentTertiary,
    marginTop: 0,
    marginBottom: theme.sizing.scale300,
    whiteSpace: "nowrap",
    maxWidth: "calc(100% - 10px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 500,
    [theme.mediaQuery.large]: {
      marginBottom: theme.sizing.scale300,
    },
  });
  return <h4 className={statTitle}>{props.children}</h4>;
};

export const StatValue: React.FunctionComponent = (props) => {
  const [css, theme] = useStyletron();
  const statValue = css({
    ...theme.typography.HeadingMedium,
    color: theme.colors.contentPrimary,
    fontSize: theme.sizing.scale650,
    fontWeight: 700,
    lineHeight: 1,
    marginTop: 0,
    marginBottom: 0,
    [theme.mediaQuery.medium]: {
      fontSize: theme.sizing.scale850,
    },
  });
  return <h3 className={statValue}>{props.children}</h3>;
};

type StatDeviationT = {
  deviation: string;
  timePeriod: string;
  negative?: boolean;
};
export const StatDeviation = (props: StatDeviationT) => {
  const [css, theme] = useStyletron();
  const statDeviation = css({
    display: "flex",
    alignItems: "center",
    flewFlow: "row nowrap",
    padding: 0,
    marginTop: theme.sizing.scale400,
    color: theme.colors.contentTertiary,
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  });
  return (
    <div className={statDeviation}>
      <ArrowCircleUp
        fill={
          props.negative
            ? theme.colors.contentNegative
            : theme.colors.backgroundAccent
        }
        className={css({ marginRight: theme.sizing.scale100 })}
      />
      <span
        className={css({
          color: theme.colors.backgroundAccent,
          fontWeight: 500,
          marginRight: theme.sizing.scale100,
        })}
      >
        {props.deviation}
      </span>{" "}
      in the last {props.timePeriod}
    </div>
  );
};
