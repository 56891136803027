import React from "react";
import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { ListItem, ListItemLabel } from "baseui/list";
import { ProgressBar } from "baseui/progress-bar";
import { ReactComponent as CheckmarkCircle } from "@fluentui/svg-icons/icons/checkmark_circle_24_regular.svg";
import { ReactComponent as CheckmarkCircleFilled } from "@fluentui/svg-icons/icons/checkmark_circle_24_filled.svg";
import StyledButton from "./StyledButton";
import { ListCard } from "./ListCard";

export default function DashboardLists() {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: "flex",
        flexFlow: "row wrap",
      })}
    >
      <div
        className={css({
          flex: "1 1 100%",
          marginBottom: theme.sizing.scale600,
          [theme.mediaQuery.large]: {
            flex: "1 1 calc(50% - 12px)",
            marginRight: theme.sizing.scale600,
          },
        })}
      >
        <ListCard header="Team Tasks">
          <ul
            className={css({
              width: "100%",
              paddingLeft: 0,
              paddingRight: 0,
            })}
          >
            <TodoListItem key={1} title="Logo design" progress={3} total={4} />
            <TodoListItem
              key={2}
              title="Feature workshop"
              progress={6}
              total={6}
            />
            <TodoListItem key={3} title="App insights" progress={4} total={5} />
            <TodoListItem
              key={4}
              title="Wireframe profile page"
              progress={4}
              total={4}
            />
            <TodoListItem
              key={5}
              title="Subscriptions POC"
              progress={1}
              total={3}
            />
          </ul>
        </ListCard>
      </div>
      <div
        className={css({
          flex: "1 1 100%",
          marginBottom: theme.sizing.scale600,
          [theme.mediaQuery.large]: {
            flex: "1 1 calc(50% - 12px)",
          },
        })}
      >
        <ListCard header="Top Contributors">
          <ul
            className={css({
              width: "100%",
              paddingLeft: 0,
              paddingRight: 0,
            })}
          >
            <ContributorListItem
              title="Buck Pucker"
              count={328}
              key={1}
              num={1}
            />
            <ContributorListItem
              title="Bernard Fuddle"
              count={221}
              key={2}
              num={2}
            />
            <ContributorListItem
              title="Luther Peters"
              count={155}
              key={3}
              num={3}
            />
            <ContributorListItem
              title="Glen Morrice"
              count={149}
              key={4}
              num={4}
            />
            <ContributorListItem
              title="Jessie Timms"
              count={34}
              key={5}
              num={5}
            />
          </ul>
        </ListCard>
      </div>
    </div>
  );
}

interface TodoListItemProps {
  title: string;
  progress: number;
  total: number;
}

export const TodoListItem: React.FC<TodoListItemProps> = (
  props: TodoListItemProps
) => {
  const [css, theme] = useStyletron();
  return (
    <ListItem
      artwork={() =>
        props.progress === props.total ? (
          <CheckmarkCircleFilled fill={theme.colors.backgroundAccent} />
        ) : (
          <CheckmarkCircle fill={theme.colors.contentTertiary} />
        )
      }
      key={props.title}
    >
      <strong
        className={css({
          ...theme.typography.LabelMedium,
          minWidth: "40%",
          color: theme.colors.contentPrimary,
        })}
      >
        {props.title}
      </strong>
      <ProgressBar
        value={props.progress}
        getProgressLabel={(value) =>
          `${props.progress} out of ${props.total} done`
        }
        successValue={props.total}
        showLabel
      />
    </ListItem>
  );
};

interface ContributorListItemProps {
  title: string;
  count: number;
  num: number | string;
}

export const ContributorListItem: React.FC<ContributorListItemProps> = (
  props: ContributorListItemProps
) => {
  const [css, theme] = useStyletron();
  return (
    <ListItem
      key="right"
      artwork={() => (
        <span
          className={css({
            ...theme.typography.LabelMedium,
            color: theme.colors.contentPrimary,
            fontWeight: 500,
          })}
        >
          {props.num}
        </span>
      )}
      endEnhancer={() => (
        <>
          <Button shape="round" size="compact" kind="tertiary">
            {props.count}
          </Button>
          <div style={{ width: "18px" }} />
          <StyledButton kind="secondary" size="compact">
            View profile
          </StyledButton>
        </>
      )}
    >
      <ListItemLabel>{props.title}</ListItemLabel>
    </ListItem>
  );
};
