import React from "react";
import { useState } from "react";
import {
  BuilderOverrides,
  TableBuilder,
  TableBuilderColumn,
} from "baseui/table-semantic";
import { Button, KIND, SHAPE } from "baseui/button";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { MainHeading } from "./MainHeading";
import FilterMenu from "./FilterMenu";
import { Product, PRODUCT_DATA } from "../utils/mockData";
import { Pagination } from "baseui/pagination";
import TableShowing from "./TableShowing";
import StatusTag from "./StatusTag";
import StyledButton from "./StyledButton";
import BoldText from "./BoldText";
import { StatefulTooltip } from "baseui/tooltip";
import { ReactComponent as Add } from "@fluentui/svg-icons/icons/add_20_filled.svg";
import { ReactComponent as EditFilled } from "@fluentui/svg-icons/icons/edit_20_filled.svg";
import { useHistory } from "react-router";

export default function ProductTable() {
  const [css, theme] = useStyletron();
  const [data] = useState(PRODUCT_DATA);
  const [page, setPage] = useState(1);

  const history = useHistory();

  //#region STYLES
  const tableOverrides: BuilderOverrides = {
    Root: {
      style: {
        width: "100%",
        borderTopWidth: "0",
        borderRightWidth: "0",
        borderBottomWidth: "0",
        borderLeftWidth: "0",
      },
    },
    Table: {
      style: {
        borderLeftColor: "transparent",
      },
    },
    TableBodyRow: {
      style: {
        borderBottom: `1px solid ${theme.colors.borderTransparent}`,
      },
    },
  };

  const tableHeader = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "row wrap",
    marginBottom: theme.sizing.scale800,
  });

  const headerActions = css({
    display: "flex",
    flexFlow: "row wrap",
    flex: "1 0 auto",
    alignItems: "center",
    justifyContent: "flex-end",
  });

  const primaryActions = css({
    marginLeft: theme.sizing.scale600,
    display: "flex",
    alignItems: "center",
  });

  // TODO: Make component. SHared with OrdersTable and AssetsTable
  const tableColumnOverrides = (args?: {
    width: string;
    align: "left" | "right";
  }) => {
    const width = args && args.width;
    const alignment = args && args.align;
    return {
      TableHeadCell: {
        style: {
          ...theme.typography.LabelXSmall,
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale600,
          textAlign: alignment ?? "left",
          lineHeight: 1.6,
          width: width ?? "auto",
          color: theme.colors.contentTertiary,
          fontWeight: 600,
          "::before": {
            borderLeftWidth: "0",
          },
        },
      },
      TableBodyCell: {
        style: { width: width ?? "auto", verticalAlign: "center" },
      },
    };
  };
  // #endregion

  //#region FUNCTIONS
  const handlePageChange = (nextPage: number) => {
    if (nextPage < 1) {
      return;
    }
    if (nextPage > Math.ceil(data.length / 3)) {
      return;
    }
    setPage(nextPage);
  };

  function onRowClick(code: string) {
    history.push(`/products/${code}`);
  }
  //#endregion

  //#region JSX
  const selectionBanner = (
    <div className={headerActions}>
      <div className={primaryActions}>
        <FilterMenu />
        <StyledButton
          kind="primary"
          size="compact"
          marginLeft={theme.sizing.scale500}
        >
          <Add
            fill={theme.colors.contentInversePrimary}
            className={css({
              [theme.mediaQuery.small]: {
                marginRight: theme.sizing.scale400,
              },
            })}
          />
          <Block as="span" display={["none", "block", "block", "block"]}>
            New product &nbsp;
          </Block>
        </StyledButton>
      </div>
    </div>
  );
  //#endregion

  return (
    <>
      <div className={tableHeader}>
        <Breadcrumbs>
          <MainHeading>Products</MainHeading>
        </Breadcrumbs>
        {selectionBanner}
      </div>

      <TableBuilder data={data} overrides={tableOverrides}>
        <TableBuilderColumn header="NAME" overrides={tableColumnOverrides()}>
          {(row: Product) => (
            <div className={css({ display: "flex", alignItems: "center" })}>
              <div
                className={css({
                  width: "60px",
                  height: "60px",
                  display: "block",
                  marginRight: theme.sizing.scale200,
                  backgroundColor: "gray",
                })}
              >
                <img
                  src={row.src}
                  alt="Logo"
                  className={css({
                    width: "60px",
                    height: "60px",
                  })}
                />
              </div>

              {/* TODO: Make a text link instead */}
              <span
                className={css({
                  ...theme.typography.LabelSmall,
                  marginLeft: theme.sizing.scale200,
                })}
              >
                {row.name}
              </span>
            </div>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="PRICE"
          numeric
          overrides={tableColumnOverrides({ width: "14%", align: "right" })}
        >
          {(row: Product) => (
            <span
              className={css({ color: theme.colors.positive, fontWeight: 500 })}
            >
              {row.price}
            </span>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="CODE"
          overrides={tableColumnOverrides({ width: "10%", align: "left" })}
        >
          {(row: Product) => row.code}
        </TableBuilderColumn>

        <TableBuilderColumn header="TAGS" overrides={tableColumnOverrides()}>
          {(row: Product) => row.tags.map((tag) => tag).join(", ")}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="INVENTORY"
          numeric
          overrides={tableColumnOverrides({ width: "8%", align: "right" })}
        >
          {(row: Product) => (
            <span
              className={css({
                color:
                  row.stock === 0
                    ? theme.colors.negative
                    : theme.colors.contentSecondary,
              })}
            >
              <BoldText>{row.stock}</BoldText>
            </span>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="STATUS"
          overrides={tableColumnOverrides({ width: "14%", align: "left" })}
        >
          {(row: Product) => <StatusTag status={row.status} />}
        </TableBuilderColumn>
        <TableBuilderColumn
          overrides={tableColumnOverrides({ width: "0.1%", align: "left" })}
        >
          {(row: Product) => (
            <StatefulTooltip accessibilityType={"tooltip"} content="Edit">
              <Button
                onClick={() => onRowClick(row.code)}
                shape={SHAPE.circle}
                kind={KIND.tertiary}
              >
                <EditFilled fill={theme.colors.contentTertiary} />
              </Button>
            </StatefulTooltip>
          )}
        </TableBuilderColumn>
      </TableBuilder>

      <div
        className={css({
          display: "flex",
          flexFlow: "column wrap",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale600,
          paddingLeft: theme.sizing.scale200,
          [theme.mediaQuery.large]: {
            flexFlow: "row wrap",
            paddingTop: theme.sizing.scale100,
          },
        })}
      >
        <TableShowing startValue={1} endValue={10} total={data.length} />
        <Pagination
          currentPage={page}
          numPages={Math.ceil(data.length / 3)}
          onPageChange={({ nextPage }) => handlePageChange(nextPage)}
        />
      </div>
    </>
  );
}
